// Note :-- ensure the db hase one entry for booked slote to reflect properly dropdown

import React, { useContext, useEffect, useState } from "react";
import "../style/ContactGoogleMeet.css";
import { UserContext } from "../contexts/UserContext";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import moment from "moment-timezone";
// import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
// import { withEmotionCache } from "@emotion/react";
import Modal from "react-bootstrap/Modal";
// import Calendar from "./Calendar";

export default function CreateGoogleMeet({ fromBlur }) {
  // User context
  const { data } = useContext(UserContext);

  // User
  const [user, setUser] = useState(null);
  const [DatesInSlote, setDatesInSlote] = useState([]);

  // Load till event created
  const [disableButton, setDisableButton] = useState(false);

  // Required fields
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //  for loader

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [weekDays, setWeekDays] = useState(null);
  // const [WeekDaySlotes, setWeekDaySlotes] = useState(null);
  const [bookedMeetings, setBookedMeetings] = useState([]);
  const [availableSlotsForWeek, setavailableSlotsForWeek] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(""); // for contact number with library
  const [showPopupConfirm, setShowPopupConfirm] = useState(false); // for final modal when meeting booked

  // for modal handlers
  const handleClose = () => {
    setShowPopupConfirm(false);
    // setDirect(true);
    resetDropdown();
    window.location.reload();
  };
  const closeModal = () => {
    setShowPopupConfirm(false);
    resetDropdown();
    window.location.reload();
  };

  //get User
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      try {
        const userId = localStorage.getItem("userId");
        const response = await authAxios.get(`/getUser/${userId}`);
        setUser({
          name: response.data.name,
          email: response.data.email,
          contactNumber: response.data.contactNumber || "",
          message: "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error.response?.data);
      }
    };
    fetchUserData();
    FetchSlots(); // Changed from FetchSlots to fetchSlots for consistency
  }, []);

  useEffect(() => {
    const days = getDaysFromArray(availableSlotsForWeek);
    setWeekDays(days);
    // FetchSlots();
  }, [availableSlotsForWeek]);

  useEffect(() => {
    const filteredSlots = availableSlotsForWeek.filter(
      (slot) => slot.day === selectedDay
    );
    setAvailableSlots(filteredSlots);
  }, [selectedDay, availableSlotsForWeek]);

  const FetchSlots = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}getAvailableSlots`
      );
      setBookedMeetings(response.data.bookedMeeting);
      setavailableSlotsForWeek(response.data.availableSlotsForWeek);
      const days = getDaysFromArray(availableSlotsForWeek);
      setWeekDays(days);
      
    } catch (error) {
      console.error(error);
      console.error("FetchSlots in fetch api error");
    }
  };

  function getDaysFromArray(array) {
    const daysArray = [];

    // Iterate through the input array
    for (const item of array) {
      // Extract the day from each object
      const day = item.day;

      // Check if the day already exists in the daysArray
      if (!daysArray.includes(day)) {
        // If not, add it to the daysArray
        daysArray.push(day);
      }
    }

    return daysArray;
  }

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    selectedDate: "",
    selectedTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate the input based on its name
    switch (name) {
      case "name":
        // Perform name validation
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Name is required",
          }));
          setDisableButton(true);
        } else if (value.length < 3) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "Name is atleast 3 character",
          }));
          setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "",
          }));
          setDisableButton(false);
        }
        break;
      case "email":
        // Perform email validation
        if (!/\S+@\S+\.\S+/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email address",
          }));
          setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));

          setDisableButton(false);
        }
        break;
      case "contactNumber":
        // Perform contact number validation
        if (!/^\d{10}$/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            contactNumber: "Invalid contact number",
          }));
          setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            contactNumber: "",
          }));
          setDisableButton(false);
        }
        break;
      case "selectedDate":
        // Perform name validation
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedDate: "",
          }));
          // setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedDate: "",
          }));
          setDisableButton(false);
        }
        // if (value.trim() === "" || user?.selectedDate == undefined ) {
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     selectedDate: "required",
        //   }));
        //   // setDisableButton(true);
        // } else {
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     selectedDate: "",
        //   }));
        //   setDisableButton(false);
        // }
        break;
      case "selectedTime":
        // Perform name validation
        if (value.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedTime: "",
          }));
          // setDisableButton(true);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedTime: "",
          }));
          setDisableButton(false);
        }
        // if (value.trim() === "" || user?.selectedTime == undefined) {
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     selectedTime: "required time",
        //   }));
        //   // setDisableButton(true);
        // } else {
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     selectedTime: "",
        //   }));
        //   setDisableButton(false);
        // }
        break;
      default:
        break;
    }

    // Update the user state
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
    setError(false);
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedDate: "",
    }));
  };

  // phone number library
  const handleChangePhone = (value) => {
    setPhoneNumber(value);
    // setValid(validatePhoneNumber(value));
    setUser({ ...user, contactNo: value });
  };

  useEffect(() => {
    // Filter available slots based on selected day
    const filteredSlots = availableSlotsForWeek.filter(
      (slot) => slot.day === selectedDay
    );
    setAvailableSlots(filteredSlots);
  }, [selectedDay]);
  

  // const handleDateTimeChange = (event) => {
  //   setSelectedDateTime(event.target.value);
  //   const { name, value } = event.target;
  //   setUser({
  //     ...user,
  //     [name]: value,
  //     // selectedTime:
  //   });
  //   setError(false);
  //   console.log(user.selectedTime);
  // };

  // Handle submit

  function resetDropdown() {
    const selectedTime = (document.getElementById(
      "selectedTime"
    ).selectedIndex = 0); // Set the selectedIndex to 0 to reset the dropdown
    const selectedDate = (document.getElementById(
      "selectedDate"
    ).selectedIndex = 0); // Set the selectedIndex to 0 to reset the dropdown
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = { ...user };
      // console.log("handleSubmit top", user.selectedDate, user.selectedTime);
      if (user.selectedDate && user.selectedTime) {
        const Adminconverted = moment(user.selectedTime)
          .tz("America/Chicago")
          .format("YYYY-MM-DD HH:mm:ss");

        formData = {
          ...formData,
          selectedDateInAdminTZ: Adminconverted,
          selectedDateInUserTZ: user.selectedTime,
        };
      
        // return
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}bookMeeting`,
          formData
        );
        setError(false);
        if (response.data.message === "Meeting slot is already booked.") {
          toast(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 3500);
        }
        if (response.data.msg === "Meeting slot booked successfully!") {
          setShowPopupConfirm(true);
        }
      } else {
        if (user.selectedDate == undefined) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedDate: "select the day",
          }));
        }
        if (user.selectedTime == undefined) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedTime: "select the slot",
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  function convertToLocalTimeZone(utcSlot) {
    // Get the time zone name of the user's location
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Parse the UTC slot date using moment-timezone
    const utcSlotMoment = moment.utc(utcSlot.start);

    // Convert the UTC slot date to the user's local time zone
    const localSlotMoment = utcSlotMoment.tz(userTimeZone);

    // Add 30 minutes to the end time
    const localEndSlotMoment = localSlotMoment.clone().add(30, "minutes");

    // Format the local dates using moment-timezone
    const start = localSlotMoment.format("YYYY-MM-DD HH:mm");
    const end = localEndSlotMoment.format("YYYY-MM-DD HH:mm");

    // Return the local slot object with start and end times
    return {
      start: start,
      end: end,
    };
  }


  function filterAvailableSlots(bookedMeetings, availableSlots) {
    // Loop through the available slots
    const availableSlotsInUserTimeZone = availableSlots.map(
      convertToLocalTimeZone
    );
    // Convert booked meeting start times to a Set for efficient lookup
    const bookedSet = new Set(bookedMeetings.map((meeting) => meeting.start));

    // Filter available slots based on booked meetings
    const filteredSlots = availableSlotsInUserTimeZone.filter((slot) => {
      // Check if the slot's start time is not in the bookedSet
      return !bookedSet.has(slot.start);
    });
    return filteredSlots;
  }

  // Function to convert UTC datetime to local datetime format
  function convertToLocaleDateTime(datetimeString) {
    // Parse the input string into a Date object
    const datetimeUTC = new Date(datetimeString);

    // Convert UTC datetime to local datetime
    const datetimeLocale = datetimeUTC.toLocaleString();

    return datetimeLocale;
  }

  // working well for old one which is not both start and end date in dropdown
  // function filterAvailableSlots(bookedMeetings, availableSlots) {
  //   // Loop through the available slots
  //   const availableSlotsInUserTimeZone = availableSlots.map(
  //     convertToLocalTimeZone
  //   );

  //   // Filter out slots that overlap with booked meetings
  //   return availableSlotsInUserTimeZone.filter((slot) => {
  //     return !bookedMeetings.some((meeting) => {
  //       const meetingStart = new Date(meeting.start);
  //       const meetingEnd = new Date(meeting.end);
  //       const slotStart = new Date(slot.start);
  //       const slotEnd = new Date(slot.end);

  //       return (
  //         (slotStart >= meetingStart && slotStart < meetingEnd) ||
  //         (slotEnd > meetingStart && slotEnd <= meetingEnd) ||
  //         (meetingStart >= slotStart && meetingEnd <= slotEnd)
  //       );
  //     });
  //   });
  // }

  // Call the functions to convert and filter slots
  const availableSlotsForUser = filterAvailableSlots(
    bookedMeetings,
    availableSlots
    // availableSlotsForWeek // creating dropdown issue not in useEfect so not working
  );

  return (
    <div
      className={`google-meet  ${
        !fromBlur && "col-11 mx-auto"
      } bg-white h-100 `}
    >
      {/* <button onClick={googleSignOut}>Click</button> */}
      {/* <button onClick={createMeeting}>Meet</button> */}
      <div className="shadow rounded rounded-4 h-100">
        <div className="p-md-4 p-3 ">
          {user && !fromBlur ? (
            <>
              <div className="text-center">
                <h3 className="text-uppercase meet-green text-primary">
                  Book 1:1 session with us
                </h3>
              </div>
              <div className="col-md-10 col-12 mx-auto">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="name" className="w-100 my-2">
                    Name
                    <br />
                    <input
                      required
                      type="text"
                      id="name"
                      name="name"
                      className="border-0 w-100 border-bottom p-1"
                      onChange={handleChange}
                      value={user?.name}
                      disabled={fromBlur}
                    />
                  </label>
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                  <label htmlFor="email" className="w-100 my-2">
                    Email
                    <br />
                    <input
                      required
                      type="email"
                      id="email"
                      name="email"
                      className="border-0 w-100 border-bottom p-1"
                      onChange={handleChange}
                      value={user?.email}
                      disabled={fromBlur}
                    />
                  </label>
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                  <label htmlFor="message" className="w-100 my-2">
                    Message
                    <br />
                    <input
                      required
                      type="text"
                      id="message"
                      name="message"
                      className="border-0 w-100 border-bottom p-1"
                      onChange={handleChange}
                      value={
                        user?.message || "Share your goals for the session"
                      }
                      disabled={fromBlur}
                    />
                  </label>

                  <label
                    style={{
                      width: "100%",
                      color: "",
                      marginTop: ".4rem",
                      marginBottom: ".7rem",
                      // borderBottomColor: "red",
                      // border: "1px solid red",
                    }}
                  >
                    Contact Number (optional)
                    <PhoneInput
                      country="US" // in
                      defaultCountry="US"
                      value={phoneNumber || user?.contactNumber?.toString()}
                      onChange={handleChangePhone}
                      inputProps={{
                        required: false,
                      }}
                      // style={phoneCss}
                      className="mt-2"
                    />
                  </label>
                  {errors.contactNumber && (
                    <div className="text-danger">{errors.contactNumber}</div>
                  )}
                  {/* {!valid && (
                    <p
                      className="text-danger mb-0"
                      style={{ color: "red", fontSize: "80%" }}
                    >
                      Please enter a valid phone number.
                    </p>
                    
                  )} */}

                 

                  <label htmlFor="selectedDate" className="w-100 my-2">
                    Select a day
                    <br />
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="col-1 text-center">
                        <FaRegCalendarAlt className="w-50 h-75" />
                      </div>
                      <div className="col-11">
                        <select
                          name="selectedDate"
                          id="selectedDate"
                          className="border-0 w-100 border-bottom p-1 form-control"
                          onChange={handleDayChange}
                          value={user?.selectedDate}
                          disabled={fromBlur}
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                            paddingLeft: ".5rem",
                          }}
                        >
                          <option value="" selected disabled>
                            Select slot day
                          </option>

                          {weekDays?.map((data) => (
                            <option value={data}>{data}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {errors.selectedDate && (
                      <div className="text-danger">{errors.selectedDate}</div>
                    )}
                  </label>
                  <label htmlFor="selectedTime" className="w-100 my-2">
                    Select a date,time
                    <br />
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="col-1 text-center">
                        <FaRegClock className="w-50 h-75" />
                      </div>
                      <div className="col-11">
                        <select
                          name="selectedTime"
                          id="selectedTime"
                          className="border-0 w-100 border-bottom p-1 form-control "
                          onChange={handleChange}
                          value={user?.selectedTime}
                          disabled={fromBlur}
                          // size="6"// show how many row should be in dropdown which is show the scrollable
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                            paddingLeft: ".5rem",
                            width: "100px",
                          }}
                        >
                          <option value="" selected disabled>
                            Select slot date,time
                          </option>
                          <hr />
                          {/* convertedArray for full date in dropdown */}
                          {availableSlotsForUser.length > 1 ? (
                            availableSlotsForUser?.map((data) => (
                              <option value={data?.start}>
                                {/* {data?.start}-
                                {data?.end} */}
                                {convertToLocaleDateTime(data?.start)?.slice(
                                  0,
                                  -3
                                )}
                                -
                                {convertToLocaleDateTime(data?.end)
                                  ?.split(", ")[1]
                                  .slice(0, -3)}
                              </option>
                            ))
                          ) : (
                            <>
                              {availableSlotsForUser.length === 1 ? (
                                <option value="">
                                  Slot unavailable,try next day!
                                </option>
                              ) : null}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    {errors.selectedTime && (
                      <div className="text-danger">{errors.selectedTime}</div>
                    )}
                  </label>

                  {error && (
                    <p className="mb-0 mt-2 text-danger">
                      * Please fill required fields.
                    </p>
                  )}

                  <button
                    type="submit"
                    disabled={fromBlur || disableButton}
                    className="btn btn-outline-primary w-100 mt-4 py-2"
                  >
                    <span className=" h5 fw-normal">
                      {disableButton
                        ? "Fill form correctly"
                        : "Request 1:1 Meet"}{" "}
                    </span>
                    {!disableButton && (
                      <TbArrowBadgeRightFilled className="height-width-svg" />
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="loader-container-contact ">
                <span className="loader-contact"></span>
              </div>
            </>
          )}
        </div>
      </div>
      {/*  confirm modal */}
      <Modal show={showPopupConfirm} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> Congratulations !</Modal.Title>
          <button
            type="button"
            className="btn-close close-button-info "
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </Modal.Header>

        {/* <Modal.Body> */}
        <p className="px-3">
          <p>
            {/* Are you sure you want to apply to the{" "} */}
            <span className="fw-semibold">
              {/* "{scholarship?.scholarshipName}" */}
              Your 1:1 session has been successfully booked. We can't wait to
              assist you. Please check your email for confirmation details.
            </span>{" "}
          </p>
          {/* <p>Are you sure you want to apply for the scholarship?</p> */}
        </p>
        {/* </Modal.Body> */}
        <Modal.Footer>
          <button className="btn btn-outline-secondary " onClick={handleClose}>
            Cancel
          </button>
          {/* <button className="btn btn-primary" onClick={handleApply}>
            Confirm
          </button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

// Handle change
// const handleChange = (e) => {
//   const { name, value } = e.target;
//   setUser({
//     ...user,
//     [name]: value,
//   });
//   setError(false);
//   // console.log("name, value", name, value);
// };

// for indian time
// function convertToLocalTimeZone(utcSlot) {
//   // Create a new Date object from the UTC slot
//   const utcSlotDate = new Date(utcSlot.start);

//   // Get the user's timezone offset in minutes
//   const userOffsetMinutes = utcSlotDate.getTimezoneOffset();

//   // Convert the offset to milliseconds
//   const userOffsetMilliseconds = userOffsetMinutes * 60 * 1000;

//   // Create a new Date object in the user's timezone
//   const localSlotDate = new Date(
//     utcSlotDate.getTime() - userOffsetMilliseconds
//   );

//   // Add 30 minutes to the end time
//   const localEndSlotDate = new Date(localSlotDate.getTime() + 30 * 60 * 1000);

//   // Return the local slot object with start and end times
//   return {
//     start: localSlotDate.toISOString().slice(0, 16),
//     end: localEndSlotDate.toISOString().slice(0, 16), // Add 30 minutes to the end time
//   };
// }

/* <label htmlFor="contactNumber" className="w-100 my-2">
                    Contact Number 
                    <br />
                    <input
                      required
                      type="text"
                      id="contactNumber"
                      name="contactNumber"
                      className="border-0 w-100 border-bottom p-1"
                      onChange={handleChange}
                      value={user?.contactNumber}
                      disabled={fromBlur || disableButton}
                    />
                  </label> */

/* {console.log(typeof(user.contactNumber))} */

/* <div style={{ padding: "10px 0px" }}>
  <p>Hello ${user.name},</p>
  <p>&nbsp;</p>
  <p>
    Congratulations! You have successfully sheduled a Google meet with
    Path2Study.
  </p>
  <p>
    Here's your Meet link:{" "}
    <a href={"meetLink"} target="_blank" rel="noopener noreferrer">
      ${"meetLink"}
    </a>
  </p>
  <p>&nbsp;</p>
  <p>- Team Path2Study</p>
</div>; */

// {!session && !fromBlur ? (
//   <div className="d-flex" style={{ height: "380px" }}>
//     <div className="col-10 text-center m-auto">
//       <h4>Kindly login with Google!</h4>
//       <button
//         className="btn btn-primary px-md-4 py-2"
//         onClick={googleSignIn}
//       >
//         Log in
//       </button>
//     </div>
//   </div>
// ) : (
//   <>
//     <div className="text-center">
//       <h3 className="text-uppercase meet-green">
//         Book 1:1 session with us
//       </h3>
//     </div>
//     <div className="col-md-10 col-12 mx-auto">
//       <form onSubmit={handleSubmit}>
//         <label htmlFor="name" className="w-100 my-2">
//           Name
//           <br />
//           <input
//             required
//             type="text"
//             id="name"
//             name="name"
//             className="border-0 w-100 border-bottom p-1"
//             onChange={handleChange}
//             value={user?.name}
//             disabled={fromBlur || disableButton}
//           />
//         </label>
//         <label htmlFor="email" className="w-100 my-2">
//           Email
//           <br />
//           <input
//             required
//             type="text"
//             id="email"
//             name="email"
//             className="border-0 w-100 border-bottom p-1"
//             onChange={handleChange}
//             value={user?.email}
//             disabled={fromBlur || disableButton}
//           />
//         </label>
//         <label htmlFor="message" className="w-100 my-2">
//           Message
//           <br />
//           <input
//             required
//             type="text"
//             id="message"
//             name="message"
//             className="border-0 w-100 border-bottom p-1"
//             onChange={handleChange}
//             value={user?.message}
//             disabled={fromBlur || disableButton}
//           />
//         </label>
//         <label htmlFor="contactNumber" className="w-100 my-2">
//           Contact Number
//           <br />
//           <input
//             required
//             type="text"
//             id="contactNumber"
//             name="contactNumber"
//             className="border-0 w-100 border-bottom p-1"
//             onChange={handleChange}
//             value={user?.contactNumber}
//             disabled={fromBlur || disableButton}
//           />
//         </label>
//         <label htmlFor="selectedDate" className="w-100 my-2">
//           Select a date
//           <br />
//           <div className="d-flex flex-wrap justify-content-between">
//             <div className="col-2 text-center">
//               <FaRegCalendarAlt className="w-50 h-75" />
//             </div>
//             <div className="col-10">
//               {/* <input
//               required
//                 type="date"
//                 id="selectedDate"
//                 name="selectedDate"
//                 className="border-0 w-100 border-bottom p-1"
//                 onChange={handleChange}
//                 value={user?.selectedDate}
//                 disabled={fromBlur || disableButton}
//               /> */}
//               <select
//                 name="selectedDate"
//                 id="selectedDate"
//                 className="border-0 w-100 border-bottom p-1 form-control"
//                 onChange={handleChange}
//                 value={user?.selectedDate}
//                 disabled={fromBlur || disableButton}
//               >
//                 <option value="" selected disabled>
//                   Select slot date
//                 </option>
//                 {[
//                   "15-03-2024",
//                   "16-03-2024",
//                   "17-03-2024",
//                   "18-03-2024",
//                   "19-03-2024",
//                   "20-03-2024",
//                 ].map((data) => (
//                   <option value={data}>{data}</option>
//                 ))}
//               </select>
//             </div>
//           </div>
//         </label>
//         <label htmlFor="selectedTime" className="w-100 my-2">
//           Select a time slot
//           <br />
//           <div className="d-flex flex-wrap justify-content-between">
//             <div className="col-2 text-center">
//               <FaRegClock className="w-50 h-75" />
//             </div>
//             <div className="col-10">
//               {/* <input
//               required
//                 type="time"
//                 id="selectedTime"
//                 name="selectedTime"
//                 className="border-0 w-100 border-bottom p-1"
//                 onChange={handleChange}
//                 value={user?.selectedTime}
//                 disabled={fromBlur || disableButton}
//               /> */}
//               <select
//                 name="selectedTime"
//                 id="selectedTime"
//                 className="border-0 w-100 border-bottom p-1 form-control"
//                 onChange={handleChange}
//                 value={user?.selectedTime}
//                 disabled={fromBlur || disableButton}
//               >
//                 <option value="" selected disabled>
//                   Select slot time
//                 </option>
//                 {[
//                   "09:00",
//                   "09:30",
//                   "10:00",
//                   "10:30",
//                   "11:00",
//                   "11:30",
//                   "12:00",
//                   "12:30",
//                   "13:00",
//                 ].map((data) => (
//                   <option value={data}>{data}</option>
//                 ))}
//               </select>
//             </div>
//           </div>
//         </label>

//         {error && (
//           <p className="mb-0 mt-2 text-danger">
//             * Please fill required fields.
//           </p>
//         )}

//         <button
//           type="submit"
//           disabled={fromBlur || disableButton}
//           className="btn btn-outline-success w-100 mt-4 py-2"
//         >
//           <span className=" h5 fw-normal">
//             {disableButton ? "Please Wait..!" : "Request 1:1 Meet"}{" "}
//           </span>
//           {!disableButton && (
//             <TbArrowBadgeRightFilled className="height-width-svg" />
//           )}
//         </button>
//       </form>
//     </div>
//   </>
// )}

// console.log(
//   response.data.availableSlotsForWeek,
//   "from get slote function"
// );
// const weekDays = [];
// // setDatesInSlote(response.availableSlotsForWeek)
// response.data.availableSlotsForWeek.forEach((slot) => {
//   // Check if the day is not already in the weekDays array
//   if (!weekDays.includes(slot.day)) {
//     // Push the day into the weekDays array
//     weekDays.push(slot.day);
//   }
// });
// setDatesInSlote(response.availableSlotsForWeek)
