import React, { useContext, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import "../../style/userprofileImage.css";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
// import { toast } from "react-toastify";
// import { API_BASE_URL } from "../../appConfig";
import Modal from "react-bootstrap/Modal";
import { signOut } from "firebase/auth";
// import { auth } from "../../firebase";
import { getAuth } from "firebase/auth";

const UserProfileImage = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { data, setData, isUserLoggedIn } = useContext(UserContext);
  // const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false); // State for loading indicator

  const clearLocalStorage = () => {
    localStorage.clear();
  };

  const [details, setDetails] = useState({});

  const handleProfileChange = async (e) => {
    try {
      setLoading(true); // Set loading to true when starting the image upload
      const file = URL.createObjectURL(e.target.files[0]);
      const image = e.target.files[0];
      setDetails({ ...details, tempFile: e.target.files[0] });
      await handleUploadButtonClick(image);
      setData({ ...data, profileImg: file }); // for showing the selected file in the page
    } finally {
      setLoading(false); // Set loading to false after the image upload is complete (success or error)
    }
  };
  const handleUploadButtonClick = async (image) => {
    try {
      const userToken = localStorage.getItem("userToken");
      const userId = localStorage.getItem("userId");
      const updatedToken = `Bearer ${userToken}`;

      const formdata = new FormData();
      formdata.append("file", image);

      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
          "Content-Type": "multipart/form-data",
        },
      });

      const response = await authAxios.put(
        `${process.env.REACT_APP_BASE_URL}/updateUser/${userId}`,
        // `${API_BASE_URL}/updateUser/${userId}`,
        formdata
      );

      const updatedProfileImg = response.data.updated.profileImg;
      setData({ ...data, profileImg: updatedProfileImg });

      // console.log("API Response:", response.data);
    } catch (error) {
      console.log("Error uploading image to API:", error);
      // toast.error("Failed to update profile image.");
    }
  };
  // const handleProfileChange = async (e) => {
  //   const file = URL.createObjectURL(e.target.files[0]);
  //   const image = e.target.files[0];
  //   // console.log("handleProfileChange profile image file ", file);
  //   // console.log("data in image--> ", image);
  //   setDetails({ ...details, tempFile: e.target.files[0] });
  //   await handleUploadButtonClick(image);
  //   setData({ ...data, profileImg: file }); // for showing the selected file in page
  // };

  // const handleUploadButtonClick = async (image) => {
  //   const userToken = localStorage.getItem("userToken");
  //   const userId = localStorage.getItem("userId");
  //   const updatedToken = `Bearer ${userToken}`;

  //   const formdata = new FormData();
  //   formdata.append("file", image);
  //   // console.log("handleUploadButtonClick ormdata check", formdata);
  //   const authAxios = axios.create({
  //     baseURL: API_BASE_URL,
  //     headers: {
  //       authorization: updatedToken,
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });
  //   const response = await authAxios
  //     .put(
  //       `${API_BASE_URL}/updateUser/${userId}`,
  //       formdata
  //       // https://server-dot-path2study.el.r.appspot.com/updateUser/:userId
  //     )
  //     .then((response) => {
  //       console.log("api response.data", response.data.updated.profileImg);
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // modal logout code
  const [showPopup, setShowPopup] = useState(false);
  // const [timer, setTimer] = useState(3);
  // const [count, setCount] = useState(10);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleLogOutModal = () => {
    setShowPopup(true);
  };
  const handleLogOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        console.error("error",error);
      });
    if (isUserLoggedIn) {
      signOut(auth);
      clearLocalStorage();
      window.location.href = "/";
    }
  };

  return (
    <>
      <div>
        <div className="position-relative">
          <h4 className="position-absolute top-0 start-0">Basic Information</h4>
          <div className="position-absolute top-0 end-0 ">
            <button
              className="btn btn-outline-secondary ps-sm-0 pe-sm-0 px-md-5 z-3 position-relative px-4"
              onClick={handleLogOutModal}
            >
              Logout
            </button>
          </div>
        </div>
        {width >= 481 ? (
          <div className="d-flex">
            <div className="parent-container position-relative">
              {/* {console.log("parent-container", data.profileImg)} */}
              {data.profileImg ? (
                <Image
                  src={data.profileImg ? data.profileImg : details.tempFile}
                  // src={data.profileImg ? details.tempFile : data.profileImage}
                  // src={data.profileImg}
                  alt="User Profile"
                  roundedCircle
                  height={220}
                  width={220}
                  // style={{ height: "14rem", marginLeft: "9rem" }}
                  className="mt-5 "
                />
              ) : (
                <Image
                  className="rounded-circle mt-5"
                  height="auto"
                  width="100%"
                  src={require("../../assests/img/new200.png")}
                  alt=""
                />
              )}
              <input
                type="file"
                id="userImage"
                name="userImage"
                className="d-none"
                accept="/image/*"
                onChange={(e) => handleProfileChange(e)}
              />
              <button
                className="btn btn-sm btn-light rounded-pill position-absolute top-10 bottom-0 end-0 start-10"
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-md"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <label htmlFor="userImage">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="39"
                      height="40"
                      viewBox="0 0 39 40"
                      fill="none"
                    >
                      <path
                        opacity="0.5"
                        d="M27.3518 15.1426C30.8157 15.1617 32.6917 15.3162 33.9149 16.5393C35.3147 17.9392 35.3147 20.1911 35.3147 24.6949V26.2875C35.3147 30.793 35.3147 33.0449 33.9149 34.4448C32.5166 35.8431 30.263 35.8431 25.7592 35.8431H13.0184C8.51459 35.8431 6.26108 35.8431 4.86278 34.4448C3.46289 33.0433 3.46289 30.793 3.46289 26.2875V24.6949C3.46289 20.1911 3.46289 17.9392 4.86278 16.5393C6.08589 15.3162 7.96197 15.1617 11.4259 15.1426"
                        stroke="#3771C8"
                        strokeWidth="2.38889"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.3891 24.6959V3.99219M19.3891 3.99219L24.1669 9.56626M19.3891 3.99219L14.6113 9.56626"
                        stroke="#3771C8"
                        strokeWidth="2.38889"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </label>
                )}
              </button>
            </div>
            {/* for laptop screen */}
            <div
              className="col-6 text-center text-primary d-none d-sm-block hideinmobile"
              style={{ marginTop: "8rem", marginLeft: "15rem" }}
            >
              <h4>
                Please complete the profile to access the best tailored loans
                and scholarship options.
              </h4>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="parent-container position-relative">
              {data.profileImg ? (
                <Image
                  src={data.profileImg ? data.profileImg : details.tempFile}
                  // src={data.profileImg ? details.tempFile : data.profileImage}
                  // src={data.profileImg}
                  alt="User Profile"
                  roundedCircle
                  height={150}
                  width={150}
                  // style={{ height: "14rem", marginLeft: "9rem" }}
                  className="mt-5"
                />
              ) : (
                <Image
                  className="rounded-circle mt-5"
                  height="auto"
                  width="100%"
                  src={require("../../assests/img/new200.png")}
                  alt=""
                />
              )}
              <input
                type="file"
                id="userImage"
                name="userImage"
                className="d-none"
                accept="/image/*"
                onChange={(e) => handleProfileChange(e)}
              />
              <button
                className="btn btn-sm btn-light rounded-pill position-absolute top-10 bottom-0 end-0 start-10"
                disabled={loading} // Disable the button while loading
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-md"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <label htmlFor="userImage">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 39 40"
                      fill="none"
                    >
                      <path
                        opacity="0.5"
                        d="M27.3518 15.1426C30.8157 15.1617 32.6917 15.3162 33.9149 16.5393C35.3147 17.9392 35.3147 20.1911 35.3147 24.6949V26.2875C35.3147 30.793 35.3147 33.0449 33.9149 34.4448C32.5166 35.8431 30.263 35.8431 25.7592 35.8431H13.0184C8.51459 35.8431 6.26108 35.8431 4.86278 34.4448C3.46289 33.0433 3.46289 30.793 3.46289 26.2875V24.6949C3.46289 20.1911 3.46289 17.9392 4.86278 16.5393C6.08589 15.3162 7.96197 15.1617 11.4259 15.1426"
                        stroke="#3771C8"
                        strokeWidth="2.38889"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.3891 24.6959V3.99219M19.3891 3.99219L24.1669 9.56626M19.3891 3.99219L14.6113 9.56626"
                        stroke="#3771C8"
                        strokeWidth="2.38889"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </label>
                )}
              </button>
            </div>
            {/* for laptop screen */}
            <div
              className="col-6 text-center text-primary d-none d-sm-block hideinmobile"
              style={{ marginTop: "8rem", marginLeft: "15rem" }}
            >
              <h4>
                Please complete the profile to access the best tailored loans
                and scholarship options.
              </h4>
            </div>
          </div>
        )}
        {/* mobile */}
        <div className="col-12 text-center text-primary mt-4 hideinmobile">
          <h6>
            Please complete the profile to access the best tailored loans and
            scholarship options.
          </h6>
        </div>
      </div>

      {/* logOut modal code */}
      <Modal show={showPopup} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
              </Modal.Header> */}
        <Modal.Body>
          <div className="justify-content-center">
            <h4 className="">Log Out </h4>
            <h6 className="" style={{ color: "#667085" }}>
              Are you sure you want to log out?
            </h6>
          </div>
          {/* <h6 className="text-center mb-0 pb-0">
            You will be redirected to the Homepage in{count - 1} seconds...
          </h6> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            button
            type="button"
            className="btn btn-outline-secondary px-4"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            button
            type="button"
            className="btn btn-secondary px-4"
            onClick={handleLogOut}
          >
            Log out
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileImage;
