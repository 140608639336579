import React from "react";
import "./../styles/AdminLogin.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { API_BASE_URL } from "../../appConfig";
const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [finalError, setFinalError] = useState("");

  const loginNavigate = useNavigate();
  const handleLogin = () => {
    //AXIOS CONNECTION

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/adminLogin`, {
        userName: username,
        password: password,
      })
      .then((response) => {

        localStorage.setItem("adminId", response.data?.tokenData.userId);
        localStorage.setItem("adminToken", response.data?.tokenData.token);
        localStorage.setItem("userType", "admin");
        localStorage.setItem("adminName", response.data?.tokenData.userName);
        // window.alert("Login Succesfull");
        loginNavigate("/admin/dashboard");
      })
      .catch((error) => {
        console.error("admin login error", error);
        // window.alert(error.response.data.message);
        setFinalError(error.response.data?.message);
      });

    //HANDLE LOGOUT
    const handleLogout = () => {
      localStorage.removeItem("adminId");
      localStorage.removeItem("adminToken");
    };
  };

  return (
    <div>
      <div className="AdminLogin vh-100 ">
        <div className="container">
          <div className="d-flex align-items-center  justify-content-center">
            <div className="col-md-5 col-sm-7  text-center bg-white bg-dark rounded-3">
              <div className="d-flex justify-content-center mt-3">
                <img
                  src={require("../assets/img/path2studylogoadmin.png")}
                  alt=""
                  className="img-fluid col-3"
                  height={"2%"}
                />
              </div>
              <div className=" ">
                <h1>Log into your account</h1>
              </div>
              {/* hfhhj */}
              <div className=" py-2 px-5 shadow border rounded-3 mx-5 my-4">
                <div className="">
                  <form>
                    <div className="form-group">
                      <div className="text-start mt-3 mb-3">
                        <label className="" for="formGroupExampleInput">
                          Username
                        </label>
                        <input
                          type="text"
                          className="form-control forfontweigth"
                          id="formGroupExampleInput"
                          placeholder="Username"
                          onChange={(e) => setUsername(e.target.value)}
                          style={{
                            borderColor: finalError ? "red" : "revert-layer",
                            fontWeight: "400",
                            outline: "none",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                            borderWidth: "1px",
                          }}
                        />
                      </div>
                      <div className="text-start mt-3">
                        <label className="" for="formGroupExampleInput">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control forfontweigth"
                          id="formGroupExampleInput"
                          placeholder="xxxxxxx"
                          onChange={(e) => setPassword(e.target.value)}
                          style={{
                            borderColor: finalError ? "red" : "revert-layer",
                            fontWeight: "400",
                            outline: "none",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                            borderWidth: "1px",
                          }}
                        />
                      </div>
                      <div className="text-danger text-start mt-2">
                        {finalError}
                      </div>
                      <div className=" mt-3 mb-3">
                        <button
                          type="button"
                          className="btn bg-primary form-control text-white"
                          onClick={handleLogin}
                          disabled={!username || !password}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* ---------------------------------------------------------------------------------------- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
