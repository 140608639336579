import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/Table.css";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { API_BASE_URL } from "../../appConfig";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { PiBankLight } from "react-icons/pi";
import { VscGlobe } from "react-icons/vsc";
// import { API_BASE_URL } from "../../appConfig";
function Table({ setLoader, loader }) {
  const [newLoader, setNewLoader] = useState(true);
  const [data, setdata] = useState([]);
  const [filterData, setfilterData] = useState("");
  const [visitors, setVisitors] = useState();
  
  const addbanknevigate = useNavigate();
  const [countryToLoan,setCountryToLoan]=useState("United States")
  const [popUp, setPopUp] = useState(false);

  const adminId = localStorage.getItem("adminId");
  const adminToken = localStorage.getItem("adminToken");

  const [currentPageBank, setCurrentPageBank] = useState(1);
  const recordperpageBank = 10;

  const startIndex = (currentPageBank - 1) * recordperpageBank;
  const endIndex = startIndex + recordperpageBank;

  const filteredData = data?.filter((row) => {
    return row?.bankName?.toLowerCase()?.includes(filterData?.toLowerCase());
  });
  const visibleDatapage = filteredData?.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalNoPages = Math.ceil(filteredData?.length / recordperpageBank);

  const [newChangedstatus, setNewChangedstatus] = useState(null);

  const [userId, setUserId] = useState();

  const handleActiveChange = (status, id) => {
    setNewChangedstatus(!status);
    setUserId(id);

    // axios.put()={

    // }
    let adminToken = localStorage.getItem("adminToken");
    const headers = {
      authorization: `bearer ${adminToken}`,
    };
    // let adminId = localStorage.getItem("adminId");

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateLoan/${adminId}/${id}`,
        {
          isActive: !status,
        },
        { headers: headers }
      )
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/getAllLoans/${adminId}?country=${countryToLoan}`, {
            headers: headers,
          })
          .then((res) => {
            const updatedLoans = res.data.loans.map((loan) => {
              // Check if the loan order is greater than 8 and set it to an empty string if true
              if (loan.order > 8) {
                loan.order = "";
              }
              return loan; // Return the modified loan object
            });
            setdata(updatedLoans);
          })
          .catch((err) => {
            console.error("error in api", err);
          });
      })
      .catch((error) => {});
  };

  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/getAllLoansopen/${adminId}?country=${countryToLoan}`,
        {
          headers: headers,
        }
        // {
        //   country: countryToLoan,
        // }
      )
      .then((response) => {
        const ToModify = response.data.loans;
        for (var i = 0; i < ToModify.length; i++) {
          if (ToModify[i].order > 8) {
            ToModify[i].order = "";
          }
        }
        setdata(ToModify);

        axios
          .get(`${process.env.REACT_APP_BASE_URL}/admin/getVisiters/${adminId}`)
          .then((response) => {
            setVisitors(response.data);
          })
          .catch((err) => {
            console.error("error in api", err);
          });
      })
      .catch((err) => {
        console.error("error in api", err);
      })
      .finally(() => {
        // setLoader(false);
        setNewLoader(false);
      });
  }, [countryToLoan]);

  const handleAddLoans = () => {
    addbanknevigate("/admin/loan-management/add-lender");
    window.scrollTo(0, 0);
  };
  const handleViewDetails = (loan) => {
    addbanknevigate("/admin/loan-management/about-lender", { state: loan });
    window.scrollTo(0, 0);
  };

  const [currentPageUserManagment, setCurrentPageUserManagment] = useState(1);
  const recordsPerPageUserManagment = 10;

  // Calculate the index range for the current page
  const startIndexUserManagment =
    (currentPageUserManagment - 1) * recordsPerPageUserManagment;
  const endIndexUserManagment =
    startIndexUserManagment + recordsPerPageUserManagment;

  const sortedVisibleData = [...data];

  sortedVisibleData.sort((a, b) => {
    // If isActive is true, move the element to the start
    if (a.isActive && !b.isActive) return -1;
    // If isActive is true, move the element to the end
    if (!a.isActive && b.isActive) return 1;
    // Maintain the original order for other elements
    return 0;
  });
  // Slice the data based on the current page
  const visibleDataUserManagment = sortedVisibleData?.slice(
    startIndexUserManagment,
    endIndexUserManagment
  );

  // Calculate the total number of pages
  const totalPagesUserManagment = Math.ceil(
    data?.length / recordsPerPageUserManagment
  );

  const forSerialNumberUserManagemnt =
    recordsPerPageUserManagment * (currentPageUserManagment - 1);

  // Function to handle page change
  const handlePageChangeUserManagment = (newPageTwo) => {
    setCurrentPageUserManagment(newPageTwo);
  };

  const[countryName,setCountryName]=useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/getAllLoansCountries`).then((response)=>{
       setCountryName(response.data);

    }).catch((err)=>{
     console.error("err",err);
    })
  },[])



  useEffect(() => {
    setCurrentPageUserManagment(1);
  }, [filterData]);

  // checkbox //

  // Edit Change //

  const [editedData, setEditedData] = useState([]);
  const handleEdit = (
    id,
    // bankName,
    columnName,
    newValue
  ) => {
    // Check if the item is already in the editedData array
    const editedItemIndex = editedData.findIndex((item) => item._id === id);

    // If it's not in the array, add it
    if (editedItemIndex === -1) {
      setEditedData((prevEditedData) => [
        ...prevEditedData,
        {
          // bankName: bankName,
          _id: id,
          [columnName]: newValue,
        },
      ]);
    } else {
      // If it's already in the array, update it
      setEditedData((prevEditedData) => {
        const updatedEditedData = [...prevEditedData];
        updatedEditedData[editedItemIndex] = {
          ...updatedEditedData[editedItemIndex],
          [columnName]: newValue,
        };
        return updatedEditedData;
      });
    }

    // Update the state with the new data
    setdata((prevPipesData) => {
      const updatedData = prevPipesData.map((item) =>
        item._id === id ? { ...item, [columnName]: newValue } : item
      );
      return updatedData;
    });
  };

  const handleUpdatePriority = () => {
    let checkOne = 0;
    let checkTwo = 0;
    let checkThree = 0;
    let checkFour = 0;
    let checkFive = 0;
    let checkSix = 0;
    let checkSeven = 0;
    let checkEight = 0;
    data.map((element) => {
      if (element.order == 1) {
        checkOne++;
      }
      if (element.order == 2) {
        checkTwo++;
      }
      if (element.order == 3) {
        checkThree++;
      }

      if (element.order == 4) {
        checkFour++;
      }
      if (element.order == 5) {
        checkFive++;
      }
      if (element.order == 6) {
        checkSix ++;
      }
      if (element.order == 7) {
         checkSeven ++;
      }
      if (element.order == 8) {
        checkEight++;
      }
    });

    let modifiedData = data.map(({ _id, order }) => ({ _id, order }));

    if (checkOne <= 1 && checkTwo <= 1 && checkThree <= 1 && checkFour<= 1 && checkFive<=1 && checkSix<=1 && checkSeven<=1 && checkEight<=1 )  {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/admin/updatePriorityLoans/${adminId}`,
          modifiedData,
          { headers: headers }
        )
        .then((res) => {
          toast.success(`Updated successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          axios
          .get(`${process.env.REACT_APP_BASE_URL}/getAllLoans/${adminId}?country=${countryToLoan}`, {
            headers: headers,
          })
          .then((res) => {
            const updatedLoans = res.data.loans.map((loan) => {
              // Check if the loan order is greater than 8 and set it to an empty string if true
              if (loan.order > 8) {
                loan.order = "";
              }
              return loan; // Return the modified loan object
            });
          
            // Set the updated array in the state
            setdata(updatedLoans);
          })
          
          .catch((err) => {
            console.error("error in api", err);
          });
          
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          console.error("error", error);
        });
    } else {
      setPopUp(true);
    }
  };

  const [editIcon, setEditIcon] = useState(false);

  // console.log("selectedLoans---->>", selectedLoans);
  return (
    <>
      {newLoader ? (
        <div>
          <div className="col-10 py-3 px-3">
            <div className="text-center mt-3">
              <div className="loader-container">
                <div className="loader"></div>

                <div>Please wait while we fetch the latest data for you</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="px-3">
            <div className="d-flex mt-3">
              <BreadCrumb />

              <div className="px-4 py-3">
                <div className="d-flex justify-content-between">
                  <FaCircleUser size={25} className="mt-1 mx-2" />
                  <div className="align-middle mt-2">
                    {localStorage.getItem("adminName")}
                  </div>
                </div>
              </div>
            </div>
            <hr className=" my-1" />
          </div>

          <div className="d-flex justify-content-between  mt-3">
            <div className="col-4  px-3">
              <div
                className="rounded-3 p-3 bg-white border"
                // onClick={handleDivClick2}
                // style={{
                //   border: `2px solid ${isClicked2 ? "#4572bf" : "white"}`,
                //   backgroundColor: `${isClicked2 ? "#F6FDFF" : "white"}`,
                //   padding: "10px",
                //   cursor: "pointer",
                //   height: "100%",
                // }}
              >
                <div className="px-4">
                  <PiBankLight size={40} style={{ color: "#64748B" }} />
                </div>
                <div className="d-flex justify-content-between px-4">
                  <div className="">
                    <h5 className="mt-3">Total Added Lenders</h5>
                  </div>

                  <div className="">
                    <h5 className="mt-3">
                      {filteredData?.length}
                      {/* {pathToStudyTable?.length} */}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4 px-4">
              <div
                className="rounded-3 bg-white border p-3"
                // onClick={handleDivClickOne}
                // style={{
                //   border: `2px solid ${isClicked1 ? "#4572bf" : "white"}`,
                //   backgroundColor: `${isClicked1 ? "#F6FDFF" : "white"}`,
                //   padding: "10px",
                //   cursor: "pointer",
                //   height: "100%",
                // }}
              >
                <div className="px-4" style={{ color: "#64748B" }}>
                  <VscGlobe size={40} />
                </div>

                <div className="d-flex justify-content-between">
                  <div className="">
                    <h5 className="mt-3">Total Added Countries</h5>
                  </div>

                  <div className="">
                    <h5 className="mt-3">
                      {/* {otherProviders?.length} */}
                      {countryName?.length}
                      {/* 02 */}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-4 px-3">
              <div
                className="rounded-3 bg-white border p-3"
                // onClick={handleDivClickOne}
                // style={{
                //   border: `2px solid ${isClicked1 ? "#4572bf" : "white"}`,
                //   backgroundColor: `${isClicked1 ? "#F6FDFF" : "white"}`,
                //   padding: "10px",
                //   cursor: "pointer",
                //   height: "100%",
                // }}
              >
                <div className="justify-content-between">
                  <div className="">
                    <h5 className="mt-3">Select Country</h5>
                  </div>

                  {/* <Dropdown
                        ref={dropdownRef}
                        className="d-inline bg-none"
                        show={show}
                        onToggle={handleToggle}
                        autoClose="inside"
                      >
                        <Dropdown.Toggle id="dropdown-autoclose-inside">
                          Clickable Outside
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#">USA</Dropdown.Item>
                          <Dropdown.Item href="#">India</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                  <select
                    className="form-select shadow-none"
                    value={countryToLoan}
                    onChange={(e) => setCountryToLoan(e.target.value)}
                  >
                    {/* <option value={"United States"}>USA</option>
                        <option value={"India"}>India</option> */}
                    {countryName.map((country, index) => (
                      <option key={index}>
                        {/* {country.name} */}
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-table m-0">
            <ToastContainer />
            <div className="container-fluid mt-3">
              <div className="border rounded-3 mx-1 shadow p-4">
                <div className="d-flex flex-wrap justify-content-between">
                  <h4 className="col-3 mt-2">
                    Total Lenders : {filteredData?.length}
                  </h4>

                  <div className="col-9 p-0 d-flex flex-wrap  justify-content-between">
                    {/* -- */}

                    <div class="w-50 form-group has-search ">
                      <span className="fa fa-search form-control-feedback "></span>

                      <input
                        type="text"
                        class="form-control fs-6 rounded-pill bg-white "
                        placeholder="Search by lender name"
                        value={filterData}
                        onChange={(e) => setfilterData(e.target.value)}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer", // Remove default border on focus
                          outline: "none", // Remove default outline on focus
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                          borderWidth: "1px",
                        }}
                      />
                    </div>

                    <div>
                      {editIcon ? (
                        <button
                          onClick={handleUpdatePriority}
                          className="p-2 shadow-0 border-0 cursor-pointer addLoan rounded px-5"
                        >
                          Update
                        </button>
                      ) : null}
                    </div>

                    <div className="">
                      <button
                        className="p-2 shadow-0 border-0 cursor-pointer addLoan rounded px-5"
                        onClick={handleAddLoans}
                      >
                        + Add New Lender
                      </button>
                    </div>

                    {/* --- */}
                  </div>
                </div>

                <div className="">
                  {data.length == 0 ? (
                    <div
                      className="bg-white pt-2 forBakgroundContainer rounded-3 bg-primary shadow-lg"
                      style={{ height: "70vh" }}
                    >
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "60vh" }}
                      >
                        <div className="text-center mt-3">
                          <h4>There is no information to display yet.</h4>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {sortedVisibleData?.length !== 0 &&
                      filteredData?.length !== 0 ? (
                        <div className="pt-1 pb-4  bg-white rounded-3 mt-3">
                          <table class="table table-hover   custom-table  ">
                            <thead className="">
                              <tr className="text-center ">
                                <td className="headingloan-color ">S.No</td>
                                <td className="headingloan-color  ForRowfont">
                                  Lender Name
                                </td>
                                <td className=" headingloan-color ForRowfont">
                                  Country
                                </td>
                                <td className="headingloan-color ForRowfont">
                                  No of Visitors
                                </td>

                                <td className=" headingloan-color ForRowfont">
                                  Active
                                </td>
                                <td className="headingloan-color ForRowfont">
                                  Priority
                                  <CiEdit
                                    size={20}
                                    fill="orange"
                                    className="cursor-pointer"
                                    // onClick={handleEditIconClick}
                                    onClick={() => setEditIcon(!editIcon)}
                                  />
                                </td>
                                <td className="headingloan-color ForRowfont">
                                  Action
                                </td>
                              </tr>
                            </thead>

                            <tbody>
                              {filterData === ""
                                ? visibleDataUserManagment?.map((loan, i) => {
                                    return (
                                      <tr className="text-center" key={i}>
                                        <td className="align-middle">
                                          {forSerialNumberUserManagemnt +
                                            (i + 1)}
                                        </td>
                                        <td className="align-middle">
                                          {" "}
                                          {loan.bankName}
                                        </td>
                                        <td className="align-middle">
                                          {loan.country}
                                        </td>
                                        <td className="align-middle">
                                          {filterData === ""
                                            ? loan.lotalVisitorsCount
                                            : ""}
                                        </td>
                                        <td className="align-middle">
                                          <div className="form-check form-switch d-flex align-items-center justify-content-center">
                                            <input
                                              className="form-check-input border-none shadow-none"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckChecked"
                                              checked={
                                                loan.isActive ? true : false
                                              }
                                              onClick={() =>
                                                handleActiveChange(
                                                  loan.isActive,
                                                  loan._id
                                                )
                                              }
                                            />
                                          </div>
                                        </td>

                                        {editIcon === true ? (
                                          <td className="input-hide align-middle">
                                            <input
                                              type="number"
                                              className="text-center rounded-2 w-50"
                                              value={loan.order}
                                              max={3}
                                              min={1}
                                              onChange={(e) => {
                                                const newValue = Math.min(
                                                  Math.max(
                                                    parseInt(e.target.value),
                                                    1
                                                  ),
                                                  8
                                                ); // Restrict the value between 1 and 3
                                                handleEdit(
                                                  loan._id,
                                                  "order",
                                                  newValue
                                                );
                                              }}
                                            />
                                          </td>
                                        ) : (
                                          <td className="align-middle">
                                            {loan.order}
                                          </td>
                                        )}

                                        <td>
                                          <button
                                            className="px-5 rounded-3  viewDetails btn btn-outline-primary"
                                            onClick={() =>
                                              handleViewDetails(loan)
                                            }
                                          >
                                            View Details
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : filteredData?.map((loan, i) => {
                                    return (
                                      <tr
                                        className="align-middle text-center"
                                        key={i}
                                      >
                                        <td>
                                          {forSerialNumberUserManagemnt +
                                            (i + 1)}
                                        </td>
                                        <td className="align-middle">
                                          {" "}
                                          {loan.bankName}
                                        </td>
                                        <td className="align-middle">
                                          {loan.country}
                                        </td>
                                        <td className="align-middle">
                                          {filterData === ""
                                            ? loan.lotalVisitorsCount
                                            : loan.lotalVisitorsCount}
                                        </td>
                                        <td className="align-middle">
                                          <div class="form-check form-switch d-flex align-items-center justify-content-center">
                                            <input
                                              class="form-check-input border-none shadow-none"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckChecked"
                                              checked={
                                                loan.isActive ? true : false
                                              }
                                              onClick={() =>
                                                handleActiveChange(
                                                  loan.isActive,
                                                  loan._id
                                                )
                                              }
                                            />
                                          </div>
                                        </td>

                                        {editIcon === true ? (
                                          <td className="input-hide align-middle">
                                            <input
                                              type="number"
                                              className="text-center rounded-2 w-50"
                                              value={loan.order}
                                              max={3}
                                              min={1}
                                              onChange={(e) => {
                                                const newValue = Math.min(
                                                  Math.max(
                                                    parseInt(e.target.value),
                                                    1
                                                  ),
                                                  3
                                                ); // Restrict the value between 1 and 3
                                                handleEdit(
                                                  loan._id,
                                                  "order",
                                                  newValue
                                                );
                                              }}
                                            />
                                          </td>
                                        ) : (
                                          <td className="align-middle">
                                            {loan.order}
                                          </td>
                                        )}
                                        <td>
                                          <button
                                            className="px-5 rounded-3  viewDetails btn btn-outline-primary"
                                            onClick={() =>
                                              handleViewDetails(loan)
                                            }
                                          >
                                            View Details
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </table>
                          {/* <pre>{JSON.stringify(editedData, null, 2)}</pre>
                  oldEdited {data.length}
                  <pre>{JSON.stringify(data, null, 2)}</pre> */}
                        </div>
                      ) : (
                        <div className="bg-white p-2">
                          <h6 className="text-center mt-3">
                            No search results found
                          </h6>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {data.length == 0 ? null : (
                  <div>
                    {visibleDataUserManagment?.length === 0 ? (
                      // <div className="bg-white p-2">
                      //   <h6 className="text-center mt-3">No search results found</h6>
                      // </div>
                      ""
                    ) : (
                      <>
                        {filteredData?.length === 0 ? (
                          ""
                        ) : (
                          <>
                            {totalPagesUserManagment > 1 &&
                            filteredData?.length > totalPagesUserManagment ? (
                              <div className="d-flex mt-3 justify-content-between mx-3">
                                <button
                                  disabled={currentPageUserManagment === 1}
                                  onClick={() =>
                                    handlePageChangeUserManagment(
                                      currentPageUserManagment - 1
                                    )
                                  }
                                  className="peginationButton custom-button fs-4 rotateArrow"
                                >
                                  {/* <GoArrowLeft  size={25} className="col-12" /> */}
                                  ↼
                                </button>
                                <div>
                                  Page {currentPageUserManagment} of{" "}
                                  {totalPagesUserManagment}
                                </div>
                                <button
                                  disabled={
                                    currentPageUserManagment ===
                                    totalPagesUserManagment
                                  }
                                  onClick={() =>
                                    handlePageChangeUserManagment(
                                      currentPageUserManagment + 1
                                    )
                                  }
                                  className="custom-button fs-4"
                                >
                                  {/* <GoArrowRight size={25} /> */}⇀
                                </button>
                              </div>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <Modal show={popUp} onHide={() => setPopUp(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Duplicate entries found.</Modal.Title>
              </Modal.Header>

              <p className="px-3">
                <p>
                  You have assigned the same priority to more than one lender.
                  Please change the priority order to make it unique for each
                  lender.
                </p>
              </p>

              <Modal.Footer>
                <button
                  className="btn btn-outline-secondary "
                  onClick={() => setPopUp(false)}
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}

export default Table;
