// import axios from "axios";
// import "../style/hero.css";
// import React, { useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/scrollbar";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";

// // Import local images
// //import img1 from "../assests/img/3.png";
// //import img2 from "../assests/img/4.png";
// //import img3 from "../assests/img/5.png";

// const HeroSwiper = () => {
//   // Set initial state to local images
//   const [banner, setBanner] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const getBanners = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}getbanners`
//         );
//         setBanner(response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false); // Update loading state after fetching
//       }
//     };

//     getBanners();
//   }, []);

//   return (
//     <div className="" style={{ marginTop: "5rem", marginBottom: "2rem" }}>
//       <Swiper
//         modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
//         spaceBetween={0}
//         autoplay={{ delay: 2500 }}
//         slidesPerView={1}
//         pagination={{
//           clickable: true,
//           renderBullet: function (index, className) {
//             return '<span class="' + className + ' bg-primary">.</span>';
//           },
//         }}
//       >
//         <div className="d-flex flex-wrap">
//           {banner.map((image, index) => (
//             <SwiperSlide key={index}>
//               <div className="border rounded-5 border-1">
//                 <img
//                   src={image}
//                   className="img-fluid w-100"
//                   alt={`banner image ${index + 1}`}
//                   loading="lazy" // Lazy load images
//                 />
//               </div>
//             </SwiperSlide>
//           ))}
//         </div>
//       </Swiper>

//       {/* Display loading state if images are still being fetched */}
//       {/* {loading && (
//         <div className="text-center mt-3">
//           <p>Loading images...</p>
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default HeroSwiper;

// // import "../style/hero.css";
// // import React, { useEffect, useState, useRef } from "react";
// // import { Swiper, SwiperSlide } from "swiper/react";
// // import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
// // import "swiper/css";
// // import "swiper/css/navigation";
// // import "swiper/css/pagination";
// // import "swiper/css/scrollbar";
// // import "swiper/css/autoplay";

// // // Import local fallback images
// // //import img1 from "../assests/img/3.png";
// // //import img2 from "../assests/img/4.png";
// // //import img3 from "../assests/img/5.png";

// // const HeroSwiper = () => {
// //   // Initial state with local fallback images
// //   const [banner, setBanner] = useState();
// //   const [loading, setLoading] = useState(true);
// //   const swiperRef = useRef(null);
// //   const [isVisible, setIsVisible] = useState(false);

// //   useEffect(() => {
// //     // IntersectionObserver to defer loading the Swiper until it's in the viewport
// //     const observer = new IntersectionObserver(
// //       ([entry]) => {
// //         if (entry.isIntersecting) {
// //           setIsVisible(true);
// //           observer.disconnect();
// //         }
// //       },
// //       { threshold: 0.1 } // Trigger when 10% of the swiper is visible
// //     );
// //     if (swiperRef.current) {
// //       observer.observe(swiperRef.current);
// //     }
// //     return () => observer.disconnect();
// //   }, []);

// //   useEffect(() => {
// //     const getBanners = async () => {
// //       // Check if banners are already cached in sessionStorage
// //       const cachedBanners = sessionStorage.getItem("banners");
// //       if (cachedBanners) {
// //         setBanner(JSON.parse(cachedBanners));
// //         setLoading(false);
// //       } else {
// //         try {
// //           const response = await axios.get(
// //             `${process.env.REACT_APP_BASE_URL}getbanners`
// //           );
// //           setBanner(response.data);
// //           sessionStorage.setItem("banners", JSON.stringify(response.data)); // Cache the API response
// //         } catch (error) {
// //           console.error("Error fetching data:", error);
// //         } finally {
// //           setLoading(false); // Update loading state
// //         }
// //       }
// //     };

// //     if (isVisible) {
// //       getBanners(); // Fetch data only when Swiper is visible
// //     }
// //   }, [isVisible]);

// //   return (
// //     <div ref={swiperRef} className="" style={{ marginTop: "5rem", marginBottom: "2rem" }}>
// //       {loading ? (
// //         <div className="skeleton-loader">
// //           <div className="skeleton-image"></div>
// //         </div>
// //       ) : (
// //         <Swiper
// //           modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
// //           spaceBetween={0}
// //           autoplay={{ delay: 2500 }}
// //           slidesPerView={1}
// //           pagination={{
// //             clickable: true,
// //             renderBullet: function (index, className) {
// //               return '<span class="' + className + ' bg-primary">.</span>';
// //             },
// //           }}
// //         >
// //           <div className="d-flex flex-wrap">
// //             {banner.map((image, index) => (
// //               <SwiperSlide key={index}>
// //                 <div className="border rounded-5 border-1">
// //                   <img
// //                     src={image} // Direct image loading
// //                     className="img-fluid w-100"
// //                     alt={`banner image ${index + 1}`}
// //                     srcSet={`${image}?w=300 300w, ${image}?w=600 600w, ${image}?w=1200 1200w`} // Responsive images
// //                     sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 1200px" // Adapt sizes for different screen widths
// //                     loading="lazy" // Lazy loading with standard HTML attribute
// //                   />
// //                 </div>
// //               </SwiperSlide>
// //             ))}
// //           </div>
// //         </Swiper>
// //       )}
// //     </div>
// //   );
// // };

// // export default HeroSwiper;



import axios from "axios";
import "../style/hero.css";
import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

// Import local fallback images
//import img1 from "../assests/img/3.png";
//import img2 from "../assests/img/4.png";
//import img3 from "../assests/img/5.png";

const HeroSwiper = () => {
  // Initial state with local fallback images
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); // Track errors

  // Fetch banners and cache them in sessionStorage
  useEffect(() => {
    const getBanners = async () => {
      try {
        const cachedBanners = sessionStorage.getItem("banners");

        // If data is already cached, use it
        if (cachedBanners) {
          setBanner(JSON.parse(cachedBanners));
          setLoading(false);
        } else {
          // Fetch from API
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}getbanners`
          );
          if (response.data && response.data.length > 0) {
            setBanner(response.data);
            sessionStorage.setItem("banners", JSON.stringify(response.data)); // Cache in sessionStorage
          } else {
            throw new Error("No banner data");
          }
        }
      } catch (error) {
        setError(true); // Set error state if data fetching fails
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once done
      }
    };

    getBanners(); // Call function to fetch banners
  }, []); // Run only on component mount

  return (
    <div className="hero-swiper-container" style={{ marginTop: "5rem", marginBottom: "2rem" }}>
      {loading ? (
        <div className="skeleton-loader">
          <div className="skeleton-image"></div>
        </div>
      ) : error ? (
        <div className="error-message">
          <p>Failed to load images. Please try again later.</p>
        </div>
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          autoplay={{ delay: 2500 }}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + ' bg-primary">.</span>';
            },
          }}
        >
          <div className="d-flex flex-wrap">
            {banner.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="border rounded-5 border-1">
                  <img
                    src={image} // Direct image loading
                    className="img-fluid w-100"
                    alt={`banner image ${index + 1}`}
                    srcSet={`${image}?w=300 300w, ${image}?w=600 600w, ${image}?w=1200 1200w`} // Responsive images
                    sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 1200px" // Adapt sizes for different screen widths
                    loading="lazy" // Lazy loading with standard HTML attribute
                  />
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      )}
    </div>
  );
};

export default HeroSwiper;
