import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useLocation, useParams } from "react-router-dom";
import "../style/mostpopular.css";
import axios from "axios";
// import "../style/mostpopular.css";

function MostPopular() {
  const location = useLocation();
  const data = location.state;


  const renderParagraphWithBreaks = (paragraph) => {
    if (!paragraph) return null;
    // Split the paragraph wherever '\n' or '<br>' is encountered
    const parts = paragraph.split(/\n|<br\s*\/?>|(\*{1}.*?\*{1})/);
    // Map each part to a <p> or <strong> element based on whether it's bold or not
    return parts.map((part, index) => {
      if (!part) return null; // Add a null check here
      if (part.startsWith("*") && part.endsWith("*")) {
        // Render text in between asterisks as bold
        return <strong key={index}>{part.slice(1, -1)}</strong>;
      } else if (part === "\n" || part === "<br>") {
        return;
      } else if (part === "") {
        return null;
      } else {
        return <p key={index}>{part}</p>;
      }
    });
  };

  // for link copy past

  const { title } = useParams();

  // //  Decoding the URL parameter to handle special characters
  const decodedTitle = decodeURIComponent(title);
  const encodedTitle = encodeURIComponent(title);

  // const encodedTitle = encodeURIComponent(title);

  useEffect(() => {
    const encodedTitle = encodeURIComponent(title);
    const decodedTitle = decodeURIComponent(title);
  }, [title]);

  // const modifiedTitle = title.replaceAll("-", " ");
  const [blogData, setBlogData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const modifiedTitle = title.replaceAll("-", " ");
    const decodedTitle = decodeURIComponent(title);
    const fetchData = async () => {
      try {
        setIsLoading(true); // Step 3: Set loading state to true before fetching data
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}getBlogs?blogTitle=${modifiedTitle}`
        );
        await setBlogData(response.data);
        setIsLoading(false); // Step 3: Set loading state to false after fetching data
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="container  pt-5 mt-5 mb-4">
        <div className=" cursor-pointer ">
          <Link to={"/blogs"} className="text-dark text-decoration-none ">
            <FaArrowLeftLong className="p-1" size={25} />
            back
          </Link>
        </div>

        {data && (
          <div className="col-12">
            <h1 className="mt-3">{data.titleOfTheBlog}</h1>
            <div className="">
              <div className="d-flex flex-wrap justify-content-start">
                <span className="text-secondary ">
                  {/* {new Date(data.updatedAt).toLocaleDateString()} */}
                </span>

                {/* <ul className="text-secondary  ">
                <li className="">
                  {data.author !== "" ? data.author : "Path2Study"}
                </li>
              </ul> */}
              </div>
            </div>
            <img
              src={data.bannerImage}
              alt=""
              // height="250px"
              width="100%"
              className=" custome-height-image"
            />
            <h4 className="mt-4">{data.paragraphAHeading}</h4>
            {/* <p className="" style={{ textAlign: "justify" }}>
            {data.paragraphA}
          </p> */}
            {renderParagraphWithBreaks(data.paragraphA)}

            <h5 className=" mb-3">{data.paragraphBHeading}</h5>
            {/* <p style={{ textAlign: "justify" }} className="mt-2">
            {data.paragraphB}
          </p> */}
            {renderParagraphWithBreaks(data.paragraphB)}
            <h5 className="mt-4">{data.paragraphCHeading}</h5>
            {/* <p style={{ textAlign: "justify" }}>{data.paragraphC}</p> */}
            {renderParagraphWithBreaks(data.paragraphC)}
          </div>
        )}
        {!isLoading  && !data ? (
          <div className="col-12">
            <h1 className="mt-3">{blogData.titleOfTheBlog}</h1>
            <div className="">
              <div className="d-flex flex-wrap justify-content-start">
                <span className="text-secondary "></span>
              </div>
            </div>
            <img
              src={blogData.bannerImage}
              alt=""
              width="100%"
              className=" custome-height-image"
            />
            <h4 className="mt-4">{blogData.paragraphAHeading}</h4>

            {renderParagraphWithBreaks(blogData.paragraphA)}

            <h5 className=" mb-3">{blogData.paragraphBHeading}</h5>

            {renderParagraphWithBreaks(blogData.paragraphB)}
            <h5 className="mt-4">{blogData.paragraphCHeading}</h5>
            {renderParagraphWithBreaks(blogData.paragraphC)}
          </div>
        ) : (
          // <>
          //   <div className="loader-container-contact vh-100">
          //     <span className="loader-contact"></span>
          //   </div>
          // </>
          null
        )}
      </div>
    </>
  );
}

export default MostPopular;

// import React, { useEffect, useState } from "react";
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { Link, useLocation, useParams } from "react-router-dom";
// import "../style/mostpopular.css";
// import axios from "axios";
// // import decodeURIComponent from 'decodeURIComponent';

// function MostPopular() {
//   const location = useLocation();
//   const data = location.state;


//   const renderParagraphWithBreaks = (paragraph) => {
//     if (!paragraph) return null;
//     // Split the paragraph wherever '\n' or '<br>' is encountered
//     const parts = paragraph.split(/\n|<br\s*\/?>|(\*{1}.*?\*{1})/);
//     // Map each part to a <p> or <strong> element based on whether it's bold or not
//     return parts.map((part, index) => {
//       if (!part) return null; // Add a null check here
//       if (part.startsWith("*") && part.endsWith("*")) {
//         // Render text in between asterisks as bold
//         return <strong key={index}>{part.slice(1, -1)}</strong>;
//       } else if (part === "\n" || part === "<br>") {
//         return;
//       } else if (part === "") {
//         return null;
//       } else {
//         return <p key={index}>{part}</p>;
//       }
//     });
//   };
//   const { title } = useParams();

//    // Decoding the URL parameter to handle special characters
//    const decodedTitle = decodeURIComponent(title);
//    const encodedTitle = encodeURIComponent(title);
//    console.log("decodedTitle--->", decodedTitle);
//    console.log('encodedTitle',encodedTitle)
//     // const encodedTitle = encodeURIComponent(title);

//     useEffect(()=>{
//       const encodedTitle = encodeURIComponent(title);
//       const decodedTitle = decodeURIComponent(title);
//       console.log('encodedTitle',encodedTitle,"decodedTitle",decodedTitle)
//     },[title])

//   console.log(title);
//   // const modifiedTitle = title.replaceAll("-", " ");
//   // console.log(title, modifiedTitle);
//   const [blogData, setBlogData] = useState();
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const modifiedTitle = title.replaceAll("-", " ");
//     const decodedTitle = decodeURIComponent(title);
//     const fetchData = async () => {
//       try {
//         setIsLoading(true); // Step 3: Set loading state to true before fetching data
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}getBlogs?blogTitle=${modifiedTitle}`
//         );
//         setBlogData(response.data);
//         setIsLoading(false); // Step 3: Set loading state to false after fetching data
//         // console.log("blog-data --------->", response.data);
//         console.log("blogData", blogData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleCardClick = (blog) => {
//     window.scrollTo(0, 0);
//     const modifiedTitle = blog.titleOfTheBlog.replaceAll("-", " ");
//     navigate(`/blogs/${modifiedTitle}`, { state: blog });
//   };

//   return (
//     <>
//       <div className="container  pt-5 mt-5 mb-4">
//         <div className=" cursor-pointer ">
//           <Link to={"/blogs"} className="text-dark text-decoration-none ">
//             <FaArrowLeftLong className="p-1" size={25} />
//             back
//           </Link>
//         </div>

//         {!isLoading ? (
//           <div className="col-12">
//             <h1 className="mt-3">{blogData.titleOfTheBlog}</h1>
//             <div className="">
//               <div className="d-flex flex-wrap justify-content-start">
//                 <span className="text-secondary "></span>
//               </div>
//             </div>
//             <img
//               src={blogData.bannerImage}
//               alt=""
//               width="100%"
//               className=" custome-height-image"
//             />
//             <h4 className="mt-4">{blogData.paragraphAHeading}</h4>

//             {renderParagraphWithBreaks(blogData.paragraphA)}

//             <h5 className=" mb-3">{blogData.paragraphBHeading}</h5>

//             {renderParagraphWithBreaks(blogData.paragraphB)}
//             <h5 className="mt-4">{blogData.paragraphCHeading}</h5>
//             {renderParagraphWithBreaks(blogData.paragraphC)}
//           </div>
//         ) : (
//           <>
//             <div className="loader-container-contact vh-100">
//               <span className="loader-contact"></span>
//             </div>
//           </>
//         )}
//       </div>
//     </>
//   );
// }

// export default MostPopular;

