import React, { useEffect, useState } from "react";
import { Modal, Form, CloseButton } from "react-bootstrap";
import "../style/CulturalIdentityPopup.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CountryDropdown } from "react-country-region-selector";
import axios from "axios";
import { toast } from "react-toastify";
import { Loader } from "./Loader";
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default function PathStudyProfileCompleteModal({
  show,
  setShow,
  handleCloseAfterLogin,
}) {
  const [isLoading, setIsLoading] = useState(false); //  for loader
  const [errors, setErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [countryByGoogle, setCountryByGoogle] = useState(""); // actual country location by ip
  // const [selectedCountry, setSelectedCountry] = useState(""); // change after location fetched
  const [selectedCountry, setSelectedCountry] = useState(
    countryByGoogle ? countryByGoogle : "United States"
  ); // change after location fetched // prblm
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(""); // new
  const [SelectedCountryToPhone, setSelectedCountryToPhone] = useState("");

  const [formData, setFormData] = useState({
    contactNumber: "",
    yourIdentity: "Student",
    tentativeHighschoolGraduationDate: "",
    country: selectedCountry || "",
  });

  // const [selectedCountry, setSelectedCountry] = useState(countryByGoogle ? countryByGoogle : "usa"); // static location by btn
  // countryByGoogle ? countryByGoogle : "usa"///// this is for usa in dropdown first on refresh
  // countryByGoogle ///// this is for actual country in dropdown first on refresh
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            setIsLoading(true);
            const { latitude, longitude } = position.coords;
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
              // `https://maps.googleapis.com/maps/api/geocode/json?latlng=41.8781,-87.6298&&key=${apiKey}` // for usa chicago
            );
            const country = response.data.results[0].address_components.find(
              (component) => component.types.includes("country")
            );
            setCountryByGoogle(country.long_name);
            setSelectedCountryToPhone(country.short_name);
            setSelectedCountry(country.long_name);
            setIsLoading(false);
            // Update the 'country' field in formData
            setFormData((prevFormData) => ({
              ...prevFormData,
              country: country.long_name,
            }));
          } catch (error) {
            console.error("Failed to fetch data:", error);
            setIsLoading(false);
          }
        },
        (error) => {
          // user close the location pop CountryContext.js:57 Error getting user location: User denied Geolocation
          setCountryByGoogle("United States");
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);
  // Validate form fields and set error messages
  const validateForm = () => {
    const newErrors = {};

    if (!formData.yourIdentity) {
      newErrors.yourIdentity = "Please select your identity.";
    }
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Please enter a contact number.";
    }
    if (!formData.tentativeHighschoolGraduationDate) {
      newErrors.tentativeHighschoolGraduationDate =
        "Please enter your tentative graduation date.";
    }
    if (!formData.country) {
      newErrors.country = "Please select a country.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const allFieldsHaveValues = Object.values(formData).every(
      (value) => value !== "" && value !== undefined && value !== null
    );
    setBtnDisable(!allFieldsHaveValues);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken}`;
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        // return;
        const response = await authAxios.put(
          `${process.env.REACT_APP_BASE_URL}/updateUser/${userId}`,
          formData
        );
       
        if (response.data.message === "Your profile details has been updated") {
          toast(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShow(!show);
          setTimeout(() => {
            window.location.reload(); //  remove to reload form
            window.scrollTo(0, 0);
          }, 2500);
        }
      } catch (error) {
        console.error("Error:", error);
      }

      // Proceed with form submission
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" }); // Clear the error message when the user starts typing
  };

  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value?.toString());
    setCountryCode(country);
    localStorage.setItem("phoneNumber", value?.toString());
    localStorage.setItem("countryCode", country);
    setFormData({
      ...formData,
      contactNumber: value?.toString(),
    });
    setErrors({ ...errors, contactNumber: "" });
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setFormData({
      ...formData,
      country: country,
    });
    setErrors({ ...errors, country: "" });
  };

  const styleform = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginBottom: ".5rem",
  };

  return (
    <div>
      {isLoading ? (
        // <Load
        <Loader />
      ) : (
        <Modal show={show} backdrop="static">
          <div className="position-relative cultural-modal">
            <div className="modal-body ">
              <div className="d-flex justify-content-end ">
                <button
                  type="button"
                  className="btn-close close-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloseAfterLogin()}
                ></button>
              </div>
              <div className="mx-3">
                <h1 className="modal-title fs-5 mt-3" id="staticBackdropLabel">
                  Please select an option to see the curated scholarships we
                  have for you
                </h1>

                <div className="mb-2">
                  <label
                    htmlFor="currentParent"
                    className="form-label my-2 fw-bold "
                  >
                    Choose Your User Type{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="yourIdentity1"
                      value="Parent"
                      name="yourIdentity"
                      onChange={handleChange}
                      checked={formData?.yourIdentity === "Parent"}
                    />
                    <label className="form-check-label" htmlFor="yourIdentity1">
                      Parent
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="yourIdentity2"
                      value="Student"
                      name="yourIdentity"
                      onChange={handleChange}
                      checked={formData?.yourIdentity === "Student"}
                    />
                    <label className="form-check-label" htmlFor="yourIdentity2">
                      Student
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="yourIdentity3"
                      value="Legal Guardian"
                      name="yourIdentity"
                      onChange={handleChange}
                      checked={formData?.yourIdentity === "Legal Guardian"}
                    />
                    <label className="form-check-label" htmlFor="yourIdentity3">
                      Legal Guardian
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="yourIdentityOther"
                      name="yourIdentity"
                      onChange={handleChange}
                      checked={formData?.yourIdentity === "Other"}
                      value="Other"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="yourIdentityOther"
                    >
                      Other
                    </label>
                  </div>
                  {errors.yourIdentity && (
                    <div className="text-danger">{errors.yourIdentity}</div>
                  )}
                </div>

                <div className="">
                  <Form.Group controlId="formBasiccontactNumber">
                    <Form.Label className="my-2 fw-bold">
                      Contact Number <span style={{ color: "red" }}>*</span>
                      <span className="text-danger fw-light ms-2">
                        (Note:-Select country first)
                      </span>
                    </Form.Label>
                    <PhoneInput
                      country={SelectedCountryToPhone}
                      // country="US"
                      value={phoneNumber || formData?.contactNumber?.toString()}
                      onChange={handlePhoneChange}
                      limitMaxLength={true}
                      defaultCountry={SelectedCountryToPhone || "US"}
                      style={styleform}
                      placeholder="+1 xxxxx"
                    />
                    {errors.contactNumber && (
                      <div className="text-danger">{errors.contactNumber}</div>
                    )}
                  </Form.Group>
                </div>

                <Form.Group controlId="formBasictententativeHighschoolGraduationDate">
                  <Form.Label className="my-2 fw-bold mt-3">
                    Tentative High School Graduation Date{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <input
                    style={styleform}
                    placeholder="mm/yyyy"
                    type="month"
                    className="form-control"
                    id="tentativeHighschoolGraduationDate"
                    name="tentativeHighschoolGraduationDate"
                    onChange={handleChange}
                    value={formData?.tentativeHighschoolGraduationDate}
                  />
                  {errors.tentativeHighschoolGraduationDate && (
                    <div className="text-danger">
                      {errors.tentativeHighschoolGraduationDate}
                    </div>
                  )}
                </Form.Group>

                <div className="">
                  <Form.Group controlId="formBasicCountry">
                    <Form.Label className="my-2 fw-bold">
                      Country <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <CountryDropdown
                      value={selectedCountry || formData.country}
                      onChange={handleCountryChange}
                      className="form-select"
                      required
                      style={styleform}
                    />
                    {errors.country && (
                      <div className="text-danger">{errors.country}</div>
                    )}
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary px-5"
                    // disabled={btnDisable}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary px-5"
              // disabled={btnDisable}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div> */}
          </div>
        </Modal>
      )}
    </div>
  );
}
