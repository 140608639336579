import React, { useState, createRef, useEffect } from "react";
import AdminSideBar from "./AdminSideBar";
import { TiCloudStorageOutline } from "react-icons/ti";
import "../styles/Editblogs.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { BsInfoCircle } from "react-icons/bs";
import { AiTwotoneEdit } from "react-icons/ai";
import DatePicker from "react-datepicker";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import "react-image-crop/dist/ReactCrop.css";

import "react-image-crop/dist/ReactCrop.css";

const Editblogs = () => {
  const [showDeletemodal, setShowDeletemodal] = useState(false);

  const locationeditblog = useLocation();
  const locationeditblogID = locationeditblog.state?.blog._id;
  const [dataToedit, setDataToedit] = useState(locationeditblog.state?.blog);

  useEffect(() => {
    setDataToedit(locationeditblog.state?.blog);
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [coverImageEdit, setCoverImageEdit] = useState("");
  const [bannerImageEdit, setBannerImageEdit] = useState("");
  const [finaleditedData, setFinaleditedData] = useState(null);
  // coverImageEdit ? coverImageEdit : ""

  const [textInput, setTextInput] = useState("");

  const handleInputBlogChange = (e) => {
    const { name, value } = e.target;

    setDataToedit((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFinaleditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const newText = e.target.value;
    // Replace newline characters with <br> tag
    const formattedText = newText.replace(/\n/g, "<br>");
    setTextInput(formattedText);
  };

  function captureEnter(event) {
    if (event.keyCode === 13) {
      document.getElementById("exampleFormControlTextarea1").value =
        document.getElementById("exampleFormControlTextarea1").value + "<br/>";
        document.getElementById("paragraphB").value =
        document.getElementById("paragraphB").value + "<br/>";
        document.getElementById("paragraphC").value =
        document.getElementById("paragraphC").value + "<br/>";
      return false;
    } else {
      return true;
    }
  }

  const handleInputDateChange = (e) => {
    // const { value } = e.target;
    // console.log("kajsbdjn habsd", e);

    setDataToedit((prevData) => ({
      ...prevData,
      createdAt: e,
    }));
    setFinaleditedData((prevData) => ({
      ...prevData,
      createdAt: e,
    }));
  };

  let adminId = localStorage.getItem("adminId");
  let adminToken = localStorage.getItem("adminToken");
  const headers = {
    authorization: `bearer ${adminToken}`,
    "Content-Type": "multipart/form-data",
  };

  const editblogNavigate = useNavigate();

  const handleSaveChnangeEditBlog = () => {
    try {
      const timestamp2 = new Date().getTime();
      const randomFileName = `${timestamp2}.png`;
      const byteCharacters = atob(cropData?.split(",")[1]);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters?.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust type based on your image format

      const file = new File([blob], randomFileName, {
        type: blob.type,
      });

      // Convert base64 to Blob
      const byteCharactersTwo = atob(cropData2?.split(",")[1]);
      const byteNumbersTwo = new Array(byteCharactersTwo?.length);
      for (let i = 0; i < byteCharactersTwo?.length; i++) {
        byteNumbersTwo[i] = byteCharactersTwo?.charCodeAt(i);
      }
      const byteArrayTwo = new Uint8Array(byteNumbersTwo);
      const blobTwo = new Blob([byteArrayTwo], { type: "image/png" }); // Adjust type based on your image format

      const timestamp = new Date().getTime();
      const randomFileName2 = `${timestamp}.png`;

      const file2 = new File([blobTwo], randomFileName2, {
        type: blobTwo.type,
      });
      finaleditedData.coverImage = file;
      finaleditedData.bannerImage = file2;
    } catch (error) {
      console.error(error);
    }
    // finaleditedData.coverImage = coverImageEdit?.file;

    delete finaleditedData.file;
    delete finaleditedData.imageUrl;
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateblog/${adminId}/${dataToedit._id}`,
        finaleditedData,
        { headers: headers }
      )
      .then((responce) => {
        setShow(false);
        toast.success("Blog details updated succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          editblogNavigate("/admin/blogs-management/");
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteBlog = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/admin/updateblog/${adminId}/${locationeditblogID}`,
        {
          isDeleted: true,
        },
        { headers: headers }
      )

      .then((responce) => {
        toast.success("blog deleted succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          editblogNavigate("/admin/blogs-management");
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImageChangeCoverImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFinaleditedData({ file, imageUrl: URL.createObjectURL(file) });
      setShowImageCrop(true);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file); // Read file as data URL
      setFinaleditedData((prevData) => ({
        ...prevData,
        coverImage: file,
      }));
    }
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleImageChangeBannerImage = (e) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        setFinaleditedData({ file, imageUrl: URL.createObjectURL(file) });
        setShowImage2Crop(true);
        const reader = new FileReader();
        reader.onload = () => {
          setImage2(reader.result);
        };
        reader.readAsDataURL(file); // Read file as data UR
        setFinaleditedData((prevData) => ({
          ...prevData,
          bannerImage: file,
        }));
      }
      e.preventDefault();
      let files;
      e.preventDefault();
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage2(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const [hoverEdit, setHoverEdit] = useState(false);
  const [hoverBannerEdit, setHoverBannerEdit] = useState(false);

  //css
  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };

  // cropper //
  const [image, setImage] = useState(coverImageEdit);

  const [cropData, setCropData] = useState("");
  const [image2, setImage2] = useState(bannerImageEdit);
  const [cropData2, setCropData2] = useState("");
  const [showImage2Crop, setShowImage2Crop] = useState();
  // try {
  var [showImageCrop, setShowImageCrop] = useState();

  var cropperRef = createRef();

  var getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setShowImageCrop(false);
    }
  };

  // cropper 2//

  var cropperRef2 = createRef();

  var getCropData2 = () => {
    if (typeof cropperRef2.current?.cropper !== "undefined") {
      setCropData2(cropperRef2.current?.cropper.getCroppedCanvas().toDataURL());
      setShowImage2Crop(false);
    }
  };

  const handleImageCropClose = () => {
    setShowImageCrop(false);
    setImage(""); // Reset image state
    setCropData(""); // Reset crop data
    window.location.reload();
  };

  const handleImage2CropClose = () => {
    setShowImage2Crop(false);
    setImage2(""); // Reset image state
    setCropData2("");
    window.location.reload();
  };

  return (
    <div
      style={{ backgroundColor: "#EFF2F5" }}
      className="Edit  forPageBackground"
    >
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          <div className="col-10 py-3 rounded-3">
            <div className="px-3">
              <div className="d-flex">
                <BreadCrumb />
                <div className="px-4 py-3">
                  <div className="d-flex justify-content-between">
                    <FaCircleUser size={25} className="mt-1 mx-2" />
                    <div className="align-middle mt-2">
                      {localStorage.getItem("adminName")}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-1"></hr>
            </div>
            <div className="px-3">
              <div className="forBackgroundColor rounded-3  mt-5 p-3">
                <h3 className="py-2">Edit blog</h3>
                <div className="d-flex justify-content">
                  <div className="col-5">
                    <div className="bg-white rounded-3 p-5">
                      <label>Title of the blog</label>
                      {/* <span className="text-danger">(Mandotory field)</span> */}
                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Title"
                        //   name="scholarshipName"
                        name="titleOfTheBlog"
                        defaultValue={dataToedit?.titleOfTheBlog}
                        onChange={handleInputBlogChange}
                        style={forInputField}
                      />
                      <label className="mt-3">
                        Add cover image of the blog
                        <span
                          className="pt-2 align-middle"
                          onMouseEnter={() => setHoverEdit(true)}
                          onMouseLeave={() => setHoverEdit(false)}
                        >
                          <BsInfoCircle className="py-1" size={22} />
                        </span>
                      </label>

                      <div>
                        {/* <BsInfoCircle /> */}
                        <div className="position-relative">
                          {hoverEdit && (
                            <div className="absolute-content-info">
                              <div className="box-style-info text-white">
                                <ul
                                  className="my-2 px-3"
                                  style={{ textAlign: "justify" }}
                                >
                                  Cover image will appear on the blog feed.
                                  Choose a relevant image with 320x200
                                  resolution, high-quality, and eye-catching to
                                  attract more readers to your post.
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-0 border-0 editclick-container">
                        {/* {dataToedit?.coverImage !== "" ? ( */}
                        <div className="rounded-3  mb-3">
                          <img
                            src={
                              cropData === ""
                                ? dataToedit?.coverImage
                                : cropData
                            }
                            height={200}
                            width="100%"
                            className="rounded"
                          />
                          <div className="text-end ">
                            <label
                              htmlFor="fileInputTwo"
                              className="bg-warning col-1 cursor-pointer editclick rounded"
                            >
                              <AiTwotoneEdit fill="" size={25} />
                            </label>
                          </div>
                          <input
                            type="file"
                            id="fileInputTwo"
                            className="file-input"
                            accept="image/*"
                            name="coverImage"
                            style={forInputField}
                            onChange={(e) => handleImageChangeCoverImage(e)}
                          />
                        </div>
                      </div>

                      <label className="">
                        Add banner image of the blog
                        <span
                          className="pt-2 align-middle"
                          onMouseEnter={() => setHoverBannerEdit(true)}
                          onMouseLeave={() => setHoverBannerEdit(false)}
                        >
                          <BsInfoCircle className="py-1" size={22} />
                        </span>
                      </label>

                      <div>
                        {/* <BsInfoCircle /> */}
                        <div className="position-relative">
                          {hoverBannerEdit && (
                            <div className="absolute-content-info">
                              <div className="box-style-info text-white">
                                <ul
                                  className="my-2 px-3"
                                  style={{ textAlign: "justify" }}
                                >
                                  Banner image will appear inside the blog.
                                  Choose an eye-catching image with 820x380
                                  resolution, to attract more readers to your
                                  post.
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-0 border-0">
                        <div className="rounded editbanner-container">
                          <img
                            src={
                              cropData2 === ""
                                ? dataToedit.bannerImage
                                : cropData2
                            }
                            height={200}
                            width="100%"
                            className="rounded"
                          />
                          <div className="text-end ">
                            <label
                              htmlFor="fileInput"
                              className="bg-warning col-1 cursor-pointer editclick-banner rounded"
                            >
                              {" "}
                              <AiTwotoneEdit fill="" size={25} />
                            </label>
                          </div>
                          <input
                            type="file"
                            id="fileInput"
                            className="file-input"
                            accept="image/*"
                            name="bannerImage"
                            onChange={(e) =>
                              handleImageChangeBannerImage(
                                e,
                                setBannerImageEdit
                              )
                            }
                          />
                        </div>
                      </div>

                      <label className="mt-3">Paragraph (A) heading </label>
                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Paragraph A heading"
                        name="paragraphAHeading"
                        style={forInputField}
                        defaultValue={dataToedit?.paragraphAHeading}
                        onChange={handleInputBlogChange}
                      />

                      <div>
                        <label htmlFor="" className="mt-3">
                          Paragraph (A)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          name="paragraphA"
                          style={forInputField}
                          maxLength={3000}
                          defaultValue={dataToedit?.paragraphA}
                          onChange={handleInputBlogChange}
                          onKeyPress={captureEnter}
                        ></textarea>

                        <p className="mt-1 mb-0">
                          Max character limit: 3000 words
                        </p>

                        <label className="mt-3">Paragraph (B) heading </label>
                        <input
                          className="form-control forInputFiledAddblog"
                          type="text"
                          placeholder="Paragraph B heading"
                          name="paragraphBHeading"
                          style={forInputField}
                          defaultValue={dataToedit?.paragraphBHeading}
                          onChange={handleInputBlogChange}
                          // paragraphBHeading
                        />
                        <label htmlFor="" className="mt-3">
                          Paragraph (B)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          // id="paragraphB"
                          id="paragraphB"
                          rows="5"
                          name="paragraphB"
                          style={forInputField}
                          maxLength={3000}
                          defaultValue={dataToedit?.paragraphB}
                          onChange={handleInputBlogChange}
                          onKeyPress={captureEnter}
                        ></textarea>

                        <p className="mt-1 mb-0">
                          Max character limit: 3000 words
                        </p>
                        {/*  b paragraph */}

                        <label className="mt-3">Paragraph (C) heading </label>
                        <input
                          className="form-control forInputFiledAddblog"
                          id="paragraphCHeading"
                          type="text"
                          placeholder="Paragraph C heading"
                          name="paragraphCHeading"
                          style={forInputField}
                          defaultValue={dataToedit?.paragraphCHeading}
                          onChange={handleInputBlogChange}
                        />

                        <label htmlFor="" className="mt-3">
                          Paragraph (C)
                        </label>
                        <textarea
                          className="form-control forInputFiledAddblog"
                          // id="paragraphC"
                          id="paragraphC"
                          rows="5"
                          name="paragraphC"
                          style={forInputField}
                          maxLength={3000}
                          defaultValue={dataToedit?.paragraphC}
                          onChange={handleInputBlogChange}
                          onKeyPress={captureEnter}
                        ></textarea>

                        <p className="mt-1">Max character limit: 3000 words</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 bg-white rounded-3">
                    <div className="bg-white rounded-3 pt-5">
                      <label className="">Publish date </label>
                      <br></br>

                      <DatePicker
                        className="form-control"
                        selected={new Date(dataToedit?.createdAt)}
                        maxDate={new Date()}
                        name="createdAt"
                        disabled
                        id="exampleDate"
                        dateFormat="MM/dd/yyyy"
                        onChange={handleInputDateChange} // Pass the function directly
                      />

                      {finaleditedData?.createdAt ? (
                        <div>
                          {!isNaN(Date.parse(finaleditedData?.createdAt))
                            ? new Date(
                                finaleditedData?.createdAt
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            : "Invalid Date"}
                        </div>
                      ) : (
                        <div>
                          {!isNaN(Date.parse(dataToedit?.createdAt))
                            ? new Date(
                                dataToedit?.createdAt
                              ).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              })
                            : "Invalid Date"}
                        </div>
                      )}

                      <label className="">Author </label>
                      <input
                        className="form-control forInputFiledAddblog"
                        type="text"
                        placeholder="Enter the Author"
                        name="author"
                        style={forInputField}
                        defaultValue={dataToedit?.author}
                        onChange={handleInputBlogChange}
                      />

                      <ToastContainer />
                    </div>
                  </div>
                  <div className="col-4 bg-white rounded-3 pt-5 px-5">
                    <h6> PREVIEW</h6>
                    <div className="col-12 ">
                      {/* {coverImageEdit ? ( */}

                      {dataToedit?.coverImage ? (
                        <img
                          src={
                            cropData === "" ? dataToedit?.coverImage : cropData
                          }
                          height={150}
                          width="100%"
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <h5 className="mt-3">
                      {finaleditedData?.titleOfTheBlog ? (
                        <span className="mt-2">
                          {dataToedit.titleOfTheBlog}
                        </span>
                      ) : (
                        <span className="mt-2">
                          {dataToedit.titleOfTheBlog}
                        </span>
                      )}
                    </h5>
                    <p>
                      {finaleditedData?.datePublish
                        ? new Date(
                            finaleditedData?.datePublish
                          ).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        : new Date(dataToedit?.createdAt).toLocaleDateString(
                            "en-US",
                            // { year: "numeric", month: "long", day: "numeric" }
                            { month: "short", day: "numeric", year: "numeric" }
                          )}{" "}
                      &nbsp; • {/* {dataToedit?.author} */}
                      {finaleditedData?.author ? (
                        <span>{finaleditedData?.author}</span>
                      ) : (
                        <span>{dataToedit?.author}</span>
                      )}
                    </p>
                    <div className="">
                      <p className="text-muted">
                        This is the how your edited blog card will appear to the
                        users of the website.
                      </p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="col-6 pe-1">
                        <button
                          style={{
                            color: " #01477A",
                            backgroundColor: "#EFF2F5",
                          }}
                          type="button"
                          className=" w-100 p-2 border border-muted rounded"
                          onClick={() => setShowDeletemodal(true)}
                        >
                          Delete
                        </button>
                      </div>
                      <div className="col-6 px-1">
                        <button
                          style={{ color: " #3771C8" }}
                          type="button"
                          disabled={finaleditedData === null}
                          className=" w-100 p-2 border-primary border bg-white rounded"
                          //   onClick={() => handleSaveChnangeEditBlog()}
                          onClick={() => setShow(true)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* modal to save chnages */}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirm save changes ?</Modal.Title>
                    </Modal.Header>

                    <p className="px-3">
                      All the blog information will be saved. Do you want to
                      proceed ?
                    </p>

                    {/* </Modal.Body> */}
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={handleSaveChnangeEditBlog}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* modal for delete button */}
                  <Modal
                    show={showDeletemodal}
                    onClick={() => setShowDeletemodal(false)}
                  >
                    <Modal.Header
                      closeButton
                      // onClick={() => setShowDelete(false)}
                    >
                      <Modal.Title>Delete Blog?</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body> */}{" "}
                    <p className="px-3">
                      Are you sure you want to delete{" "}
                      {dataToedit.titleOfTheBlog}? Once deleted, this action
                      cannot be undone.
                    </p>
                    {/* </Modal.Body> */}
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowDeletemodal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="danger"
                        //   onClick={() => handleDiscard()}
                        onClick={() => deleteBlog()}
                      >
                        Delete
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* cropper */}

                  <Modal show={showImageCrop} onHide={handleImageCropClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Crop Cover Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div className="px-5">
                          <Cropper
                            ref={cropperRef}
                            style={{ height: "200px", width: "100%" }}
                            // zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={200}
                            minCropBoxWidth={384}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            guides={true}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleImageCropClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={() => getCropData()}>
                        Crop Image
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Banner Modal */}

                  <Modal show={showImage2Crop} onHide={handleImage2CropClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Crop Banner Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        {/* <input type="file" onChange={onChange} /> */}

                        <div className="px-5">
                          <Cropper
                            ref={cropperRef2}
                            style={{ height: "200px", width: "100%" }}
                            // zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image2}
                            viewMode={1}
                            minCropBoxHeight={200}
                            minCropBoxWidth={384}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            guides={true}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleImage2CropClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={getCropData2}>
                        Crop Image
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editblogs;
