import React, { useEffect, useState } from "react";
import "../styles/AddBank.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
// import { BackNavigation } from "./BackNavigation";
import BreadCrumb from "./BreadCrumb";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { FaCircleUser } from "react-icons/fa6";
// import ReactCountriesInput from "react-countries-input";
import Cropper from "react-cropper";
import cropperRef from "react-cropper";
// import { countries } from 'countries-list';
// import { RxCrossCircled } from "react-icons/rx";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
function AddBank() {
  const adminToken = localStorage.getItem("adminToken");
  const adminId = localStorage.getItem("adminId");

  const [addBankData, setAddBankData] = useState({
    bankName: "",
    // country: "",
    fixedInterestRate: "",
    variableInterestRate: "",
    aboutTheLoan: "",
    aboutTheBank: "",
    disclosure:
      "Path2Study.com is an affiliate website. We may receive a small fee from student loan lenders when our customers opt for student loans through links on our site. This helps support our efforts to provide valuable information and resources to students. However, please note that our recommendations are based on unbiased evaluations of loan options to best meet your needs.",
    redirectionalUrl: "",
    image: "file",
    // culturalIdentity: "",
  });
  const [image, setImage] = useState(null);

  const headers = {
    authorization: `bearer ${adminToken}`,
    "Content-Type": "multipart/form-data",
  };

  const handleChange = (e) => {
    // setLenderLogo(true)
    if (e.target.type === "file") {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
        setAddBankData({
          ...addBankData,
          Image: e.target.files[0],
        });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      setAddBankData({
        ...addBankData,

        [e.target.name]: e.target.value,
      });
    }
  };

  // const [culturlaIsentitySelection, setculturlaIsentitySelection] = useState([]);

  const [discardShow, setDiscardShow] = useState(false);

  const handleDiscard = () => {
    setDiscardShow(true);
  };

  const handleComfirmDiscard = () => {
    setDiscardShow(false);
    setAddBankData({
      bankName: "",
      // country: "",
      fixedInterestRate: "",
      variableInterestRate: "",
      aboutTheLoan: "",
      aboutTheBank: "",
      disclosure: "",
      redirectionalUrl: "",
      image: "file",
      culturalIdentity: "",
    });

    setCheckOne(false);
    setCheckTwo(false);
    setCheckThree(false);
    setSelectedCountryTwo("");
    setImage("");
  };

  const [saveShow, setSaveShow] = useState(false);
  const [lenderLogo, setLenderLogo] = useState(false);

  const handleSaveChange = () => {
    setSaveShow(true);
  };

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch the list of countries from the REST Countries API
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setShowMap(true); // Always show the country list when the input is being typed
  };
  const filteredCountries = countries.filter((country) =>
    country.name.common.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [showMap, setShowMap] = useState(true);

  const handleCountrySelect = (selectedCountry) => {
    setAddBankData({
      ...addBankData,
      country: selectedCountry,
    });
    setShowMap(false); // This line should only execute if a country is selected
  };
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);

  const [selectedCountryTwo, setSelectedCountryTwo] = useState();

  const handleCountryChange = (country) => {
    setSelectedCountryTwo(country);

    // Reset state when country changes
  };

  const navigate = useNavigate();
  const handleSaveClick = (e) => {
    e.preventDefault();
    setSaveShow(false);
    // toast.success("New lender added succesfully", {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   // onClose: navigate("/admin/scholarshipmanagement/")
    //   // setTimeout(delayFunction, 4000))
    // });
    // setTimeout(() => {
    //   navigate("/admin/loan-management/");
    // }, 3000);
    // window.location.reload();

    const culturlaIsentitySelection = [];

    if (checkOne) {
      culturlaIsentitySelection.push(
        "US citizen or GC holder with US cosigner"
      );
    }
    if (checkTwo) {
      culturlaIsentitySelection.push(
        "US citizen or GC holder with no US cosigner"
      );
    }
    if (checkThree) {
      culturlaIsentitySelection.push("Non-US citizen");
    }

    if (culturlaIsentitySelection.length == 0) {
      addBankData.culturalIdentity = JSON.stringify(["ALL"]);
    } else {
      addBankData.culturalIdentity = JSON.stringify(culturlaIsentitySelection);
    }



    if (selectedCountryTwo) {
      addBankData.country = selectedCountryTwo;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/addLoan/${adminId}`,
        addBankData,
        { headers: headers }
      )
      .then((response) => {
        setSaveShow(false);

        toast.success("New lender added succesfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/loan-management/");
        }, 3000);

        // Clear the form data upon successful save
        setAddBankData({
          bankName: "",
          // country: "",
          fixedInterestRate: "",
          variableInterestRate: "",
          aboutTheLoan: "",
          aboutTheBank: "",
          disclosure: "",
          redirectionalUrl: "",
          image: "file",
          culturalIdentity: "",
        });
      })
      .catch((error) => {
        console.error("admin login error", error);
      });
  };

  return (
    <div className="d-flex ">
      <div className="col-2">
        <AdminSideBar />
      </div>
      <div style={{ backgroundColor: "#EFF2F5" }} className="">
        {/* <BackNavigation /> */}

        <div className="px-3">
          <div className="d-flex mt-3">
            <BreadCrumb />
            <div className="px-4 py-3">
              <div className="d-flex justify-content-between">
                <FaCircleUser size={25} className="mt-1 mx-2" />
                <div className="align-middle mt-2">
                  {localStorage.getItem("adminName")}
                </div>
              </div>
            </div>
          </div>
          <hr className=" my-1" />
        </div>

        <div className="mx-3 my-3">
          <div
            style={{ backgroundColor: "#F9FAFC" }}
            className="  px-4 rounded-4"
          >
            <h4 className="pt-4">+Add new lender</h4>
            <div className=" bg-white rounded-3 shadow p-4">
              <div className=" d-flex flex-wrap justify-content-between ">
                <div className="col-4 pe-4">
                  <label>Lender name</label>
                  <input
                    type="text"
                    className="form-control  shadow-none  input"
                    value={
                      addBankData?.bankName
                        ? addBankData?.bankName?.charAt(0)?.toUpperCase() +
                          addBankData?.bankName?.slice(1)
                        : ""
                    }
                    name="bankName"
                    onChange={handleChange}
                  ></input>

                  <label className="mt-3">Fixed Interest Rates</label>
                  <input
                    type="text"
                    className="form-control  shadow-none  input"
                    value={addBankData?.fixedInterestRate}
                    name="fixedInterestRate"
                    onChange={handleChange}
                  ></input>

                  <label className="mt-3">About the loan</label>
                  <textarea
                    type="text"
                    rows={6}
                    value={
                      addBankData?.aboutTheLoan
                        ? addBankData?.aboutTheLoan?.charAt(0)?.toUpperCase() +
                          addBankData?.aboutTheLoan?.slice(1)
                        : ""
                    }
                    // value={addBankData?.aboutTheLoan}
                    className="form-control  shadow-none  input "
                    name="aboutTheLoan"
                    onChange={handleChange}
                  />

                  <label className="mt-3">About the Lender (Know More)</label>
                  <textarea
                    type="text"
                    rows={6}
                    value={
                      addBankData?.aboutTheBank
                        ? addBankData?.aboutTheBank?.charAt(0)?.toUpperCase() +
                          addBankData?.aboutTheBank?.slice(1)
                        : ""
                    }
                    // value={addBankData?.aboutTheBank}
                    className="form-control  shadow-none  input"
                    name="aboutTheBank"
                    onChange={handleChange}
                  />

                  <label className="mt-3">Please add Lender logo/image</label>
                  <div className="border-container cursor-pointer rounded-3 p-2 ">
                    <label
                      htmlFor="file-input"
                      className="text-center cursor-pointer  text-muted mt-3"
                    >
                      <div className="text-center">
                        <img
                          src={require("../../assests/img/Icon.png")}
                          className=""
                        />
                      </div>
                      Browse and choose the files you want to upload from your
                      system.{" "}
                    </label>
                    <input
                      id="file-input"
                      onChange={handleChange}
                      // onChange={(e)=>setLenderLogo(true)}
                      className="d-none"
                      value={addBankData?.file}
                      type="file"
                      name="image"
                    />
                  </div>
                  {/* <button className="btn w-100 rounded mt-2 border-1  border border-primary">
                    Upload Bank Logo
                  </button> */}
                  <label className="mt-3">
                    Add redirection URL link of lender
                  </label>
                  <input
                    type=" text"
                    className="form-control  shadow-none  input"
                    placeholder="URL link of bank website"
                    name="redirectionalUrl"
                    value={addBankData?.redirectionalUrl}
                    onChange={handleChange}
                  ></input>
                </div>

                <div className="col-4 ">
                  {/* <label className="">Country</label>
                <input
                  type="text"
                  className="form-control  shadow-none  input"
                  name="country"
                  autoComplete="off"
                  value={addBankData?.country}
                  onChange={handleChange}
                  onInput={handleSearch}
                ></input>
                <div className="country-container">
                {searchTerm && filteredCountries.length > 0 && (
                  <div className="country">
                    {filteredCountries.map((country, index) => (
                        index<=5?<div className="country-background px-3" key={index} onClick={ () => handleCountrySelect(country.name.common)}>{country.name.common}</div>:null
                    ))}
                  </div>
                )} */}

                  {/* <label className="">Country</label>
                <input
                  type="text"
                  className="form-control  shadow-none  input"
                  name="country"
                  autoComplete="off"
                  value={addBankData?.country}
                  onChange={handleChange}
                  onInput={handleSearch}
                ></input> */}
                  <div className="">
                    {/* Select Country */}
                    <label>Country</label>
                    <CountryDropdown
                      value={selectedCountryTwo}
                      onChange={handleCountryChange}
                      className="form-select  shadow-none  input"
                      // style={forInputField}
                    />

                    {/* Display selected country */}
                    {/* {selectedCountry && (
                          <div>
                            <p className="mt-2">
                              Selected Country: {selectedCountry}
                            </p>
                          </div>
                        )} */}

                    {/* Select State based on the selected country */}

                    <div className="mt-3"></div>

                    {/* Display selected state */}
                  </div>

                  {showMap !== true ? (
                    ""
                  ) : (
                    <div className="country-container">
                      {searchTerm && filteredCountries.length > 0 && (
                        <div className="country">
                          {filteredCountries.map((country, index) =>
                            index <= 5 ? (
                              <div
                                className="country-background px-3"
                                key={index}
                                onClick={() =>
                                  handleCountrySelect(country.name.common)
                                }
                              >
                                {country.name.common}
                              </div>
                            ) : null
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <label className="">Variable interest Rates</label>
                  <input
                    type="text"
                    className="form-control  shadow-none  input"
                    value={addBankData?.variableInterestRate}
                    name="variableInterestRate"
                    onChange={handleChange}
                  ></input>

                  <div>
                    <label className="mt-3">Disclosure</label>
                    <textarea
                      type="text"
                      rows={6}
                      className="form-control shadow-none input"
                      name="disclosure"
                      value={addBankData?.disclosure || ""}
                      defaultValue={
                        addBankData?.disclosure
                          ? addBankData?.disclosure.charAt(0).toUpperCase() +
                            addBankData?.disclosure.slice(1)
                          : "Path2Study.com is an affiliate website. We may receive a small fee from student loan lenders when our customers opt for student loans through links on our site. This helps support our efforts to provide valuable information and resources to students. However, please note that our recommendations are based on unbiased evaluations of loan options to best meet your needs."
                      }
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="mt-3">
                  <label>Cultural Identity</label>




                  


                
                  
                </div> */}

                  <form>
                    <h6 className="mt-4">Cultural Identity :</h6>

                    <div className="d-flex">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="US citizen or GC holder with US cosigner"
                          checked={checkOne}
                          onChange={() => setCheckOne(!checkOne)}
                        />
                      </div>
                      <div for="vehicle1" className="col-11">
                        {" "}
                        US citizen or GC holder with US cosigner
                      </div>
                    </div>

                    <div className="d-flex mt-2">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="US citizen or GC holder with no US cosigner"
                          checked={checkTwo}
                          onChange={() => setCheckTwo(!checkTwo)}
                          // onChange={(e) => handleEthincity(e)}
                        />
                      </div>
                      <div for="vehicle2" className="col-11">
                        US citizen or GC holder with no US cosigner
                      </div>
                      <br />
                    </div>

                    <div className="d-flex mt-2">
                      <div className="col-1">
                        <input
                          type="checkbox"
                          id="vehicle3"
                          name="vehicle3"
                          value="Non-US citizen"
                          checked={checkThree}
                          onChange={() => setCheckThree(!checkThree)}
                          // onChange={(e) => handleEthincity(e)}
                        />
                      </div>
                      <div for="vehicle3" className="col-11">
                        Non-US citizen
                      </div>
                    </div>

                    {/* <input type="submit" value="Submit" /> */}
                  </form>
                </div>

                {/* modal for logo selection */}

                <Modal
                  show={lenderLogo}
                  onHide={() => setLenderLogo(!lenderLogo)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Crop Cover Image</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div>
                      <div className="px-5">
                        <Cropper
                          ref={cropperRef}
                          style={{ height: "auto", width: "100%" }}
                          zoomTo={0.5}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={200}
                          minCropBoxWidth={384}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                          guides={true}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="box" style={{ width: "50%" }}>
                        <h5>Preview</h5>
                        <div
                          className="img-preview"
                          style={{ height: 200, width: "384px" }}
                        />

                        <img src={addBankData?.file?.imageUrl} />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      // onClick=
                      // {handleImageCropClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      // onClick={getCropData}
                    >
                      Crop Image
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* card for preview */}

                <div className="col-4  px-4">
                  <h6>PREVIEW</h6>
                  <div class="card rounded">
                    <div className="d-flex flex-wrap rounded p-3">
                      {image && (
                        <div className="rounded-top">
                          <img
                            src={image}
                            alt=""
                            className="rounded-top img-fluid"
                            name="bankLogo"
                          />
                        </div>
                      )}
                    </div>

                    <div class="card-body mt-0 pt-0">
                      <h5 class="card-title">{addBankData.bankName}</h5>
                      <hr></hr>
                      <div className="d-flex justify-content-between">
                        <label className="text-muted col-6">
                          Variable Rates:
                        </label>
                        <h6 className="mt-1 col-6">
                          {addBankData?.variableInterestRate}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="text-muted col-6">Fixed Rates:</label>
                        <h6 className="col-6">
                          {addBankData.fixedInterestRate}
                        </h6>
                      </div>
                      <div
                        style={{ height: "100px" }}
                        className="overflow-auto"
                      >
                        <ul>
                          <li>{addBankData.aboutTheLoan}</li>
                        </ul>
                      </div>
                      <div>
                        <span
                          className="d-flex justify-content-end text-decoration-underline  me-1 cursor-pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target={`#KnowmoreModal-${item._id}`}
                          // key={`trigger-${item._id}`}
                        >
                          Know More &gt;&gt;
                          {/* pop up */}
                        </span>
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="col-6 pe-1">
                          <button
                            className="btn border w-100"
                            // onClick={handleDisclosure}
                            // data-bs-toggle="modal"
                            // data-bs-target={`#DisclosureModal-${addBankData._id}`}
                            // key={`trigger-${addBankData._id}`}
                          >
                            Disclosure
                          </button>
                        </div>
                        <div className="col-6 px-1">
                          <Link
                            // to={addBankData.redirectionalUrl}
                            // target="_blank"
                            className="btn btn-primary w-100"
                          >
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p
                    style={{ textAlign: "justify" }}
                    className="mt-3 text-secondary"
                  >
                    This is the how your newly added lender card will appear to
                    the users of the website.
                  </p>
                  <div className="d-flex flex-wrap justify-content-between mt-3 ">
                    <div className="col-6 pe-1">
                      <button
                        onClick={handleDiscard}
                        className=" w-100  btn border "
                      >
                        Discard
                      </button>
                    </div>
                    <div className="col-6 px-1">
                      <button
                        disabled={
                          !addBankData.bankName.trim() ||
                          !addBankData.fixedInterestRate.trim() ||
                          !addBankData.aboutTheLoan.trim() ||
                          !addBankData.aboutTheBank.trim() ||
                          !addBankData.image.trim() ||
                          !addBankData.redirectionalUrl.trim() ||
                          // !addBankData.country.trim() ||
                          !addBankData.variableInterestRate.trim() ||
                          !addBankData.disclosure.trim()
                        }
                        className="btn w-100 btn-primary  "
                        onClick={handleSaveChange}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                {/* ---------save---------------- */}
                <ToastContainer />
                <Modal show={saveShow} onHide={() => setSaveShow(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add new lender?</Modal.Title>
                  </Modal.Header>
                  {/* <Modal.Body> */}
                  <p className="px-3">
                    {" "}
                    New lender information will be added to Lenders list. Are
                    you sure you want to proceed?
                  </p>
                  {/* </Modal.Body> */}
                  <Modal.Footer className="border-0">
                    <Button
                      variant="secondary"
                      onClick={() => setSaveShow(false)}
                    >
                      Cancel
                    </Button>
                    <button
                      className="btn btn-primary"
                      onClick={handleSaveClick}
                    >
                      Confirm
                    </button>
                  </Modal.Footer>
                </Modal>

                {/* ----------------- discard------------ */}

                <div
                  className="modal show"
                  style={{ display: "block", position: "initial" }}
                >
                  {/* modal for discard button */}

                  <Modal
                    show={discardShow}
                    onHide={() => setDiscardShow(false)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Discard changes?</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body> */}
                    <p className="mx-3">
                      All the changes will be discarded. Are you sure you want
                      to proceed?
                    </p>
                    {/* </Modal.Body> */}
                    <Modal.Footer className="border-0">
                      <Button
                        variant="secondary"
                        onClick={() => setDiscardShow(false)}
                      >
                        Cancel
                      </Button>

                      <Button onClick={handleComfirmDiscard} variant="primary">
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                {/* ------ discloser--------- */}

                {/* <>
                      <div key={addBankData._id}>
                        <div
                          className="modal fade"
                          id={`DisclosureModal-${addBankData._id}`}
                          tabIndex="-1"
                          aria-labelledby={`DisclosureModalLabel${addBankData._id}`}
                          aria-hidden="true"
                          key={`modal-${addBankData._id}`}
                        >
                          <div className="modal-dialog  modal-lg">
                            <div className="modal-content p-3">
                              <div className="d-flex justify-content-between">
                                <img
                                  src={image}
                                  className="col-3 p-1"
                                  alt="..."
                                />
                                <h1
                                  className="modal-title fs-4 col-8"
                                  id={`DisclosureModalLabel-${addBankData._id}`}
                                >
                                  <p className="mt-4 text-center">
                                    {addBankData.bankName}
                                  </p>
                                </h1>
                             
                              </div>
                             
                              <div className="modal-body">
                                {addBankData.disclosure}
                              </div>
                              <div className="modal-footer justify-content-center w-100">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary col-4"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> */}

                {/* ------- know-more modal -----*/}
                {/* {CardData &&
        CardData.map((item, index) => {
          return (
            <>
              <div
                className="modal fade"
                id={`KnowmoreModal-${item._id}`}
                tabIndex="-1"
                aria-labelledby={`KnowmoreModalLabel-${item._id}`}
                aria-hidden="true"
                // key={item._id}
                key={`modal-${item._id}`}
              >
                <div className="modal-dialog  modal-lg">
                  <div className="modal-content p-3">
                    <div className="d-flex flex-wrap justify-content-between">
                      <img
                        src={item.bankLogo}
                        className=" col-3 p-1"
                        alt="..."
                      />
                      <h1
                        className="modal-title fs-4 col-8"
                        id={`KnowmoreModalLabel-${item._id}`}
                      >
                        <p className="mt-4">{item.bankName}</p>
                      </h1>
                    </div>
                    <div className="modal-body">{item.aboutTheBank}</div>

                    <div className="modal-footer justify-content-center w-100">
                      <button
                        type="button"
                        className="btn btn-outline-secondary col-4"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBank;
