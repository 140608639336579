import React, { useState, useContext, useEffect, useCallback } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import { ModalContext } from "../../contexts/ModalContext";
import { jwtDecode } from "jwt-decode";
import { API_BASE_URL } from "../../appConfig";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { redirect } from "react-router-dom";
import SignInModal from "./SignInModall";
import TermsPath2Study from "../../assests/dosc/Terms_and_Conditions.pdf";
import debounce from 'lodash.debounce';

const CreatePasswordModal = () => {
  const [countryByBackend, setCountryByBackend] = useState("");
  const [stateBackend, setStateByBackend] = useState("");
  const [cityByBackend, setCityByBackend] = useState("");
  const [pincodeByBackend, setPincodeByBackend] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle button disable/enable

  useEffect(() => {
    const getLocation = () => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const LatLong = { latitude, longitude };
          try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/getCountry`, LatLong);
            setCountryByBackend(response.data.country);
            setStateByBackend(response.data.state);
            setCityByBackend(response.data.city);
            setPincodeByBackend(response.data.pincode);
          } catch (error) {
            console.error("Error fetching location:", error.message);
          }
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    };
    getLocation();
  }, []);

  const { handleNext, data, closeModal, allCloseModal, showSigninModal } = useContext(ModalContext);
  const [showPassword, setShowPassword] = useState(false);
  const { Formik } = formik;

  const passwordSchema = yup.object().shape({
    password: yup.string().min(8, "Password must be at least 8 characters")
      .matches(/(?=.*[0-9])/, "Password must contain at least one number")
      .matches(/(?=.*[a-zA-Z])/, "Password must contain at least one alphabet character")
      .matches(/(?=.*[A-Z])/, "Password must contain at least one uppercase letter")
      .matches(/(?=.*[!@#$%^&*])/, "Password must contain at least one special character (!@#$%^&*)")
      .required("Password is required"),
    confirmPassword: yup.string().required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const handlePassword = async (values) => {
    setIsSubmitting(true); // Disable the button after first click
    const { email, name } = data;
    const { password } = values;

    const postData = {
      email,
      name,
      password,
      country: countryByBackend,
      state: stateBackend,
      city: cityByBackend,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/userRegister`, postData);
      if (response.data.message === "Your account has been created") {
        const userType = jwtDecode(response.data.tokenData.token);
        localStorage.setItem("userId", response.data.tokenData.userId);
        localStorage.setItem("userToken", response.data.tokenData.token);
        localStorage.setItem("userType", userType.userType);
        allCloseModal();
        window.location.href = "/user/userprofile";
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        redirect("/");
      }
    } catch (error) {
      setIsSubmitting(false); // Re-enable the button if the API call fails
      console.error("Error:", error);
      toast(error.response?.data?.message || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const debouncedHandlePassword = useCallback(debounce(handlePassword, 1000), [countryByBackend, stateBackend, cityByBackend]);

  const handleLoginButton = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <>
      <Modal.Body>
        <div className="card mx-md-4 shadow p-3  bg-body rounded-lg">
          <img src={require("../../assests/img/lock.png")} className="rounded mx-auto d-block" alt="logocreate" />
          <span className="fs-6 fw-lighter my-2">
            Set a Password for your account. Use min 8 characters, with the combination of at least numbers, alphabet, 1 Uppercase and 1 special character.
          </span>

          <Formik
            validationSchema={passwordSchema}
            onSubmit={debouncedHandlePassword}
            initialValues={{
              password: "",
              confirmPassword: "",
              isAppliedForPromotion: false,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group controlId="validationFormik01">
                    <Form.Label>Create Password</Form.Label>
                    <Form.Control
                      placeholder="xxxxxxxx"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password && touched.password}
                      style={{
                        fontWeight: "400",
                        border: "revert-layer",
                        outline: "none",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="validationFormik02" className="mt-2">
                    <Form.Label>Confirm Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        placeholder="xxxxxxxx"
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer",
                          outline: "none",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "33px",
                          transform: "translateY(-102%)",
                        }}
                      >
                        {showPassword ? (
                          <i className="bi bi-eye" style={{ fontSize: "1.2rem" }}></i>
                        ) : (
                          <i className="bi bi-eye-slash" style={{ fontSize: "1.2rem" }}></i>
                        )}
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </Row>
                <div className="my-2">
                  <span className="fs-6 text">
                    By signing in I agree with the
                    <a
                      href={TermsPath2Study}
                      className="text-primary ms-1 text-decoration-none"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>{" "}
                    of Path2Study
                  </span>
                </div>

                <div className="d-grid gap-2">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting} // Disable button when submitting
                  >
                    Sign Up
                  </Button>
                </div>
              </Form>
            )}
          </Formik>

          <span className="d-flex justify-content-center mt-3">
            Already have an account?
            <span
              className="cursor-pointer text-primary ms-1"
              onClick={(e) => handleLoginButton(e)}
            >
              Log In
            </span>
          </span>
        </div>
      </Modal.Body>
      {showSigninModal && (
        <SignInModal
          onHide={closeModal}
          handleClose={closeModal}
          allCloseModal={allCloseModal}
        />
      )}
    </>
  );
};

export default CreatePasswordModal;



// import React, { useState, useContext, useEffect, useCallback } from "react";
// import { Modal, Form, Button } from "react-bootstrap";
// import axios from "axios";
// import { ModalContext } from "../../contexts/ModalContext";
// import { jwtDecode } from "jwt-decode";
// import { API_BASE_URL } from "../../appConfig";
// // import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
// import * as formik from "formik";
// import * as yup from "yup";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { redirect } from "react-router-dom";
// import SignInModal from "./SignInModall";
// import TermsPath2Study from "../../assests/dosc/Terms_and_Conditions.pdf"; //  src folder
// import debounce from 'lodash.debounce';
// // import TermsPath2Study from "../../../public/TermsPath2Study.pdf" // public folder 
// // import Privacy from "../assests/dosc/Privacy.pdf";
// // import { CountryContext } from "../../contexts/CountryContext";
// // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// const CreatePasswordModal = () => {
//   const [countryByBackend, setCountryByBackend] = useState(""); // actual country location by viswas
//   const [stateBackend, setStateByBackend] = useState(""); // actual country location by ip
//   const [cityByBackend, setCityByBackend] = useState(""); // actual country location by ip
//   const [pincodeByBackend, setPincodeByBackend] = useState(""); // actual country location by ip
//   // const [selectedCountry, setSelectedCountry] = useState(countryByGoogle);

//   useEffect(() => {
//     const getLocation = () => {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const { latitude, longitude } = position.coords;
//           console.log("Latitude:", latitude);
//           console.log("Longitude:", longitude);
//           // https://server-dot-path2study.el.r.appspot.com/getCountry
//           const LatLong = {
//             latitude: latitude,
//             longitude: longitude,
//           };
//           const response = await axios.post(
//             `${process.env.REACT_APP_BASE_URL}/getCountry`,
//             // `${API_BASE_URL}/getCountry`,
//             LatLong
//           );
//           // console.log(
//           //   "getCountry in the useEffct of createPasswordMOdal",
//           //   response.data
//           // );
//           setCountryByBackend(response.data.country);
//           setStateByBackend(response.data.state);
//           setCityByBackend(response.data.city);
//           setPincodeByBackend(response.data.pincode);
//         },
//         (error) => {
//           console.error("Error getting location:", error.message);
//         }
//       );
//     };

//     // Trigger permission request when component mounts
//     getLocation();
//   }, []);

//   // const [selectedCountry, setSelectedCountry] = useState(countryByGoogle); //  for google
//   // const { countryByGoogle, selectedCountry } = useContext(CountryContext); // for google

//   const { handleNext, data, closeModal, allCloseModal, showSigninModal } =
//     useContext(ModalContext);
//   const [showPassword, setShowPassword] = useState(false); // for eye button
//   const { Formik } = formik;
//   const passwordSchema = yup.object().shape({
//     password: yup
//       .string()
//       .min(8, "Password must be at least 8 characters")
//       .matches(/(?=.*[0-9])/, "Password must contain at least one number")
//       .matches(
//         /(?=.*[a-zA-Z])/,
//         "Password must contain at least one alphabet character"
//       )
//       .matches(
//         /(?=.*[A-Z])/,
//         "Password must contain at least one uppercase letter"
//       )
//       .matches(
//         /(?=.*[!@#$%^&*])/,
//         "Password must contain at least one special character (!@#$%^&*)"
//       )
//       .required("Password is required"),
//     confirmPassword: yup
//       .string()
//       .required("Confirm Password is required")
//       .oneOf([yup.ref("password"), null], "Passwords must match"),
//   });
  

//   const handlePassword = async (values) => {
//     const { email, name } = data;
//     const { password } = values;
//     // const { password, isAppliedForPromotion } = values; //  checkbox code
//     // console.log("onsubmitting data of country while creating",countryByBackend)
//     const postData = {
//       email,
//       name,
//       password,
//       // isAppliedForPromotion,
//       country: countryByBackend,
//       state: stateBackend,
//       city: cityByBackend,
//       // pincode:pincodeByBackend
//     }; // userType = "user"
//     console.log("password component", postData);

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/userRegister`,
//         // `${API_BASE_URL}/userRegister`,
//         postData
//       );
//       console.log("Response:", response.data.tokenData.token);
//       // Handle the response or update the state as needed
//       // return
//       if (response.data.message === "Your account has been created") {
//         const userType = jwtDecode(response.data.tokenData.token);
//         console.log(userType);
//         const UserId = localStorage.setItem(
//           "userId",
//           response.data.tokenData.userId
//         );
//         const UserToken = localStorage.setItem(
//           "userToken",
//           response.data.tokenData.token
//         );
//         const UserType = localStorage.setItem("userType", userType.userType);
//         allCloseModal();

//         window.location.href = "/user/userprofile"; //  new as per sir tell

//         toast(response.data.message, {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       } else {
//         // window.location.href = "/";
//         redirect("/");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       // Handle errors, if any
//       if (error) {
//         toast(error.response.data.message, {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//       }
//     }
//   };

//   const debouncedHandlePassword = useCallback(debounce(handlePassword, 1000), [countryByBackend, stateBackend, cityByBackend]);

//   const handleLoginButton = (e) => {
//     e.preventDefault();
//     closeModal();
//     // setShowSigninModal(true);
//     // setIsOpen(false);
//     // console.log(formik.values);
//     // console.log("showSigninModal-- in -- signupmodal", showSigninModal);
//     // showSigninModal && <SignInModal />
//   };

//   return (
//     <>
//       {/* <Modal show={show} onHide={handleClose}>
//         <Modal.Header className="d-grid justify-content-center">
//           <Modal.Title>
//             <p className="fs-1">Create Your Account</p>
//           </Modal.Title>
//         </Modal.Header>

//         <p className="mx-5 justify-content-center fs-6">
//           Path2Study gives you authentic information on loans and scholarship to
//           help you achieve your education goals. Let’s create your profile on
//           Path2Study.
//         </p> */}

//       <Modal.Body>
//         <div className="card mx-md-4 shadow p-3  bg-body rounded-lg">
//           <img
//             src={require("../../assests/img/lock.png")}
//             className="rounded mx-auto d-block"
//             alt="logocreate"
//           />
//           <span className="fs-6 fw-lighter my-2">
//             Set a Password for your account. Use min 8 characters, with the
//             combination of at least numbers, alphabet, 1 Uppercase and 1 special
//             character.
//           </span>
//           {/* old version */}
//           {/* <form>
//             <Form.Group controlId="formBasicPassword">
//               <Form.Label className="m-2">Create Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="xxxxxxxx"
//                 value={formik.values.password}
//                 onChange={formik.handleChange}
//                 name="password"
//               />
//             </Form.Group>
//             <Form.Group controlId="formBasicPassword1">
//               <Form.Label className="m-2">Confirm Password</Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="xxxxxxxx"
//                 value={formik.values.confirmPassword}
//                 onChange={formik.handleChange}
//                 name="confirmPassword"
//               />
//             </Form.Group>
//           </form> */}

//           {/* <Button
//             variant="primary"
//             className="d-grid gap-2 col-6 mx-auto w-100 mt-3"
//             onClick={handlePassword}
//           >
//             Sign Up
//           </Button> */}

//           {/* new version  */}
//           <Formik
//             validationSchema={passwordSchema}
//             onSubmit={debouncedHandlePassword}
//             // onSubmit={handlePassword}
//             initialValues={{
//               password: "",
//               confirmPassword: "",
//               // isAppliedForPromotion:""
//               isAppliedForPromotion: false, // Set initial value to false
//             }}
//           >
//             {({ handleSubmit, handleChange, values, touched, errors }) => (
//               <Form noValidate onSubmit={handleSubmit}>
//                 <Row className="mb-3">
//                   <Form.Group controlId="validationFormik01">
//                     <Form.Label>Create Password</Form.Label>
//                     <Form.Control
//                       placeholder="xxxxxxxx"
//                       // type="password"
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       value={values.password}
//                       onChange={handleChange}
//                       isInvalid={!!errors.password && touched.password}
//                       style={{
//                         fontWeight: "400",
//                         border: "revert-layer", // Remove default border on focus
//                         outline: "none", // Remove default outline on focus
//                         boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
//                       }}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.password}
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Form.Group controlId="validationFormik02" className="mt-2">
//                     <Form.Label>Confirm Password</Form.Label>
//                     <div className="position-relative">
//                       <Form.Control
//                         placeholder="xxxxxxxx"
//                         // type="password"
//                         type={showPassword ? "text" : "password"}
//                         name="confirmPassword"
//                         value={values.confirmPassword}
//                         onChange={handleChange}
//                         isInvalid={
//                           !!errors.confirmPassword && touched.confirmPassword
//                         }
//                         style={{
//                           fontWeight: "400",
//                           border: "revert-layer", // Remove default border on focus
//                           outline: "none", // Remove default outline on focus
//                           boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
//                         }}
//                       />
                      
//                       <div
//                         onClick={() => setShowPassword(!showPassword)}
//                         style={{
//                           cursor: "pointer",
//                           position: "absolute",
//                           // top: "36%",
//                           right: "33px",
//                           transform: "translateY(-102%)",
//                         }}
//                       >
//                         {showPassword ? (
//                           <i
//                             className="bi bi-eye"
//                             style={{ fontSize: "1.2rem" }}
//                           ></i>
//                         ) : (
//                           <i
//                             className="bi bi-eye-slash"
//                             style={{ fontSize: "1.2rem" }}
//                           ></i>
//                         )}
//                       </div>
//                       <Form.Control.Feedback type="invalid">
//                       {errors.confirmPassword}
//                     </Form.Control.Feedback>
//                     </div>
                    
//                   </Form.Group>
//                   {/* <Form.Group controlId="formBasicCheckbox" className="mt-2">
//                     <Form.Check
//                       type="checkbox"
//                       label="Subscribe to receive our marketing and promotional emails."
//                       name="isAppliedForPromotion"
//                       checked={values.isAppliedForPromotion}
//                       onChange={handleChange}
//                       // onChange={(e) => handleChange(e)} // Handle the checkbox change
//                     />
//                   </Form.Group> */}
//                 </Row>
//                 <div className="my-2">
//                 <span className="fs-6 text">
//                       By signing in I agree with the
//                       <a
//                         href={TermsPath2Study}
//                         className="text-primary ms-1 text-decoration-none"
//                         // onClick={onButtonClick}
//                         target="_blank"
//                       >
//                         Terms & Conditions
//                       </a>{" "}
//                       of Path2Study
//                     </span>
//                 </div>

//                 <div className="d-grid gap-2">
//                   <Button
//                     type="submit"
//                     variant="primary"
//                     // className="d-grid gap-2 col-6 mx-auto w-100 mt-3"
//                     // onClick={handlePassword}
//                   >
//                     Sign Up
//                   </Button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//           {/* <Button
//             variant="light"
//             className="d-grid gap-2 col-6 mx-auto w-100 mt-3 border"
//           >
//             Sign Up with Google
//           </Button> */}
//           {/* <p className="d-flex justify-content-center mt-3">
//             Already have an account?{" "}
//             </p>
//             <span className="text-primary ms-1"> Log In </span> */}
//           <span className="d-flex justify-content-center mt-3">
//             Already have an account?
//             <span
//               className="cursor-pointer text-primary ms-1 "
//               // data-bs-toggle="modal" data-bs-target="#secondModal" data-bs-dismiss="modal"
//               onClick={(e) => handleLoginButton(e)}
//             >
//               Log In
//             </span>
//           </span>
//         </div>
//       </Modal.Body>
//       {showSigninModal && (
//         <SignInModal
//           onHide={closeModal}
//           handleClose={closeModal}
//           allCloseModal={allCloseModal}
//         />
//       )}
//       {/* </Modal> */}
//     </>
//   );
// };

// export default CreatePasswordModal;
