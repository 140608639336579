import "../style/HomeOverlap.css";
import React, { useContext, useEffect, useState } from "react";
import CityBar from "./CityBar";
import UnlockPotential from "./UnlockPotential";
import ChanceToWin from "./ChanceToWin";
import AboveFooter from "./AboveFooter";
import UsChoise from "./UsChoise";
import HomeOverlap from "./HomeOverlap";
import { CountryContext } from "../contexts/CountryContext";
import Statastick from "./Statastick";
import HomeWinner from "./HomeWinner";
import Path2studyHighlights from "./Path2studyHighlights";
import P2sIdealChoice from "./P2sIdealChoice";
import HeroSwiper from "./HeroSwiper";
import axios from "axios";
import HomeOverlap_v1 from "./HomeOverlap_v1";

const Home = () => {
  const { selectedCountry, setSelectedCountry } = useContext(CountryContext);
  const [cardData, setCardData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getWinners`)
      // .get(`https://server-dot-path2study.el.r.appspot.com/getWinners`)
      .then((response) => {
        setCardData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
  return (
    <div>
      {/* <MultiStepForm/> */}
      {/* <Hero /> */}
      <HeroSwiper />
      {/* <div className="blank ">
        <HomeOverlap />
      </div> */}
        <HomeOverlap_v1 />
      {/* <div style={{ marginTop: "20rem", marginBottom: "20rem" }}> */}{" "}
      <div className="container">
        {/* <LoansHighlights /> */}
        <Path2studyHighlights />
      </div>
      <div className="container custome-height">
        <Statastick />
      </div>
      <div className="custome-height">
        <ChanceToWin />
      </div>
      {cardData?.length >= 3 && (
        <div className="">
          <HomeWinner />
        </div>
      )}
      
      {/* dependent on const [selectedCountry, setSelectedCountry] = useState("india"); in country context */}
      {selectedCountry !== "usa" || selectedCountry === "United States" ? (
        <div className="custome-height ">
          <UsChoise />
          <CityBar />
        </div>
      ) : (
        <div className="custome-height">
          <P2sIdealChoice />
          {/* <CityBar /> */}
        </div>
      )}
      <div className="custome-height">
        <UnlockPotential />
      </div>
      {/* <div className="mt-5">
        <Faqs />
      </div> */}
      <div className="">
        <AboveFooter />
      </div>
      {/* <whatsappConnect /> */}
    </div>
  );
};

export default Home;
