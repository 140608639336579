import "./Loader.css";

export const Loader = () => {
  return (
    <div className="loader-container">
      {/* <div>Please wait! Your data is being processed</div> */}
      <div className="loader-wrapper text-center">
        <span className="loader"></span>
      </div>
    </div>
  );
};
