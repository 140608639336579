import React, { useState, useEffect } from "react";
import { Modal} from "react-bootstrap";
import { convertISOToHumanReadable } from "../../hooks/convertdate";
import axios from "axios";
// import { API_BASE_URL } from "../../appConfig";
import profileImage from "../../assests/img/new200.png";
import { formatAmount } from "../../hooks/useFormattedAmount";
const TrackScholModal = ({ closeModal, card }) => {

  const [winner, setWinner] = useState([]);
  const [currency, setCurrency] = useState('');
  useEffect(() => {
    const getWinners = async () => {
      try {
        const userToken = localStorage.getItem("userToken");
        const updatedToken = `Bearer ${userToken} `;
        const userId = localStorage.getItem("userId");
        const authAxios = axios.create({
          baseURL: process.env.REACT_APP_BASE_URL,
          headers: {
            authorization: updatedToken,
          },
        });
        const response = await authAxios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/getWinner/${userId}?scholarshipId=${card._id}`
          // `${API_BASE_URL}/admin/getWinner/${userId}?scholarshipId=${card._id}`
        );
        setWinner(response.data.winners[0].user);
        setCurrency(response.data.currency)
      } catch (error) {
        console.error(error);
      }
    };

    getWinners();
  }, []);
  return (
    <div>
      <>
        <Modal show={true} onHide={closeModal} size="md">
          <div>
            {/* <Modal.Header closeButton> */}
            <Modal.Header className="d-grid text-center justify-content-center">
              {/* <Modal.Title>Scholarship name (path2study)</Modal.Title> */}
              <Modal.Title>
                {card?.scholarshipName}
                <span className="ms-3 text-primary">{card.currency ? card.currency : "$"} {formatAmount(card?.amount)}</span>
                {/* <span className="ms-3 text-primary">${card?.amount}</span> */}
              </Modal.Title>
              <button
                type="button"
                className="btn-close close-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </Modal.Header>
            <Modal.Body>
              <table className="table" style={{border:'none',borderCollapse: 'collapse'}}>
                <tbody>
                  <tr>
                    <td className="w-25" style={{border:'none',borderCollapse: 'collapse'}}>Course Name:</td>
                    <td className="w-75" style={{border:'none',borderCollapse: 'collapse'}}>
                    {Array.isArray(card.courseName) && card.courseName.length > 0
                ? card.courseName.join(", ")
                : "All"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>Start Date:</td>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>
                      {card.startDate
                        ? convertISOToHumanReadable(card.startDate)
                        : "Not provided"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>End Date:</td>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>
                      {card.Deadline
                        ? convertISOToHumanReadable(card.Deadline)
                        : "Not provided"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>Total Applicants:</td>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>{card?.appliedUsers.length}</td>
                  </tr>
                  <tr>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>Scholarship Amount:</td>
                    <td style={{border:'none',borderCollapse: 'collapse'}}>{card.currency ? card.currency : "$"} {formatAmount(card?.amount)}</td>
                  </tr>
                  {card?.minimumRequirements && (
                    <tr>
                      <td style={{border:'none',borderCollapse: 'collapse'}}>Minimum Requirements:</td>
                      <td style={{border:'none',borderCollapse: 'collapse'}}>{card?.minimumRequirements}</td>
                    </tr>
                  )}
                </tbody>
              </table>

             
              {card?.winnerChosen && (
                <>
                  <div className="">
                    <h4>Declared winner</h4>
                    <div className="d-flex justify-content-between">
                      {/* Profile image and name on the left */}
                      <div className="d-flex align-items-center">
                        <img
                          className="rounded-circle"
                          src={
                            winner?.profileImg !== null && winner?.profileImg
                              ? winner?.profileImg
                              : profileImage
                          }
                          alt=""
                          style={{ height: "60px", width: "60px" }}
                        />
                        <h5 className="text-center my-auto mx-3">
                          {winner.name}
                        </h5>
                      </div>
                      {/* Amount on the right */}
                      <div>
                        <h3 className="text-center my-auto text-primary mt-3">
                        {currency ? currency : "$"}{formatAmount(card?.amount)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default TrackScholModal;
