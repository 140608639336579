import React, { useContext, useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { getAuth } from "firebase/auth";
import RecommendedScholarship from "./RecommendedScholarship";
// import SearchBar from "./SearchBarScholarship";
import ScholarBanksFooterUSA from "./ScholarBanksFooterUSA";
import axios from "axios";
import { API_BASE_URL } from "../appConfig";
import { UserContext } from "../contexts/UserContext";
import { ModalContext } from "../contexts/ModalContext";
import "../style/Scholarships.css";
import { CountryContext } from "../contexts/CountryContext";
import ScholarBanksFooterIND from "./ScholarBanksFooterIND";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import NotShowData from "./NotShowData";
import PathStudyProfileCompleteModal from "./PathStudyProfileCompleteModal";

export const Banner = () => {
  return (
    <div className=" " style={{ marginTop: "6rem", marginBottom: "3rem" }}>
      <div className="card mb-3 border border-white ">
        <div className="row g-3 mt-2 ">
          <div className="col-md-6 ">
            <img
              src={require("../assests/img/p2sschol.png")}
              className="img-fluid rounded-pill"
              alt="..."
            />
          </div>

          <div className="col-md-6 p-lg-2 order-first order-md-1 hide-br">
            <div className="card-body mt-lg-5 hide-br ">
              <h5 className="card-title fs-1 mt-lg-3 text-align-right order-md-1 ">
                {/* DISCOVER YOUR IDEAL SCHOLARSHIPS TODAY! */}
                {/* Instantly Fund Your Education with Support from Key Lenders */}
                DISCOVER YOUR{" "}
                <span className="text-primary ">
                  IDEAL <br /> SCHOLARSHIPS
                </span>{" "}
                <br />
                TODAY!
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PathStudyScholarship = () => {
  const { data, setData, isUserLoggedIn, userCountry, setUserCountry } =
    useContext(UserContext);
  const { selectedCountry, setSelectedCountry } = useContext(CountryContext);
  const [isProfileComplete, setProfileComplete] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const {
    steps,
    closeModal,
    openModal,
    isOpen,
    setIsOpen,
    openLogin,
    clooseLogin,
    showSigninModal,
    setShowSigninModal,
    allCloseModal,
  } = useContext(ModalContext);

  // For search
  const [searchObj, setSearchObj] = useState({});
  const [scholarships, setScholarships] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [isLoading, setIsLoading] = useState(false); //  for loader
  const [
    isPathstudyProfileCompleteToGetSchol,
    setisPathstudyProfileCompleteToGetSchol,
  ] = useState(false);
  // condition for checking user logged in or not
  // to check the user logged in or not
  useEffect(() => {
    if (!isUserLoggedIn) {
      openModal();
    }
  }, [isUserLoggedIn]);

  // console.log('isProfileComplete',isProfileComplete,'isPathstudyProfileCompleteToGetSchol',isPathstudyProfileCompleteToGetSchol)
  const handleCloseAfterLogin =()=>{
    setisPathstudyProfileCompleteToGetSchol(false)
  }

  // to get the data for scholarships
  useEffect(() => {
    setIsLoading(true); // Set loading state to true before fetching data
    const fetchData = () => {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      authAxios
        .get(`${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}?scholarshipType=Path2Study`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((res) => {
          if (res.data.isProfileComlete === false) {
            setProfileComplete(false);
            setIsLoading(false);
            setisPathstudyProfileCompleteToGetSchol(true)
          } 
          else {
            setProfileComplete(true);
            setisPathstudyProfileCompleteToGetSchol(false)
          }
          if (res.data.path2StudyScholarships?.length == 0) {
            setNoMatchFound(true);
          } else {
            setNoMatchFound(false);
          }
          // setScholarships(res.data.scholarships);
          if (res.data?.path2StudyScholarships && res?.data?.path2StudyScholarships?.length > 0) {
            // if (res.data?.path2StudyScholarships) {
            const filtered = res.data.path2StudyScholarships?.filter(
              (item) =>
                item.scholarshipProvider === "Path2Study" &&
                item.status !== "expired" &&
                item.isDeleted !== true
              // &&
              // item.Country?.toLowerCase() === selectedCountry?.toLowerCase()
              // console.log('item.scholarshipProvider === "Path2Study"', item.scholarshipProvider === "Path2Study")
            );
            setScholarships(filtered);
            if (filtered.length <= 0) {
              setNoMatchFound(true);
            }
            setIsLoading(false); // Set loading state to true before fetching data
            // console.log('set the scholarship new with country ',scholarships)
          }
        })
        .catch((err) => {
          console.error("error get scholarship", err);
        });
    };

    // fetchData();
    if (isUserLoggedIn) {
      fetchData();
    }
  }, [selectedCountry]);
  // useEffect(() => {
  //   setIsLoading(true); // Set loading state to true before fetching data
  //   const fetchData = () => {
  //     const userId = localStorage.getItem("userId");
  //     const userToken = localStorage.getItem("userToken");
  //     const updatedToken = `Bearer ${userToken} `;
  //     const authAxios = axios.create({
  //       baseURL: process.env.REACT_APP_BASE_URL,
  //       headers: {
  //         authorization: updatedToken,
  //       },
  //     });
  //     authAxios
  //       .get(`${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}`, {
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       })
  //       .then((res) => {
  //         // console.log(res)
  //         if (res.data.isProfileComlete === false) {
  //           setProfileComplete(false);
  //           setIsLoading(false);
  //           setisPathstudyProfileCompleteToGetSchol(true)
  //         } else {
  //           setProfileComplete(true);
  //           setisPathstudyProfileCompleteToGetSchol(false)
  //         }
  //         if (res.data.scholarships?.length == 0) {
  //           setNoMatchFound(true);
  //         } else {
  //           setNoMatchFound(false);
  //         }
  //         // setScholarships(res.data.scholarships);
  //         if (res.data?.scholarships && res?.data?.scholarships?.length > 0) {
  //           // if (res.data?.scholarships) {
  //           const filtered = res.data.scholarships?.filter(
  //             (item) =>
  //               item.scholarshipProvider === "Path2Study" &&
  //               item.status !== "expired" &&
  //               item.isDeleted !== true
  //             // &&
  //             // item.Country?.toLowerCase() === selectedCountry?.toLowerCase()
  //             // console.log('item.scholarshipProvider === "Path2Study"', item.scholarshipProvider === "Path2Study")
  //           );
  //           setScholarships(filtered);
  //           if (filtered.length <= 0) {
  //             setNoMatchFound(true);
  //           }
  //           setIsLoading(false); // Set loading state to true before fetching data
  //           // console.log('set the scholarship new with country ',scholarships)
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("error get scholarship", err);
  //       });
  //   };

  //   // fetchData();
  //   if (isUserLoggedIn) {
  //     fetchData();
  //   }
  // }, [selectedCountry]);




  return (
    <div>
      <div className="container ">
        <Banner />
        
        {!isUserLoggedIn && <NotShowData />}
        {isUserLoggedIn && !isProfileComplete && isLoading && (
          <div className="loader-container-schol d-flex">
            {/* Loader */}
            <div className="loader-container-schol d-flex">
              <span className="loader-schol"></span>
              <p className="text-primary">
                Hold tight! We're fetching the best possible options for you.
              </p>
            </div>
          </div>
        )}
        {isUserLoggedIn && !isProfileComplete && !isLoading && (
          <>
            <div
              className="d-flex justify-content-center text-center "
              style={{
                marginTop: "4rem",
                marginBottom: "2rem",
              }}
            >
              <div className="text-primary border p-5 rounded-4 mx-1">
                <p className="fs-3 ">
                  {" "}
                  <span className="text-primary">
                    Kindly complete your profile to checkout scholarships
                    curated for you!
                  </span>
                </p>
                <Link
                  to="/user/userprofile"
                  className="text-decoration-underline fs-5 profile-link-button "
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Complete Profile
                  <FaArrowRightLong
                    style={{ verticalAlign: "middle" }}
                    className="ms-2 mt-1"
                  />
                </Link>
              </div>
            </div>
          </>
        )}
        {isUserLoggedIn && isProfileComplete && noMatchFound && (
          <>
            <div className="text-center border pt-5  rounded-4 ">
              <span className="fs-3 text-dark ">
                Thank you for completing your profile on Path2Study. Currently,
                there are <span className="text-primary"> no matches</span>{" "}
                based on your profile.
              </span>
              <p className="text-dark py-5 px-2">
                Note: Consider updating your profile for better matching
                opportunities. Ignore this message if your profile information
                is already updated.
              </p>
            </div>
          </>
        )}
        {isUserLoggedIn && isProfileComplete && !isLoading && !noMatchFound && (
          <>
            <RecommendedScholarship filteredData={scholarships} />
          </>
        )}

        {selectedCountry === "usa" ? (
          <ScholarBanksFooterUSA />
        ) : (
          <ScholarBanksFooterIND />
        )}
      </div>
      {/* {console.log('injsx',isPathstudyProfileCompleteToGetSchol)} */}
      {isPathstudyProfileCompleteToGetSchol && (
        <PathStudyProfileCompleteModal  show={isPathstudyProfileCompleteToGetSchol} setShow={setisPathstudyProfileCompleteToGetSchol} handleCloseAfterLogin={handleCloseAfterLogin}/>
      )}
    </div>
  );
};

export default PathStudyScholarship;

// %%%%%%%% dont remove the below code needed
//  // Handle change
//  const handleChange = (e) => {
//   const { name, value } = e.target;
//   setSearchObj({
//     ...searchObj,
//     [name]: value,
//   });
// };

// // Handle submit
// const handleSubmit = (e) => {
//   e.preventDefault();
//   // console.log('scholarship', scholarships);
//   console.log("Searched Objects", searchObj);
//   const filtered = scholarships.filter(
//     (data) =>
//       data?.Country?.toLowerCase() === searchObj.country &&
//       data?.courseName?.toLowerCase() === searchObj.course
//     // console.log('data', data.Country, searchObj.country , data.course, searchObj.course)
//   );

//   // setFilteredData(filtered);
//   // console.log("filtered data final", filtered);
// };
// // Unique Countries
// const uniqueCountries = Array.from(
//   new Set(scholarships.map((data) => data.Country.toLowerCase()))
// );

// // Unique Courses
// const uniqueCourses = Array.from(
//   new Set(scholarships.map((data) => data.courseName.toLowerCase()))
// );
{
  /* search bar with functionality */
}
{
  /* <SearchBar
          uniqueCountries={uniqueCountries}
          uniqueCourses={uniqueCourses}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        /> */
}
