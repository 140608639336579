import React, { useRef, useEffect, useState } from "react";
import "./../styles/LoanManagement.css";
import AdminSideBar from "./AdminSideBar";
// import Header from "./Header";
import Table from "./Table";

// import BreadCrumb from "./BreadCrumb";
// import { FaCircleUser } from "react-icons/fa6";
// import { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { PiBankLight } from "react-icons/pi";
import { VscGlobe } from "react-icons/vsc";
import { PiBank } from "react-icons/pi";
const LoanManagment = () => {
  const [loader, setLoader] = useState(false);



  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = (isOpen) => {
    setShow(isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="LoanManagement">
      <div className="">
        <div className="d-flex justify-content ">
          <div className="col-2">
            <AdminSideBar />
          </div>
          {loader ? (
            <div className="col-10 py-3 px-3">
              <div className="text-center mt-3">
                <div className="loader-container">
                  <div className="loader"></div>

                  <div>Please wait while we fetch the latest data for you</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-10  pageBackground">
              {/* <Header/>   */}



              {loader !== true ? null : ""}

              <Table setLoader={setLoader} loader={loader} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanManagment;
