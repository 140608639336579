import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "../../style/userprofile.css";
import axios from "axios";
// import { API_BASE_URL } from "../../appConfig";
import UserProfileImage from "./UserProfileImage";
import { UserContext } from "../../contexts/UserContext";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { getAuth } from "firebase/auth";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Button, Modal } from "react-bootstrap";
// dropdown for country state city
import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData,
} from "react-country-region-selector";

const NewProfile = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const clearLocalStorage = () => {
    localStorage.clear();
  };
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //  phone number
  const [errorsPhone, setErrorsPhone] = useState(false);
  // dropdown country and all
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isLoading, setIsLoading] = useState(false); //  for loader
  // condition for checking user logged in or not
  const [phoneNumber, setPhoneNumber] = useState(""); // new
  const [countryCode, setCountryCode] = useState(""); // new

  const [errors, setErrors] = useState(null);
  const [errorsCountrystate, setErrorsCountrystate] = useState(false);
  const [updatedErrors] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const [savebutton, setSaveButton] = useState(false);
  const [formDataChanged, setFormDataChanged] = useState(false); // for new condition to change the formdata changed or not for toast msg

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "" || phoneNumber,
    yourIdentity: "",
    gender: "",
    ethnicity: "",
    culturalIdentity: "",
    currentEducationalLevel: "",
    highschoolUniversity: "",
    citizenshipStatus: "",
    tentativeHighschoolGraduationDate: "",
    MajorFieldOfstudy: "",
    modeOfStudy: "",
    currentGpa: "",
    sourcesOfFinancial: "",
    existingFinancialAid: "",
    annualFamilyIncome: "",
    involvement: "",
    recognitions: "",
    leadership: "",
    academicRecognitions: "",
    academicPrograms: "",
    academicAchievement: "",
    country: selectedCountry || "",
    state: selectedState || "",
    pincode: "",
    city: "",
    address: "",
  });

  const handleCountryChange = (country) => {
    setSelectedState(undefined); // Reset state before updating the country
    // validateFormCountry();
    setFormDataChanged(true);
    setSelectedCountry(country);
    setFormData({
      ...formData,
      country: country,
      state: "",
    });
  };

  const handleStateChange = (state) => {
    // validateFormCountry();
    setFormDataChanged(true);
    setSelectedState(state);
    setFormData({
      ...formData,
      state: state,
    });
  };

  const [CountryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const validateFormCountry = () => {
    const errors = {};
    if (
      !selectedCountry ||
      selectedCountry === "" ||
      !formData.country ||
      formData.country === "" ||
      formData.country == "Select Country"
    ) {
      errors.country = "Please select a country.";
      setCountryError(true);
      setErrorsCountrystate(errors.country);
    } else {
      errors.country = ""; // Set to empty string if country is selected
      setCountryError(false);
    }

    if (
      !selectedState ||
      selectedState === "" ||
      !formData.state ||
      formData.state === "" ||
      formData.country == "-"
    ) {
      errors.state = "Please select a state.";
      setStateError(true);
      setErrorsCountrystate(errors.state);
    } else {
      errors.state = ""; // Set to empty string if state is selected
      setStateError(false);
    }
    setErrorsCountrystate(errors);

    // Return false if any error exists
    return Object.values(errors).every((error) => error === "");
  };
  const [notIn, setNotIn] = useState(false);
  const [notInChecked, setNotInChecked] = useState(false);

  useEffect(() => {
    if (
      formData?.ethnicity !== "American Indian or Alaska Native" &&
      formData?.ethnicity !== "Asian" &&
      formData?.ethnicity !== "Black or African American" &&
      formData?.ethnicity !== "Native Hawaiian or Pacific Islander" &&
      formData?.ethnicity !== "White/Caucasian"
    ) {
      // Condition for other strings
      setNotIn(true);
      setNotInChecked(true);
    } else {
      setNotIn(false);
      setNotInChecked(false);
    }
  }, [formData?.ethnicity]);

  const handleChangeEthnicity = (e) => {
    const { name, value } = e.target;
    setFormDataChanged(true);
    setNotIn(false);
    setFormData({
      ...formData,
      ethnicity: value,
    });
  };

  const handleChangeEthnicityRadio = (e) => {
    setFormDataChanged(true);
    setNotIn(true);
    setNotInChecked(true);
    setFormData((prevData) => {
      if (notInChecked) {
        return { ...prevData, ethnicity: "" };
      } else {
        return { ...prevData, ethnicity: "" };
      }
    });
  };

  const handleChangeEthnicityOther = (e) => {
    setFormDataChanged(true);
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      // return { ...prevData, ethnicity: value }
      // Handle ethnicity radio button changes
      if (notInChecked) {
        // If "Other" is selected, return an empty 'otherEthnicity'
        return { ...prevData, ethnicity: value };
      } else {
        // If a standard ethnicity is selected, clear 'otherEthnicity'
        return { ...prevData, ethnicity: value };
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // const errors = { ...validated, [name]: "" };
    const updatedErrors = { ...errors, [name]: "" }; // Use updatedErrors instead of validated
    // Check if there are any errors
    const hasErrors = Object.values(updatedErrors).some(
      (error) => error !== ""
    );
    // Update btnDisable state based on validation
    setBtnDisable(hasErrors);
    setSaveButton(true);

    // Validation logic for the 'name' field
    if (name === "name") {
      if (value.length < 3) {
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "Name is too short.";
        // setErrors(updatedErrors);
      } else if (value.length > 25) {
        updatedErrors[name] = "Name is too long.";
        // setErrors(updatedErrors);
      }
      // You can add more validation rules as needed
    }
    if (name === "address") {
      if (value.length < 3) {
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "address is too short.";
        // setErrors(updatedErrors);
      } else if (value.length > 60) {
        updatedErrors[name] = "address is too long.";
        // setErrors(updatedErrors);
      }
      // You can add more validation rules as needed
    }

    // if (name === "yourIdentity") {
    //   // console.log("validation", value);
    //   if (value === "Select") {
    //     // console.log("validation", value);
    //     // errors[name] = "Name is too short.";
    //     updatedErrors[name] = "please select the option";
    //     // setErrors(updatedErrors);
    //   }
    // }
    if (name === "currentEducationalLevel") {
      // console.log("validation", value);
      if (value === "Select") {
        // console.log("validation", value);
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "please select the option";
        // setErrors(updatedErrors);
      }
    }
    if (name === "annualFamilyIncome") {
      // console.log("validation", value);
      if (value === "Select") {
        // console.log("validation", value);
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "please select the option";
        // setErrors(updatedErrors);
      }
    }
    if (name === "currentGpa") {
      // console.log("validation currentGpa", value);
      let legalValue = /^(10|\d(\.\d{1,2})?)$/;
      if (value === "" || parseFloat(value) === 0) {
        updatedErrors[name] = "Please enter your GPA.\n";
      } else if (!legalValue.test(value)) {
        updatedErrors[name] = "Please enter a valid GPA (0.00 to 10.00).\n";
      }
    }
    if (name === "MajorFieldOfstudy") {
      // console.log("validation", value);
      if (value.length < 2) {
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "Enter valid major field of study.";
        // setErrors(updatedErrors);
      }
      //   else if (value.length > 25) {
      //     updatedErrors[name] = "Enter valid major field of study";
      //     // setErrors(updatedErrors);
      //   }
    }
    if (name === "highschoolUniversity") {
      // console.log("validation", value);
      if (value.length < 2) {
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "Enter valid high school or university name";
        // setErrors(updatedErrors);
      }
      //   else if (value.length > 25) {
      //     updatedErrors[name] = "Enter valid major field of study";
      //     // setErrors(updatedErrors);
      //   }
    }
    if (name === "city") {
      // console.log("validation currentGpa", value);
      if (value.length < 2) {
        // errors[name] = "Name is too short.";
        updatedErrors[name] = "city is too short.";
        // setErrors(updatedErrors);
      } else if (value.length > 25) {
        updatedErrors[name] = "city is too long.";
        // setErrors(updatedErrors);
      }
    }

    setErrors(updatedErrors);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [validated, setValidated] = useState(false);

  //  get user data api
  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken} `;
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      try {
        const userId = localStorage.getItem("userId");
        const response = await authAxios.get(`/getUser/${userId}`);
        setFormData(response.data);
   
        setIsLoading(false);
      } catch (error) {
        if (
          error?.response?.data ===
          "The user belonging to this token does no longer exist."
        ) {
          localStorage.clear();
          window.location.href = "/";
          const auth = getAuth();
          signOut(auth)
            .then(() => {
              // Sign-out successful.
            })
            .catch((error) => {
              console.error("error");
            });
          if (isUserLoggedIn) {
            signOut(auth);
            clearLocalStorage();
            window.location.href = "/";
          }
        }
      }
    };
    fetchUserData();
    checkAllfields();
  }, []);

  const checkAllfields = () => {
    // Array of API request promises
    const userToken = localStorage.getItem("userToken");
    const updatedToken = `Bearer ${userToken} `;
    const userId = localStorage.getItem("userId");
    const authAxios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      // baseURL: API_BASE_URL,
      headers: {
        authorization: updatedToken,
      },
    });
    const apiRequests = [
      authAxios.get(`${process.env.REACT_APP_BASE_URL}/getAllLoans/${userId}`, {
        headers: {
          Authorization: `bearer ${userToken}`,
        },
      }),
      authAxios.get(
        `${process.env.REACT_APP_BASE_URL}/getAllScholarships/${userId}`,
        {
          headers: {
            Authorization: `bearer ${userToken}`,
          },
        }
      ),
    ];
    // Make parallel requests using Promise.all()
    Promise.all(apiRequests)
      .then((responses) => {
        if (
          responses[0].data?.isProfileComlete ||
          responses[1].data?.isProfileComlete === false
        ) {
          setBtnDisable(true);
          return "Not filled";
        } else {
          setBtnDisable(false);
          return "all filled";
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const validateFormPhoneNumber = () => {
    const errors = {};
    if (
      !phoneNumber ||
      phoneNumber === "" ||
      !formData.contactNumber ||
      formData.contactNumber === ""
    ) {
      errors.contactNumber = "Please enter a phone number."; // Changed the error message
      setErrorsPhone(errors.contactNumber);
    } else {
      errors.contactNumber = ""; // Set to empty string if phone number is provided
    }
    return Object.keys(errors).every((key) => errors[key] === ""); // Returns true if no errors
  };

  const handlePhoneChange = (value, country) => {
    // console.log(value);
    setPhoneNumber(value?.toString()); // Ensure phoneNumber is always a string
    setCountryCode(country);
    localStorage.setItem("phoneNumber", value?.toString());
    localStorage.setItem("countryCode", country);
    // console.log("phoneNumber", phoneNumber);
    // const formDataNew = { ...formData, contactNumber: phoneNumber };
    validateFormPhoneNumber(); // Moved the validation function call here
    setSaveButton(true);
    setFormDataChanged(true);
    setFormData({
      ...formData,
      contactNumber: value?.toString(),
    });
  };

  useEffect(() => {
    const savedPhoneNumber = localStorage.getItem("phoneNumber");
    const savedCountryCode = localStorage.getItem("countryCode");
    if (savedPhoneNumber && savedCountryCode) {
      setPhoneNumber(savedPhoneNumber);
      setCountryCode(savedCountryCode);
    }
  }, [countryCode]);

  const [FieldModal, setFieldModal] = useState(false);
  const [fieldsToUpdateValue, setfieldsToUpdateValue] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseModal = () => {
    // Your existing code
    setShow(false);
    window.scrollTo(0, 0);
  };
  // handle submit api
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setValidated(true);
    }
    try {
      const userId = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      const updatedToken = `Bearer ${userToken}`;
      const phoneNumber = localStorage.getItem("phoneNumber");
      // console.log("phoneNumber in the post ", phoneNumber);
      const authAxios = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        // baseURL: API_BASE_URL,
        headers: {
          authorization: updatedToken,
        },
      });
      // console.log("selectedCountry", selectedCountry);
      // console.log("selectedState", selectedState);

      // get all required field AND all not filled input
      const response = await authAxios.put(
        `${process.env.REACT_APP_BASE_URL}/updateUser/${userId}`,
        // `${API_BASE_URL}/updateUser/${userId}`,
        { ...formData, text: "1" }
      );
      // console.log("data to send", formDataChanged, formData);
      // console.log("data to response", response);
      if (
        response.data.message ===
        "Please provide all mandatary fields to continue"
      ) {
        setShow(true);
        const field = response.data?.fieldsToUpdate;
        // console.log("feild", field);
        // setfieldsToUpdateValue(response.data?.fieldsToUpdate);

        const fieldsToUpdateValue = response.data?.fieldsToUpdate;
        const fieldReplacements = {
          // name: "Name",
          // email: "Email",
          currentEducationalLevel: "Current Educational Level",
          ethnicity: "Ethnicity",
          gender: "Gender",
          highschoolUniversity: "High School University",
          citizenshipStatus: "Citizenship Status",
          MajorFieldOfstudy: "Major Field of Study",
          currentGpa: "Current GPA",
          country: "Country",
          state: "State",
        };

        const updatedFields = fieldsToUpdateValue.map(
          (field) => fieldReplacements[field]
        );
        setfieldsToUpdateValue(updatedFields);
        // console.log(updatedFields);
      }
      if (savebutton || formDataChanged) {
        if (response.data.message === "Your profile details has been updated") {
          toast(response.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setSaveButton(false); // after first click onchange trigger then disable it to false
        setTimeout(() => {
          // window.location.reload(); //  remove to reload form
          window.scrollTo(0, 0);
        }, 2200); // Adjust the delay time here (2000 + 500 for buffer)
      }
      // validateFormCountry();
      // window.location.reload();

      // setTimeout(() => {
      //   // window.location.reload(); //  remove to reload form
      //   window.scrollTo(0, 0);
      // }, 500); // Adjust the delay time here (2000 + 500 for buffer)
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const styleform = {
    fontWeight: "400",
    border: "revert-layer", // Remove default border on focus
    outline: "none", // Remove default outline on focus
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
    marginBottom: ".5rem",
  };
  const stylefordrop = {
    fontWeight: "400",
    border: "1px solid #ced4da", // Remove default border on focus
    outline: "none", // Remove default outline on focus
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
    marginBottom: "1rem",
  };

  const modeStudyOptions = ["Select", "Full Time", "Part Time"];

  const yourIdentity = [
    "Select",
    "Parent",
    "Student",
    "Legal Guardian",
    "Other",
  ];

  // Define state variables for the selected option and the custom input value
  const [notInyourIdentity, setNotInyourIdentity] = useState(false);
  const [notInCheckedyourIdentity, setNotInCheckedyourIdentity] =
    useState(false);

  useEffect(() => {
    if (
      formData?.yourIdentity !== "Parent" &&
      formData?.yourIdentity !== "Student" &&
      formData?.yourIdentity !== "Legal Guardian"
      // &&
      // formData?.yourIdentity !== "other" // new added need focus
    ) {
      // Condition for other strings
      setNotInyourIdentity(true);
      setNotInCheckedyourIdentity(true);
    } else {
      setNotInyourIdentity(false);
      setNotInCheckedyourIdentity(false);
    }
  }, [formData?.yourIdentity]);

  const handleChangeyourIdentity = (e) => {
    const { name, value } = e.target;
    setFormDataChanged(true);
    setNotInyourIdentity(false);
    setFormData({
      ...formData,
      yourIdentity: value,
    });
  };

  const handleChangeyourIdentityRadio = (e) => {
    setFormDataChanged(true);
    setNotInyourIdentity(true);
    // need changes
    setNotInCheckedyourIdentity(true);
    setFormData((prevData) => {
      if (notInCheckedyourIdentity) {
        return { ...prevData, yourIdentity: "" };
      } else {
        return { ...prevData, yourIdentity: "" };
      }
    });
  };

  const handleChangeyourIdentityOther = (e) => {
    setFormDataChanged(true);
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      // return { ...prevData, ethnicity: value }
      // Handle ethnicity radio button changes
      if (notInCheckedyourIdentity) {
        // If "Other" is selected, return an empty 'otherEthnicity'
        return { ...prevData, yourIdentity: value };
      } else {
        // If a standard ethnicity is selected, clear 'otherEthnicity'
        return { ...prevData, yourIdentity: value };
      }
    });
  };

  // mandetory field
  const dropdownOptions = [
    // "Select",
    "High School",
    "Under Graduate",
    "Post Graduate",
    "MBA",
    "PhD",
    "Other",
  ];
  const [showInput, setShowInput] = useState(false); // State to track whether to show the input field
  const [otherValue, setOtherValue] = useState(""); // State to store value of the "Other" input
  const [IntheDropdown, setIntheDropdown] = useState(false); // State to store value of the "Other" input
  const [currentEducationalLevel, setcurrentEducationalLevel] = useState(""); // State to store value of the "Other" input

  useEffect(() => {
    if (
      formData?.currentEducationalLevel !== "High School" &&
      formData?.currentEducationalLevel !== "Under Graduate" &&
      formData?.currentEducationalLevel !== "Post Graduate" &&
      formData?.currentEducationalLevel !== "MBA" &&
      formData?.currentEducationalLevel !== "PhD"
    ) {
      // Condition for other strings
      setShowInput(true);
      setIntheDropdown(false);
    } else {
      setShowInput(false);
    }
  }, [formData?.currentEducationalLevel]);

  const handleChangeCurrentEducationalLevel = (event) => {
    const selectedOption = event.target.value;
    setFormDataChanged(true);
    if (selectedOption === "Other") {
      setShowInput(true); // Show input field if "Other" is selected
      setFormData({
        ...formData,
        currentEducationalLevel: "",
      });
    } else {
      setShowInput(false); // Hide input field for other options
      setFormData({
        ...formData,
        currentEducationalLevel: event.target.value,
      });
    }
  };

  const handleOtherInputChange = (event) => {
    setFormDataChanged(true);
    setOtherValue(event.target.value); // Update the value of "Other" input
    setFormData({
      ...formData,
      currentEducationalLevel: event.target.value,
    });
    if (
      event.target.value == undefined ||
      event.target.value == null ||
      event.target.value == ""
    ) {
      // show error
      setcurrentEducationalLevel("please enter the value");
    } else {
      setcurrentEducationalLevel("");
    }
    if (event.target.value) {
      setcurrentEducationalLevel("");
    }
  };
  const existingFinancialAid = ["Select", "Yes", "No"];

  // mandetory field
  const AnnualFamilyIncome = [
    "Select",
    "Below $20,000",
    "$20,000 - $40,000",
    "$40,000 - $60,000",
    "$60,000 - $80,000",
    "Above $80,000",
  ];

  const [modalFields, setModalFields] = useState();

  const [nameerror, setNameError] = useState(false);
  const [gendererror, setGenderError] = useState(false);
  const [ethnicityerror, setEthnicityError] = useState(false);
  const [citizenshipStatuserror, setcitizenshipStatusError] = useState(false);
  const [currentEducationalLevelerror, setcurrentEducationalLevelError] =
    useState(false);
  const [MajorFieldOfstudyerror, setMajorFieldOfstudyError] = useState(false);
  const [highschoolUniversityerror, sethighschoolUniversityError] =
    useState(false);
  const [currentGpaerror, setcurrentGpaError] = useState(false);
  const [culturalIdentityerror, setculturalIdentityError] = useState(false);
  const handleSubmitButton = () => {
    if (formData?.name == undefined || formData.name === "" || !formData.name) {
      setNameError(true);
      setModalFields("name is required");
    }
    if (
      formData?.gender == undefined ||
      formData.gender === "" ||
      !formData.gender
    ) {
      setGenderError(true);
      setModalFields("gender is required");
    }
    if (
      formData?.ethnicity == undefined ||
      formData.ethnicity === "" ||
      !formData.ethnicity
    ) {
      setEthnicityError(true);
      setModalFields("ethnicity is required");
    }
    if (
      formData?.citizenshipStatus == undefined ||
      formData.citizenshipStatus === "" ||
      !formData.citizenshipStatus
    ) {
      setcitizenshipStatusError(true);
      setModalFields("citizenshipStatus is required");
    }
    if (
      formData?.culturalIdentity == undefined ||
      formData.culturalIdentity === "" ||
      !formData.culturalIdentity
    ) {
      setculturalIdentityError(true);
      setModalFields("culturalIdentity is required");
    }
    if (
      formData?.MajorFieldOfstudy == undefined ||
      formData.MajorFieldOfstudy === "" ||
      !formData.MajorFieldOfstudy
    ) {
      setMajorFieldOfstudyError(true);
      setModalFields("MajorFieldOfstudy is required");
    }
    if (
      formData?.highschoolUniversity == undefined ||
      formData.highschoolUniversity === "" ||
      !formData.highschoolUniversity
    ) {
      sethighschoolUniversityError(true);
      setModalFields("highschoolUniversity is required");
    }
    if (
      formData?.currentGpa == undefined ||
      formData.currentGpa === "" ||
      !formData.currentGpa
    ) {
      setcurrentGpaError(true);
      setModalFields("currentGpa is required");
    }
    if (
      formData?.currentEducationalLevel == undefined ||
      formData.currentEducationalLevel === "" ||
      !formData.currentEducationalLevel ||
      formData?.currentEducationalLevel === "Select"
    ) {
      setcurrentEducationalLevelError(true);
      setModalFields("currentEducationalLevel is required");
    }

    if (
      !nameerror &&
      !gendererror &&
      !ethnicityerror &&
      !citizenshipStatuserror &&
      !currentEducationalLevelerror &&
      !MajorFieldOfstudyerror &&
      !highschoolUniversityerror &&
      !currentGpaerror &&
      !culturalIdentityerror
    ) {
      const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.stopPropagation();
        } else {
          setValidated(true);
        }
        try {
          const userId = localStorage.getItem("userId");
          const userToken = localStorage.getItem("userToken");
          const updatedToken = `Bearer ${userToken}`;
          const phoneNumber = localStorage.getItem("phoneNumber");
          const authAxios = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            // baseURL: API_BASE_URL,
            headers: {
              authorization: updatedToken,
            },
          });
          // console.log("selectedCountry", selectedCountry);
          // console.log("selectedState", selectedState);

          // get all required field AND all not filled input

          const response = await authAxios.put(
            `${process.env.REACT_APP_BASE_URL}/updateUser/${userId}`,
            formData
          );
          // console.log("data to send", formDataChanged, formData);
          if (savebutton || formDataChanged) {
            if (
              response.data.message === "Your profile details has been updated"
            ) {
              toast(response.data.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            setSaveButton(false); // after first click onchange trigger then disable it to false
            setTimeout(() => {
              window.location.reload(); //  remove to reload form
              window.scrollTo(0, 0);
            }, 2500); // Adjust the delay time here (2000 + 500 for buffer)
          }
          // validateFormCountry();
          // window.location.reload();

          setTimeout(() => {
            window.location.reload(); //  remove to reload form
            window.scrollTo(0, 0);
          }, 500); // Adjust the delay time here (2000 + 500 for buffer)
        } catch (error) {
          console.error("Error:", error);
        }
      };
    }
  };

  return (
    <div className="container bg-body ">
      {isLoading ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        <>
          <div className="row ">
            <div className="col-md-12">
              <div className=" p-3">
                <UserProfileImage />
              </div>
            </div>
          </div>
          {/* validated={validated} */}
          {width >= 481 ? (
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="needs-validation"
              validated={updatedErrors}
            >
              <div className="row ">
                {/* <!---------------- Left Side - 6 Divs ---------------------> */}
                <div className="col-md-6">
                  <div className="mb-3 p-3">
                    <h4>Profile Information</h4>
                    {/* name */}
                    <Form.Group controlId="formBasicName">
                      <Form.Label className="my-2 fw-bold ">
                        Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="User Name"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors?.name}
                        //   isInvalid={!formData.name}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Mobile number new */}
                    <div className="">
                      <Form.Group controlId="formBasiccontactNumber">
                        <div className="">
                          <Form.Label className="my-2 fw-bold  ">
                            Contact Number
                            <span className="text-danger fw-light ms-2">
                              (Note:-Select country first)
                            </span>
                          </Form.Label>
                        </div>
                        <PhoneInput
                          country="US"
                          // value={phoneNumber} //
                          value={
                            phoneNumber || formData?.contactNumber?.toString()
                          }
                          // onChange={handleChangePhone}
                          onChange={handlePhoneChange}
                          limitMaxLength={true}
                          // international={true}
                          defaultCountry="US"
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                            paddingTop: ".2rem",
                            paddingBottom: ".2rem",
                          }}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          placeholder="+1 xxxxx"
                        />

                        <div className="text-danger fw-light">
                          {errors?.contactNumber}
                        </div>
                      </Form.Group>
                    </div>
                    {/* Gender */}
                    <Form.Group controlId="formBasicGender">
                      <Form.Label className="my-2 fw-bold ">
                        Gender<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <div key="gender" className="mb-3">
                        <Form.Check
                          type="radio"
                          inline
                          label="Male"
                          id="male"
                          name="gender"
                          value="Male"
                          checked={
                            formData?.gender === "male" ||
                            formData?.gender === "Male"
                          }
                          onChange={handleChange}
                          //isInvalid={!!errors.gender}
                        />
                        <Form.Check
                          type="radio"
                          inline
                          label="Female"
                          id="female"
                          name="gender"
                          value="Female"
                          checked={
                            formData?.gender === "female" ||
                            formData?.gender === "Female"
                          }
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          inline
                          label="Other"
                          id="other"
                          name="gender"
                          value="Other"
                          checked={
                            formData?.gender === "other" ||
                            formData?.gender === "Other"
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select your gender.
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Ethenticity   */}
                    <Form.Group controlId="formBasicethnicity">
                      <Form.Label className="my-2   fw-bold ">
                        Ethnicity
                      </Form.Label>
                      <span style={{ color: "red" }}> *</span>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity1"
                          value="American Indian or Alaska Native"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={
                            formData?.ethnicity ===
                            "American Indian or Alaska Native"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity1"
                        >
                          American Indian or Alaska Native
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity2"
                          value="Asian"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={formData?.ethnicity === "Asian"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity2"
                        >
                          Asian
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity3"
                          value="Black or African American"
                          name="ethnicity"
                          checked={
                            formData?.ethnicity === "Black or African American"
                          }
                          onChange={handleChangeEthnicity}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity3"
                        >
                          Black or African American
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity4"
                          value="Native Hawaiian or Pacific Islander"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={
                            formData?.ethnicity ===
                            "Native Hawaiian or Pacific Islander"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity4"
                        >
                          Native Hawaiian or Pacific Islander
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity5"
                          value="White/Caucasian"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={formData?.ethnicity === "White/Caucasian"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity5"
                        >
                          White/Caucasian
                        </label>
                      </div>
                      {/* ... other ethnicity radio buttons ... */}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicityOther"
                          // value="Other"
                          name="ethnicity"
                          // onChange={() => {
                          //   setNotIn(true);
                          //   setNotInChecked(true);
                          // }}
                          onChange={handleChangeEthnicityRadio}
                          // checked={notInChecked}
                          // checked={formData?.ethnicity === "Other"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicityOther"
                        >
                          Other
                        </label>
                      </div>
                      {notIn && (
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Enter your ethnicity"
                            name="ethnicity"
                            value={formData?.ethnicity || ""} // Set default value to empty string
                            onChange={handleChangeEthnicityOther}
                            style={styleform}
                          />
                        </div>
                      )}
                    </Form.Group>

                    {/* state */}
                    <Form.Group controlId="formBasicstate">
                      <Form.Label className="my-2 fw-bold ">
                        State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <RegionDropdown
                        country={selectedCountry || formData?.country} //  right ahe
                        value={selectedState || formData?.state}
                        onChange={handleStateChange}
                        // className="form-select"
                        classes={
                          (selectedCountry || formData?.country) &&
                          errorsCountrystate?.state
                            ? "form-select is-invalid"
                            : "form-select"
                        }
                        placeholder="Select state"
                        style={styleform}
                      />
                      {stateError && (
                        <Form.Control.Feedback type="invalid">
                          {errorsCountrystate.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    {/* Adreess */}
                    <Form.Group controlId="formBasicAddress">
                      <Form.Label className="my-2 fw-bold ">Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={formData?.address}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors?.address}
                        //   isInvalid={!formData.Address}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.address}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/*dropdown  Current Educational Level */}
                    <div className="mb-2">
                      <label
                        htmlFor="currentEducationalLevel"
                        className="form-label my-2 fw-bold "
                      >
                        Current Educational Level
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <select
                        className="form-select"
                        id="currentEducationalLevel"
                        name="currentEducationalLevel"
                        onChange={handleChangeCurrentEducationalLevel}
                        // value={formData?.currentEducationalLevel}
                        value={
                          showInput
                            ? "Other"
                            : formData?.currentEducationalLevel
                        }
                        isInvalid={!!errors?.currentEducationalLevel}
                        style={{
                          fontWeight: "400",
                          border: "1px solid #ced4da",
                          outline: "none",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          marginBottom: ".5rem",
                        }}
                      >
                        {!IntheDropdown ? (
                          <>
                            {dropdownOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </select>
                      {showInput && (
                        <input
                          type="text"
                          value={
                            otherValue || formData?.currentEducationalLevel
                          }
                          onChange={handleOtherInputChange}
                          placeholder="Enter other educational level"
                          className="form-control text-capitalize"
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                          }}
                        />
                      )}
                      <div type="invalid" className="text-danger">
                        {errors?.currentEducationalLevel ||
                          currentEducationalLevel}
                      </div>
                    </div>

                    {/* Degree   */}
                    <Form.Group>
                      <Form.Label className="my-2 fw-bold  ">
                        Major/Field of study
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Major/field of study"
                        name="MajorFieldOfstudy"
                        value={formData?.MajorFieldOfstudy}
                        required
                        onChange={handleChange}
                        isInvalid={!!errors?.MajorFieldOfstudy}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.MajorFieldOfstudy}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Area of Study  
                   {/* Current GPA */}
                    <Form.Group>
                      <Form.Label className="my-2 fw-bold">
                        Current GPA<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Enter your CGPA/GPA"
                        name="currentGpa"
                        isInvalid={!!errors?.currentGpa}
                        value={formData?.currentGpa}
                        onChange={handleChange}
                        style={styleform}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.currentGpa}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Academic Achievements */}
                    <h4 className="mt-4">Academic Achievements</h4>
                    <div className="row col-12 d-flex">
                      {/* Left  */}
                      <div className="Left">
                        <Form.Group controlId="formBasicinvolvement">
                          <Form.Label className="my-2 fw-bold  ">
                            Involvement in clubs, sports, or community service{" "}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Write your answer here!"
                            name="involvement"
                            value={formData?.involvement}
                            onChange={handleChange}
                            style={styleform}
                            className="text-capitalize"
                          />
                        </Form.Group>
                        {/*   Participation in Research or Academic Programs */}
                        <Form.Group controlId="formBasicacademicPrograms">
                          <Form.Label className="my-2 fw-bold  ">
                            Participation in Research or Academic Programs
                          </Form.Label>
                          <div className="d-flex align-items-center flex-wrap">
                            <div className="form-check ">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineradio1"
                                value="yes"
                                name="academicPrograms"
                                checked={
                                  formData?.academicPrograms === "yes" ||
                                  formData?.academicPrograms === "Yes"
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineradio1"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check ms-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="inlineradio2"
                                value="no"
                                name="academicPrograms"
                                checked={
                                  formData?.academicPrograms === "no" ||
                                  formData?.academicPrograms === "No"
                                }
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineradio2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </Form.Group>

                        {/*  Notable Academic Achievements */}
                        <Form.Group controlId="formBasicacademicAchievement">
                          <Form.Label className="my-2 fw-bold  ">
                            Notable Academic Achievements
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Write your answer here!"
                            name="academicAchievement"
                            value={formData?.academicAchievement}
                            onChange={handleChange}
                            style={styleform}
                            className="text-capitalize"
                          />
                        </Form.Group>
                      </div>
                      {/* Right  */}
                    </div>
                  </div>
                </div>

                {/* <!---------------- Right Side - 6 Divs ---------------------> */}
                <div className="col-md-6 ">
                  <div className="mb-3 p-3" style={{ marginTop: "2.3rem" }}>
                    {/* Div 4  */}
                    {/* email */}
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="my-2 fw-bold ">
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        readOnly // normal bootstrap
                        className="form-control-plaintext"
                        disabled
                        type="email"
                        placeholder="username@domain.com"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                        //   isInvalid={!!formData.email}
                        isInvalid={!formData.email}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer", // Remove default border on focus
                          outline: "none", // Remove default outline on focus
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                          marginBottom: ".5rem",
                          paddingLeft: ".5rem",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter email correct
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/*  Describe Yourself*/}
                    {/* <div className="mb-2">
                    <label
                      htmlFor="currentParent"
                      className="form-label my-2 fw-bold "
                    >
                      Describe Yourself
                    </label>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="yourIdentity1"
                        value="Parent"
                        name="yourIdentity"
                        onChange={handleChangeyourIdentity}
                        checked={formData?.yourIdentity === "Parent"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yourIdentity1"
                      >
                        Parent
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="yourIdentity2"
                        value="Student"
                        name="yourIdentity"
                        onChange={handleChangeyourIdentity}
                        checked={formData?.yourIdentity === "Student"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yourIdentity2"
                      >
                        Student
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="yourIdentity3"
                        value="Legal Guardian"
                        name="Legal Guardian"
                        onChange={handleChangeyourIdentity}
                        checked={formData?.yourIdentity === "Legal Guardian"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yourIdentity3"
                      >
                        Legal Guardian
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="yourIdentityOther"
                        name="yourIdentity"
                        onChange={handleChangeyourIdentityRadio}
                        // checked={notInCheckedyourIdentity}
                        // checked={formData?.ethnicity === "Other"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yourIdentityOther"
                      >
                        Other
                      </label>
                    </div>
                    {notInyourIdentity && (
                      <div className="mt-2">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          placeholder="Enter your user type "
                          name="yourIdentity"
                          value={formData?.yourIdentity || ""} // Set default value to empty string
                          onChange={handleChangeyourIdentityOther}
                          style={styleform}
                        />
                      </div>
                    )}
                    <div type="invalid" className="text-danger">
                      {errors?.yourIdentity}
                    </div>
                  </div> */}
                    <div className="mb-2">
                      <label
                        htmlFor="currentParent"
                        className="form-label my-2 fw-bold"
                      >
                        Describe Yourself
                      </label>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity1"
                            value="Parent"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={formData?.yourIdentity === "Parent"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity1"
                          >
                            Parent
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity2"
                            value="Student"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={formData?.yourIdentity === "Student"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity2"
                          >
                            Student
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity3"
                            value="Legal Guardian"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={
                              formData?.yourIdentity === "Legal Guardian"
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity3"
                          >
                            Legal Guardian
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentityOther"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentityRadio}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentityOther"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                      {notInyourIdentity && (
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Enter your user type"
                            name="yourIdentity"
                            value={formData?.yourIdentity || ""}
                            onChange={handleChangeyourIdentityOther}
                            style={styleform}
                          />
                        </div>
                      )}
                      <div type="invalid" className="text-danger">
                        {errors?.yourIdentity}
                      </div>
                    </div>
                    {/* citizenship status radio */}
                    <Form.Group controlId="formBasiccitizenshipStatus">
                      <Form.Label className="my-2 fw-bold   ">
                        Citizenship <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      {/* <div className="d-flex align-items-center flex-wrap"> */}
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus1"
                          value="US Citizen"
                          name="citizenshipStatus"
                          onChange={handleChange}
                          checked={formData?.citizenshipStatus === "US Citizen"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus1"
                        >
                          US Citizen
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus2"
                          value="Permanent Resident (Green Card Holder)"
                          name="citizenshipStatus"
                          checked={
                            formData?.citizenshipStatus ===
                            "Permanent Resident (Green Card Holder)"
                          }
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus2"
                        >
                          Permanent Resident (Green Card Holder)
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus3"
                          value="Nonimmigrant"
                          name="citizenshipStatus"
                          onChange={handleChange}
                          checked={
                            formData?.citizenshipStatus === "Nonimmigrant"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus3"
                        >
                          Nonimmigrant
                        </label>
                      </div>
                      {/* </div> */}
                    </Form.Group>
                    {/* address data form country state  */}
                    {/* {console.log(errorsCountrystate?.country)} */}
                    <div className="">
                      <Form.Group controlId="formBasicCountry">
                        <Form.Label className="my-2 fw-bold">
                          Country <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <CountryDropdown
                          value={selectedCountry || formData.country}
                          onChange={handleCountryChange}
                          // classes="form-select"
                          className={
                            (selectedCountry || formData?.country) &&
                            errorsCountrystate?.country
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                          required
                          style={styleform}
                        />
                        {CountryError && (
                          <Form.Control.Feedback type="invalid">
                            {errorsCountrystate.country}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>

                    <Form.Group controlId="formBasicCity">
                      <Form.Label className="my-2 fw-bold ">City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        name="city"
                        value={formData?.city}
                        onChange={handleChange}
                        // required
                        isInvalid={!!errors?.city}
                        //   isInvalid={!formData.city}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.city}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Label className="my-2 fw-bold   ">
                      Cosigner Preferences
                      <span style={{ color: "red" }}> *</span>
                      {/* Cosigner Status<span style={{ color: "red" }}> *</span> */}
                    </Form.Label>
                    <div className="form-check">
                      <input
                        required
                        className="form-check-input"
                        type="radio"
                        name="culturalIdentity"
                        id="flexRadioDefault1"
                        value="US citizen or GC holder with US cosigner"
                        onChange={handleChange}
                        checked={
                          formData?.culturalIdentity ===
                          "US citizen or GC holder with US cosigner"
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        US citizen or GC holder with US cosigner
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="flexRadioDefault2"
                        value="US citizen or GC holder with no US cosigner"
                        name="culturalIdentity"
                        onChange={handleChange}
                        checked={
                          formData?.culturalIdentity ===
                          "US citizen or GC holder with no US cosigner"
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        US citizen or GC holder with no US cosigner
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="culturalIdentity"
                        id="flexRadioDefault3"
                        value="Non-US citizen"
                        onChange={handleChange}
                        checked={
                          formData?.culturalIdentity === "Non-US citizen"
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault3"
                      >
                        Non-US citizen
                      </label>
                    </div>

                    <Form.Group>
                      <Form.Label className="my-2 fw-bold  ">
                        High School or University Name
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter your High School or University Name"
                        name="highschoolUniversity"
                        isInvalid={!!errors?.highschoolUniversity}
                        value={formData?.highschoolUniversity}
                        onChange={handleChange}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.highschoolUniversity}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* tentative date of graduation */}
                    <Form.Group controlId="formBasictententativeHighschoolGraduationDate">
                      <Form.Label className="my-2 fw-bold  ">
                        Tentative High School Graduation Date
                      </Form.Label>
                      <input
                        placeholder="mm/yyyy"
                        type="month"
                        className="form-control mb-3"
                        id="tentativeHighschoolGraduationDate"
                        name="tentativeHighschoolGraduationDate"
                        onChange={handleChange}
                        value={formData?.tentativeHighschoolGraduationDate}
                      />
                    </Form.Group>

                    {/* Mode of Study dropdown Mode of Study */}
                    <div className="mb-2 fw-bold ">
                      <label htmlFor="modeOfStudy">Mode of Study</label>
                      <select
                        className="form-select"
                        id="modeOfStudy"
                        name="modeOfStudy"
                        onChange={handleChange}
                        //   onBlur={handleBlur}
                        value={formData?.modeOfStudy}
                        style={stylefordrop}
                      >
                        {modeStudyOptions.map((option) => (
                          <option
                            key={option}
                            value={option}
                            style={{ marginTop: "20px" }} // Adjust the value as needed
                            className="py-2"
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div
                      className="heightAdjust"
                      // style={{ marginTop: "7.23rem" }}
                    >
                      {/*   Volunteer (leadership roles) */}
                      <Form.Group controlId="formBasicleadership">
                        <Form.Label className="my-2 fw-bold  ">
                          Volunteer (leadership roles)
                        </Form.Label>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Volunteer"
                              value="yes"
                              name="leadership"
                              onChange={handleChange}
                              checked={
                                formData?.leadership === "yes" ||
                                formData?.leadership === "Yes"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Volunteer"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check ms-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Volunteer2"
                              value="no"
                              name="leadership"
                              onChange={handleChange}
                              checked={
                                formData?.leadership === "no" ||
                                formData?.leadership === "No"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Volunteer2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </Form.Group>

                      {/* recognitions or Awards for Extracurricular */}
                      {/* <Form.Group controlId="formBasicrecognitions">
                      <Form.Label className="my-2 fw-bold ">
                        Recognitions or Awards for Extracurricular{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        placeholder="Write your answer here!"
                        name="recognitions"
                        value={formData?.recognitions}
                        onChange={handleChange}
                        style={styleform}
                        className="text-capitalize"
                      />
                    </Form.Group> */}
                      {/* Honors, Awards or Recognition */}
                      <Form.Group controlId="formBasicacademicRecognitions">
                        <Form.Label className="my-2 fw-bold">
                          Honors, Awards or Recognition
                        </Form.Label>
                        <Form.Control
                          // id="formBasicacademicRecognitions"
                          as="textarea"
                          type="text"
                          placeholder="Write your answer here!"
                          name="academicRecognitions"
                          value={formData?.academicRecognitions}
                          onChange={handleChange}
                          style={styleform}
                          className="text-capitalize"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <button
                  className="btn btn-primary px-sm-0 px-md-5 mb-2 ms-3 px-4"
                  type="submit"
                  // undefined is for blanck all fields and errors for validation of onChange
                  // onClick={handleSubmitButton}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {" "}
                  Save Changes
                </button>
              </div>
              <div className="container mt-0">
                <p className="text-danger">
                  Please note that the fields marked with an asterisk (*) are
                  mandatory.
                </p>
              </div>
              <>
                <Modal show={show} onHide={handleCloseModal}>
                  <Modal.Header>
                    <Modal.Title>
                      The following fields are marked compulsory. Please fill in
                      all the details.
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* {fieldsToUpdateValue?.map((field, index) => {
                // Check if the field matches the condition
                if (field === "currentEducationalLevel") {
                  // Update the field value
                  field = "current Educational Level";
                }
                // Return the updated field value
                return (
                  <p className="text-capitalize" key={index}>
                    {field}
                  </p>
                );
              })} */}

                    {fieldsToUpdateValue?.map((field, index) => (
                      <p className="text-capitalize" key={index}>
                        {field}
                      </p>
                    ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </Form>
          ) : (
            // For Mobile
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="needs-validation"
              validated={updatedErrors}
            >
              <div className="row ">
                {/* <!---------------- Left Side - 6 Divs ---------------------> */}
                <div className="col-md-6">
                  <div className="mb-3 p-3">
                    <h4>Profile Information</h4>
                    {/* name */}
                    <Form.Group controlId="formBasicName">
                      <Form.Label className="my-2 fw-bold ">
                        Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="User Name"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors?.name}
                        //   isInvalid={!formData.name}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* email */}
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="my-2 fw-bold ">
                        Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        readOnly // normal bootstrap
                        className="form-control-plaintext"
                        disabled
                        type="email"
                        placeholder="username@domain.com"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                        //   isInvalid={!!formData.email}
                        isInvalid={!formData.email}
                        style={{
                          fontWeight: "400",
                          border: "revert-layer", // Remove default border on focus
                          outline: "none", // Remove default outline on focus
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                          marginBottom: ".5rem",
                          paddingLeft: ".5rem",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter email correct
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* Mobile number new */}
                    <div className="">
                      <Form.Group controlId="formBasiccontactNumber">
                        <div className="">
                          <Form.Label className="my-2 fw-bold  ">
                            Contact Number
                            <span className="text-danger fw-light ms-2">
                              (Note:-Select country first)
                            </span>
                          </Form.Label>
                        </div>
                        <PhoneInput
                          country="US"
                          // value={phoneNumber} //
                          value={
                            phoneNumber || formData?.contactNumber?.toString()
                          }
                          // onChange={handleChangePhone}
                          onChange={handlePhoneChange}
                          limitMaxLength={true}
                          // international={true}
                          defaultCountry="US"
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                            paddingTop: ".2rem",
                            paddingBottom: ".2rem",
                          }}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          placeholder="+1 xxxxx"
                        />

                        <div className="text-danger fw-light">
                          {errors?.contactNumber}
                        </div>
                      </Form.Group>
                    </div>
                    {/* Gender */}
                    <Form.Group controlId="formBasicGender">
                      <Form.Label className="my-2 fw-bold ">
                        Gender<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <div key="gender" className="mb-3">
                        <Form.Check
                          type="radio"
                          inline
                          label="Male"
                          id="male"
                          name="gender"
                          value="Male"
                          checked={
                            formData?.gender === "male" ||
                            formData?.gender === "Male"
                          }
                          onChange={handleChange}
                          //isInvalid={!!errors.gender}
                        />
                        <Form.Check
                          type="radio"
                          inline
                          label="Female"
                          id="female"
                          name="gender"
                          value="Female"
                          checked={
                            formData?.gender === "female" ||
                            formData?.gender === "Female"
                          }
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          inline
                          label="Other"
                          id="other"
                          name="gender"
                          value="Other"
                          checked={
                            formData?.gender === "other" ||
                            formData?.gender === "Other"
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select your gender.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="mb-2">
                      <label
                        htmlFor="currentParent"
                        className="form-label my-2 fw-bold"
                      >
                        Describe Yourself
                      </label>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity1"
                            value="Parent"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={formData?.yourIdentity === "Parent"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity1"
                          >
                            Parent
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity2"
                            value="Student"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={formData?.yourIdentity === "Student"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity2"
                          >
                            Student
                          </label>
                        </div>
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentity3"
                            value="Legal Guardian"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentity}
                            checked={
                              formData?.yourIdentity === "Legal Guardian"
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentity3"
                          >
                            Legal Guardian
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="yourIdentityOther"
                            name="yourIdentity"
                            onChange={handleChangeyourIdentityRadio}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="yourIdentityOther"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                      {notInyourIdentity && (
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Enter your user type"
                            name="yourIdentity"
                            value={formData?.yourIdentity || ""}
                            onChange={handleChangeyourIdentityOther}
                            style={styleform}
                          />
                        </div>
                      )}
                      <div type="invalid" className="text-danger">
                        {errors?.yourIdentity}
                      </div>
                    </div>
                    {/* Ethenticity   */}
                    <Form.Group controlId="formBasicethnicity">
                      <Form.Label className="my-2   fw-bold ">
                        Ethnicity
                      </Form.Label>
                      <span style={{ color: "red" }}> *</span>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity1"
                          value="American Indian or Alaska Native"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={
                            formData?.ethnicity ===
                            "American Indian or Alaska Native"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity1"
                        >
                          American Indian or Alaska Native
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity2"
                          value="Asian"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={formData?.ethnicity === "Asian"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity2"
                        >
                          Asian
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity3"
                          value="Black or African American"
                          name="ethnicity"
                          checked={
                            formData?.ethnicity === "Black or African American"
                          }
                          onChange={handleChangeEthnicity}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity3"
                        >
                          Black or African American
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity4"
                          value="Native Hawaiian or Pacific Islander"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={
                            formData?.ethnicity ===
                            "Native Hawaiian or Pacific Islander"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity4"
                        >
                          Native Hawaiian or Pacific Islander
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicity5"
                          value="White/Caucasian"
                          name="ethnicity"
                          onChange={handleChangeEthnicity}
                          checked={formData?.ethnicity === "White/Caucasian"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicity5"
                        >
                          White/Caucasian
                        </label>
                      </div>
                      {/* ... other ethnicity radio buttons ... */}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="ethnicityOther"
                          // value="Other"
                          name="ethnicity"
                          // onChange={() => {
                          //   setNotIn(true);
                          //   setNotInChecked(true);
                          // }}
                          onChange={handleChangeEthnicityRadio}
                          // checked={notInChecked}
                          // checked={formData?.ethnicity === "Other"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="ethnicityOther"
                        >
                          Other
                        </label>
                      </div>
                      {notIn && (
                        <div className="mt-2">
                          <input
                            type="text"
                            className="form-control text-capitalize"
                            placeholder="Enter your ethnicity"
                            name="ethnicity"
                            value={formData?.ethnicity || ""} // Set default value to empty string
                            onChange={handleChangeEthnicityOther}
                            style={styleform}
                          />
                        </div>
                      )}
                    </Form.Group>
                    {/* citizenship status radio */}
                    <Form.Group controlId="formBasiccitizenshipStatus">
                      <Form.Label className="my-2 fw-bold   ">
                        Citizenship <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      {/* <div className="d-flex align-items-center flex-wrap"> */}
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus1"
                          value="US Citizen"
                          name="citizenshipStatus"
                          onChange={handleChange}
                          checked={formData?.citizenshipStatus === "US Citizen"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus1"
                        >
                          US Citizen
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus2"
                          value="Permanent Resident (Green Card Holder)"
                          name="citizenshipStatus"
                          checked={
                            formData?.citizenshipStatus ===
                            "Permanent Resident (Green Card Holder)"
                          }
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus2"
                        >
                          Permanent Resident (Green Card Holder)
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="citizenshipStatus3"
                          value="Nonimmigrant"
                          name="citizenshipStatus"
                          onChange={handleChange}
                          checked={
                            formData?.citizenshipStatus === "Nonimmigrant"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="citizenshipStatus3"
                        >
                          Nonimmigrant
                        </label>
                      </div>
                      {/* </div> */}
                    </Form.Group>
                    <div className="">
                      <Form.Group controlId="formBasicCountry">
                        <Form.Label className="my-2 fw-bold">
                          Country <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <CountryDropdown
                          value={selectedCountry || formData.country}
                          onChange={handleCountryChange}
                          // classes="form-select"
                          className={
                            (selectedCountry || formData?.country) &&
                            errorsCountrystate?.country
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                          required
                          style={styleform}
                        />
                        {CountryError && (
                          <Form.Control.Feedback type="invalid">
                            {errorsCountrystate.country}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                    {/* state */}
                    <Form.Group controlId="formBasicstate">
                      <Form.Label className="my-2 fw-bold ">
                        State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <RegionDropdown
                        country={selectedCountry || formData?.country} //  right ahe
                        value={selectedState || formData?.state}
                        onChange={handleStateChange}
                        // className="form-select"
                        classes={
                          (selectedCountry || formData?.country) &&
                          errorsCountrystate?.state
                            ? "form-select is-invalid"
                            : "form-select"
                        }
                        placeholder="Select state"
                        style={styleform}
                      />
                      {stateError && (
                        <Form.Control.Feedback type="invalid">
                          {errorsCountrystate.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    {/* City */}
                    <Form.Group controlId="formBasicCity">
                      <Form.Label className="my-2 fw-bold ">City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="City"
                        name="city"
                        value={formData?.city}
                        onChange={handleChange}
                        // required
                        isInvalid={!!errors?.city}
                        //   isInvalid={!formData.city}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {/* Adreess */}
                    <Form.Group controlId="formBasicAddress">
                      <Form.Label className="my-2 fw-bold ">Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={formData?.address}
                        onChange={handleChange}
                        required
                        isInvalid={!!errors?.address}
                        //   isInvalid={!formData.Address}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.address}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/*dropdown  Current Educational Level */}
                    <div className="mb-2">
                      <label
                        htmlFor="currentEducationalLevel"
                        className="form-label my-2 fw-bold "
                      >
                        Current Educational Level
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <select
                        className="form-select"
                        id="currentEducationalLevel"
                        name="currentEducationalLevel"
                        onChange={handleChangeCurrentEducationalLevel}
                        // value={formData?.currentEducationalLevel}
                        value={
                          showInput
                            ? "Other"
                            : formData?.currentEducationalLevel
                        }
                        isInvalid={!!errors?.currentEducationalLevel}
                        style={{
                          fontWeight: "400",
                          border: "1px solid #ced4da",
                          outline: "none",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          marginBottom: ".5rem",
                        }}
                      >
                        {!IntheDropdown ? (
                          <>
                            {dropdownOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </select>
                      {showInput && (
                        <input
                          type="text"
                          value={
                            otherValue || formData?.currentEducationalLevel
                          }
                          onChange={handleOtherInputChange}
                          placeholder="Enter other educational level"
                          className="form-control text-capitalize"
                          style={{
                            fontWeight: "400",
                            border: "revert-layer", // Remove default border on focus
                            outline: "none", // Remove default outline on focus
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                            marginBottom: ".5rem",
                          }}
                        />
                      )}
                      <div type="invalid" className="text-danger">
                        {errors?.currentEducationalLevel ||
                          currentEducationalLevel}
                      </div>
                    </div>

                    {/* Degree   */}
                    <Form.Group>
                      <Form.Label className="my-2 fw-bold  ">
                        Major/Field of study
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Major/field of study"
                        name="MajorFieldOfstudy"
                        value={formData?.MajorFieldOfstudy}
                        required
                        onChange={handleChange}
                        isInvalid={!!errors?.MajorFieldOfstudy}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.MajorFieldOfstudy}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Area of Study  
                     {/* Current GPA */}
                    <Form.Group>
                      <Form.Label className="my-2 fw-bold">
                        Current GPA<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Enter your CGPA/GPA"
                        name="currentGpa"
                        isInvalid={!!errors?.currentGpa}
                        value={formData?.currentGpa}
                        onChange={handleChange}
                        style={styleform}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.currentGpa}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="my-2 fw-bold  ">
                        High School or University Name
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter your High School or University Name"
                        name="highschoolUniversity"
                        isInvalid={!!errors?.highschoolUniversity}
                        value={formData?.highschoolUniversity}
                        onChange={handleChange}
                        style={styleform}
                        className="text-capitalize"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.highschoolUniversity}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* tentative date of graduation */}
                    <Form.Group controlId="formBasictententativeHighschoolGraduationDate">
                      <Form.Label className="my-2 fw-bold  ">
                        Tentative High School Graduation Date
                      </Form.Label>
                      <input
                        placeholder="mm/yyyy"
                        type="month"
                        className="form-control mb-3"
                        id="tentativeHighschoolGraduationDate"
                        name="tentativeHighschoolGraduationDate"
                        onChange={handleChange}
                        value={formData?.tentativeHighschoolGraduationDate}
                      />
                    </Form.Group>

                    {/* Mode of Study dropdown Mode of Study */}
                    <div className="mb-2 fw-bold ">
                      <label htmlFor="modeOfStudy">Mode of Study</label>
                      <select
                        className="form-select"
                        id="modeOfStudy"
                        name="modeOfStudy"
                        onChange={handleChange}
                        //   onBlur={handleBlur}
                        value={formData?.modeOfStudy}
                        style={stylefordrop}
                      >
                        {modeStudyOptions.map((option) => (
                          <option
                            key={option}
                            value={option}
                            style={{ marginTop: "20px" }} // Adjust the value as needed
                            className="py-2"
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <Form.Label className="my-2 fw-bold   ">
                        Cosigner Preferences
                        <span style={{ color: "red" }}> *</span>
                        {/* Cosigner Status<span style={{ color: "red" }}> *</span> */}
                      </Form.Label>
                      <div className="form-check">
                        <input
                          required
                          className="form-check-input"
                          type="radio"
                          name="culturalIdentity"
                          id="flexRadioDefault1"
                          value="US citizen or GC holder with US cosigner"
                          onChange={handleChange}
                          checked={
                            formData?.culturalIdentity ===
                            "US citizen or GC holder with US cosigner"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          US citizen or GC holder with US cosigner
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault2"
                          value="US citizen or GC holder with no US cosigner"
                          name="culturalIdentity"
                          onChange={handleChange}
                          checked={
                            formData?.culturalIdentity ===
                            "US citizen or GC holder with no US cosigner"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          US citizen or GC holder with no US cosigner
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="culturalIdentity"
                          id="flexRadioDefault3"
                          value="Non-US citizen"
                          onChange={handleChange}
                          checked={
                            formData?.culturalIdentity === "Non-US citizen"
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Non-US citizen
                        </label>
                      </div>
                    </div>
                    {/* Academic Achievements */}
                    <h4 className="mt-4">Academic Achievements</h4>
                    <div className=" col-12 ">
                      <Form.Group controlId="formBasicinvolvement">
                        <Form.Label className="my-2 fw-bold  ">
                          Involvement in clubs, sports, or community service{" "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Write your answer here!"
                          name="involvement"
                          value={formData?.involvement}
                          onChange={handleChange}
                          style={styleform}
                          className="text-capitalize"
                        />
                      </Form.Group>
                      {/*   Participation in Research or Academic Programs */}
                      <Form.Group controlId="formBasicacademicPrograms">
                        <Form.Label className="my-2 fw-bold  ">
                          Participation in Research or Academic Programs
                        </Form.Label>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineradio1"
                              value="yes"
                              name="academicPrograms"
                              checked={
                                formData?.academicPrograms === "yes" ||
                                formData?.academicPrograms === "Yes"
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineradio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check ms-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="inlineradio2"
                              value="no"
                              name="academicPrograms"
                              checked={
                                formData?.academicPrograms === "no" ||
                                formData?.academicPrograms === "No"
                              }
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineradio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                      <div className="">
                        {/* recognitions or Awards for Extracurricular */}
                        {/* <Form.Group controlId="formBasicrecognitions">
                        <Form.Label className="my-2 fw-bold ">
                          Recognitions or Awards for Extracurricular{" "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Write your answer here!"
                          name="recognitions"
                          value={formData?.recognitions}
                          onChange={handleChange}
                          style={styleform}
                          className="text-capitalize"
                        />
                      </Form.Group> */}
                      </div>

                      {/* Honors, Awards or Recognition */}
                      <Form.Group controlId="formBasicacademicRecognitions">
                        <Form.Label className="my-2 fw-bold">
                          Honors, Awards or Recognition
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Write your answer here!"
                          name="academicRecognitions"
                          value={formData?.academicRecognitions}
                          onChange={handleChange}
                          style={styleform}
                          className="text-capitalize"
                        />
                      </Form.Group>
                      {/*   Volunteer (leadership roles) */}
                      <Form.Group controlId="formBasicleadership">
                        <Form.Label className="my-2 fw-bold  ">
                          Volunteer (leadership roles)
                        </Form.Label>
                        <div className="d-flex align-items-center flex-wrap">
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Volunteer"
                              value="yes"
                              name="leadership"
                              onChange={handleChange}
                              checked={
                                formData?.leadership === "yes" ||
                                formData?.leadership === "Yes"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Volunteer"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check ms-5">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Volunteer2"
                              value="no"
                              name="leadership"
                              onChange={handleChange}
                              checked={
                                formData?.leadership === "no" ||
                                formData?.leadership === "No"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Volunteer2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                      {/*  Notable Academic Achievements */}
                      <Form.Group controlId="formBasicacademicAchievement">
                        <Form.Label className="my-2 fw-bold  ">
                          Notable Academic Achievements
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          type="text"
                          placeholder="Write your answer here!"
                          name="academicAchievement"
                          value={formData?.academicAchievement}
                          onChange={handleChange}
                          style={styleform}
                          className="text-capitalize"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <button
                  className="btn btn-primary px-sm-0 px-md-5 mb-2 ms-3 px-4"
                  type="submit"
                  // undefined is for blanck all fields and errors for validation of onChange
                  // onClick={handleSubmitButton}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {" "}
                  Save Changes
                </button>
              </div>
              <div className="container mt-0">
                <p className="text-danger">
                  Please note that the fields marked with an asterisk (*) are
                  mandatory.
                </p>
              </div>
              <>
                <Modal show={show} onHide={handleCloseModal}>
                  <Modal.Header>
                    <Modal.Title>
                      The following fields are marked compulsory. Please fill in
                      all the details.
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* {fieldsToUpdateValue?.map((field, index) => {
                  // Check if the field matches the condition
                  if (field === "currentEducationalLevel") {
                    // Update the field value
                    field = "current Educational Level";
                  }
                  // Return the updated field value
                  return (
                    <p className="text-capitalize" key={index}>
                      {field}
                    </p>
                  );
                })} */}

                    {fieldsToUpdateValue?.map((field, index) => (
                      <p className="text-capitalize" key={index}>
                        {field}
                      </p>
                    ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default NewProfile;
