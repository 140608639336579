import React, { useEffect, useState } from "react";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "../styles/adminsidebar.css";
import { useLocation } from "react-router-dom";
//sidebar icons
import { MdSignalCellularAlt } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { PiBankLight } from "react-icons/pi";

import { BsToggles } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";
// import { FaRulerVertical } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
// import WinnersPage from "./WinnersPage";
import { NavLink } from "react-router-dom";
import { MdLeaderboard } from "react-icons/md";
import axios from "axios";
// import { API_BASE_URL } from "../../appConfig";
const AdminSideBar = () => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const [linkActive, setLinkActive] = useState();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("adminId");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("userType");

    navigate("/admin/login");
  };

  let adminToken = localStorage.getItem("adminToken");
  let adminId = localStorage.getItem("adminId");

  useEffect(() => {
    const headers = {
      authorization: `bearer ${adminToken}`,
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/dashboard/${adminId}`,
        {
          headers: headers,
        }
      )
      .then((responce) => {})
      .catch((error) => {
        navigate("/admin/login");
      });
  }, []);


  return (
    // <Container>
    // <Row>
    // <Col>
    <div className="AdminSidebar">
      <div
        className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary"
        style={{ height: "100vh" }}
      >
        {/* <Link
              to="/"
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
            > */}
        {/* active class define */}
        {/* <span className="fs-4">Path2Study</span> */}
        <div className="text-center">
          <img
            src={require("../assets/img/path2studylogoadmin.png")}
            alt=""
            className="img-fluid col-6"
            height={"%"}
          />
        </div>
        {/* </Link> */}
        <hr />
        <ul className="nav nav-pills flex-column mb-auto custom-nav-pills">
          <li>
            <NavLink
              to="/admin/dashboard"
              // activeClassName="text-primary"
              className=" nav-link"
              aria-current="page"
            >
              {/* <FaRulerVertical />  */}
              <MdSignalCellularAlt
                size={20}
                className="align-middle me-2"
              />{" "}
              Dashboard
            </NavLink>
          </li>
          <li className="nav-item mt-3">
            <NavLink
              to="/admin/user-management"
              activeClassName="text-primary"
              className=" nav-link"
              aria-current="page"
            >
              <RiGroupLine size={20} className="align-middle me-2" /> User
              Management
            </NavLink>
          </li>

          <li className="mt-3">
            <NavLink
              to="/admin/loan-management"
              className=" nav-link"
              // "link-body-emphasis"
            >
              <PiBankLight size={20} className="align-middle me-2" /> Loan
              Management
            </NavLink>
          </li>
          <li className="mt-3">
            <NavLink
              to="/admin/scholarship-management"
              // activeClassName="text-primary"
              className=" nav-link"
              
            >
              <div className="d-flex">
                <div className="">
                  <BsToggles
                    size={20}
                    className="justify-content-center align-items-center me-2 mt-2"
                  />
                </div>
                <div className="px-1">
                  Scholarship <br /> Management
                </div>
              </div>
            </NavLink>
          </li>
          <li className="mt-3">
            <NavLink
              to="/admin/leader-board"
              className=" nav-link"
            >
              <MdDashboard size={20} className="align-middle me-2" /> Leader
              Board
            </NavLink>

            {/* /admin/blogs-management */}
          </li>
          <li className="mt-3">
            <NavLink
              to="/admin/blogs-management"
              // activeClassName="text-danger bg-secondary"
              // activeStyle={{ backgroundColor: "#32a852" }}
              className=" nav-link"
              // className="nav-link link-body-emphasis"
              // className={`nav-link link-body-emphasis ${location.pathname === '/admin/leaderboard' ? 'text-primary forBackroundColor' : ''}`}
            >
              <RiPagesLine size={20} className="align-middle me-2" /> Blogs
              Management
            </NavLink>

            {/* /admin/blogs-management */}
          </li>
          <li className="mt-3">
            <NavLink
              to="/admin/declared-winners"
              // activeClassName="text-danger bg-secondary"
              // activeStyle={{ backgroundColor: "#32a852" }}
              className=" nav-link"
              // className="nav-link link-body-emphasis"
              // className={`nav-link link-body-emphasis ${location.pathname === '/admin/leaderboard' ? 'text-primary forBackroundColor' : ''}`}
            >
              <MdLeaderboard size={20} className="align-middle me-2" />
              &nbsp;Declared Winners
            </NavLink>

            {/* /admin/blogs-management */}
          </li>

          <li className="mt-3">
            <NavLink to="/admin/subscribed-users" className="nav-link">
             
              <RiPagesLine size={20} className="align-middle me-2" />
              &nbsp;Subscribed Users
            </NavLink>
          </li>

          {/* /admin/winners-page WinnersPage */}
        </ul>
        <hr />

        <button
          className="btn btn-outline-secondary"
          onClick={() => setShowPopup(true)}
        >
          Sign out
        </button>

        <Modal
          show={showPopup}
          //  onHide={handleClose}
          centered
        >
          {/* <Modal.Header closeButton>
              </Modal.Header> */}
          <Modal.Body>
            <div className="justify-content-center">
              <h4 className="">Sign out </h4>
              <h6 className="" style={{ color: "#667085" }}>
                Are you sure you want to sign out?
              </h6>
            </div>
            {/* <h6 className="text-center mb-0 pb-0">
            You will be redirected to the Homepage in{count - 1} seconds...
          </h6> */}
          </Modal.Body>
          <Modal.Footer>
            <button
              button
              type="button"
              className="btn btn-secondary px-4"
              // onClick={handleClose}
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              button
              type="button"
              className="btn btn-primary px-4"
              onClick={() => handleLogout()}
            >
              Sign out
            </button>
          </Modal.Footer>
        </Modal>
        {/* need section profile image and all */}
        {/* <div className="dropdown">
              <Link
                to="#"
                className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://github.com/mdo.png"
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle me-2"
                />
                <strong>mdo</strong>
              </Link>
              <ul className="dropdown-menu text-small shadow">
                <li>
                  <Link className="dropdown-item" to="#">
                    New project...
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Settings
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Profile
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Sign out
                  </Link>
                </li>
              </ul>
            </div> */}
      </div>

      {/* <div className="b-example-divider b-example-vr"></div> */}
    </div>
    // </Col>
    // </Row>

    // </Container>
  );
};

export default AdminSideBar;
