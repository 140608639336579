import"../../style/userTrackschol.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
// import { API_BASE_URL } from "../../appConfig";
import {  convertISOToHumanReadable } from "../../hooks/convertdate";
import TrackScholModal from "./TrackScholModal";
import { formatAmount } from "../../hooks/useFormattedAmount";

const UserTrackScholarship = () => {
  const [currency, setCurrency] = useState('');
  const [cardData, setCardData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const [isExpired, setIsExpired] = useState(""); // get from api status
  const [selectedCard, setSelectedCard] = useState();

  useEffect(() => {
    getUserData();
  }, []);

  const userToken = localStorage.getItem("userToken");
  const updatedToken = `Bearer ${userToken} `;
  // console.log('updatedToken',updatedToken)
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: API_BASE_URL,
    headers: {
      authorization: updatedToken,
    },
  });

  const getUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await authAxios.get(`/getUser/${userId}`);
      setCardData(response.data.appliedScholarships);
      setCurrency(response.data.currency)
    } catch (error) {
      console.error(error);
    }
  };

  // const handleClick = () => {
  //   window.scrollTo(0, 0);
  // };

  const openModal = (selectedCard) => {
    setIsOpen(true);
    setSelectedCard(selectedCard);
  };
  const closeModal = () => {
    setIsOpen(false);
  };


  const TrackCard = ({ cardData }) => {
    return (
      <div className="row">
        {cardData.map((card, i) => (
         
          <div key={card._id} className="col-lg-4 col-md-4 col-12  ">
            <div className="border rounded-4 p-3 mt-2 mb-3">
              <div className="text-end">
                {/* <StatusComponent status={card.status} />{" "} */}
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                {/* <h4 className="text-primary">${card.amount}</h4> */}
                <h4 className="text-primary">{card.currency ? card.currency : "$"} {formatAmount(card.amount)}</h4>
                <div className="d-flex flex-wrap ">
                  <i className="bi bi-clock "></i>
                  <p className="px-2">
                    {card?.Deadline &&
                      convertISOToHumanReadable(card?.Deadline)}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                <i className="bi bi-mortarboard-fill col-1 "></i>
                <small className="col-11 text-truncate d-inline-block fs-5">
                  {card.scholarshipName}
                </small>
              </div>
              <div className="d-flex flex-wrap justify-content-between ">
                <i className="bi bi-send-arrow-up-fill col-1 mt-1"></i>
                <small className="col-11 mb-1 pb-0 mt-1">
                  Provider :{card.scholarshipProvider}
                </small>
              </div>
              <div className="d-flex flex-wrap justify-content-between ">
                <i className="bi bi-journal-bookmark-fill col-1 mt-1"></i>
                <small className="col-11 mt-1 text-truncate ">
                  Course name :
                  {Array.isArray(card.courseName) && card.courseName.length > 0
                ? card.courseName.join(", ")
                : "All"}
                </small>
              </div>
              <button
                type="button"
                className="btn btn-outline-secondary w-100 mt-2 z-3 position-relative "
                onClick={() => openModal(card)}
              >
               View Application
              </button>
            </div>
          </div>
        ))}

        <>
          {isOpen === true ? (
            <TrackScholModal closeModal={closeModal} card={selectedCard} />
          ) : null}
        </>
      </div>
    );
  };
  return (
    <div className="container bg-body  p-3 mb-4"  >
      <div className="view-height">
        <h4 className="p-2 ms-2">Scholarships Applied</h4>
        {cardData.length <= 0 ? (
          <div className="alert alert-primary p-3 m-3" role="alert">
            No scholarship
            applied !!!
          </div>
        ) : (
          <div className="container">
            <TrackCard cardData={cardData} />
          </div>
        )}
        {/* <div className="container">
          <TrackCard cardData={cardData} />
        </div> */}
      </div>
    </div>
  );
};

export default UserTrackScholarship;

// {card?.status ? (
//   <span className=" badge bg-danger justify-content-end ">
//     {/* <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="10"
//       height="10"
//       fill="red"
//       className="bi bi-circle-fill "
//       viewBox="0 0 16 16"
//     >
//       <circle cx="8" cy="8" r="8" />
//     </svg> */}
//     Expire
//   </span>
// ) : (
//   <span className=" badge bg-primary justify-content-end ">
//     {/* <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="10"
//       height="10"
//       fill="blue"
//       className="bi bi-circle-fill"
//       viewBox="0 0 16 16"
//     >
//       <circle cx="8" cy="8" r="8" />
//     </svg>  */}
//     Live
//   </span>
// )}


 // <div key={i} className="col-md-4 mb-4">
          //   <div className="card">
          //     <div className="card-body d-flex justify-content-between">
          //       <div>
          //         <h5 className="card-title">
          //           ${card.amount}
          //           {/* $750 */}
          //         </h5>

          //         <p className="card-text">
          //           <i className="bi bi-mortarboard-fill me-1"></i>
          //           {/* Texas Chapter of the Wildlife Society Undergraduate
          //           Scholarship */}
          //           {card.scholarshipName}
          //         </p>
          //         <p className="card-text">
          //           <i className="bi bi-globe me-1"></i>
          //           {/* Country name */}
          //           {card.Country}
          //         </p>
          //         <p className="card-text">
          //           {" "}
          //           <i className="bi bi-send-arrow-up-fill me-1"></i>Provider :
          //           {/* University/Govt */}
          //           {card.scholarshipProvider
          //             ? card.scholarshipProvider
          //             : "Not provided "}
          //         </p>
          //         <p className="card-text">
          //           {" "}
          //           {/* <i className="bi bi-journal me-1"></i> */}
          //           <i className="bi bi-journal-bookmark-fill me-1"></i>
          //           Course name :{card.courseName}
          //         </p>
          //         <div>
          //           <button
          //             type="button"
          //             className="btn btn-outline-secondary w-100 ms-5 z-3 position-relative "
          //             onClick={() => openModal(card)}
          //           >
          //             Know More
          //           </button>
          //           {/* {isOpen === true ? (
          //             <TrackScholModal closeModal={closeModal} card={card} />
          //           ) : null} */}
          //         </div>
          //       </div>
          //       <div className="">
          //         <div className="d-flex justify-content-end ">
          //           {/* opacity-50 */}
          //           {/* {console.log("card.status", card.status)} */}
          //           <StatusComponent status={card.status} />
          //         </div>
          //         <div className="fw-light ps-4 d-md-inline-flex justify-content-end ">
          //           <i className="bi bi-clock me-1 "></i>
          //           <span className="fs-6 ">
          //             {/* December 18, 2023 col-auto*/}
          //             {card?.Deadline &&
          //               convertISOToHumanReadable(card?.Deadline)}
          //             {/* {console.log(card.Deadline)} */}
          //           </span>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          //   <>
          //     {isOpen === true ? (
          //       <TrackScholModal closeModal={closeModal} card={selectedCard} />
          //     ) : null}
          //   </>
          // </div>

           /* <div className="row">
          {cardData.map((card, i) => (
            <div key={card._id} className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body d-flex">
                  <div>
                    <h5 className="card-title">
                      ${card.amount}
                    </h5>

                    <p className="card-text">
                      <i className="bi bi-mortarboard-fill me-1"></i>
                      
                      {card.scholarshipName}
                    </p>
                    <p className="card-text">
                      <i className="bi bi-globe me-1"></i>
                      {card.Country}
                    </p>
                    <p className="card-text">
                      {" "}
                      <i className="bi bi-send-arrow-up-fill me-1"></i>Provider
                      {card.scholarshipProvider
                        ? card.scholarshipProvider
                        : "Not provided "}
                    </p>
                    <p className="card-text">
                      {" "}
                      <i className="bi bi-journal-bookmark-fill me-1"></i>
                      Course name :{card.courseName}
                    </p>
                    
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary w-100 ms-5"
                        onClick={openModal}
                      >
                        Know More
                      </button>
                      {isOpen === true ? (
                        <TrackScholModal closeModal={closeModal} />
                      ) : null}
                    </div>
                  </div>
                  <div className="">
                    <p className="fw-light pe-4 d-md-inline-flex">
                      <i className="bi bi-clock me-1 "></i>
                      <span className="fs-6 col-auto">
                        {formatDate(card.Deadline)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */