import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
// import { API_BASE_URL } from "../appConfig";
const UserContext = createContext();

const UserProvider = ({ children }) => {

  const [userType, setUserType] = useState("Basic"); // Set default user type
  const [userCountry, setUserCountry ] = useState(''); // Set default user country

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    () =>
      (localStorage.getItem("userType") || "Basic") &&
      localStorage.getItem("userToken")
  ); // by default false for dev its true

  // const clearLocalStorage = () => {
  //   localStorage.clear();
  // };

  const [data, setData] = useState({});

  // old original
  // const [data, setData] = useState({
  //   name: "",
  //   email: "",
  //   contactNumber: "",
  //   gender: "",
  //   ethnicity: "",
  //   culturalIdentity: "",
  //   citizenshipStatus: "",
  //   currentEducationalLevel: "",
  //   highschoolUniversity: "",
  //   tentativeHighschoolGraduationDate: "",
  //   MajorFieldOfstudy: "",
  //   modeOfStudy: "",
  //   sourcesOfFinancial: "",
  //   currentGpa: "",
  //   existingFinancialAid: "",
  //   annualFamilyIncome: "",
  //   involvement: "",
  //   recognitions: "",
  //   leadership: "",
  //   academicRecognitions: "",
  //   academicPrograms: "",
  //   academicAchievement: "",
  //   userId:'',
  //   profileImg:''
  // });
  const userToken = localStorage.getItem("userToken");
  const updatedToken = `Bearer ${userToken} `;
  // console.log('updatedToken',updatedToken)
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      authorization: updatedToken,
    },
  });
  // console.log("provider auth axios created url check", userToken);

  const getUserData = async () => {
    // const userToken = localStorage.getItem("userToken");
    try {
      const userId = localStorage.getItem("userId");
      const response = await authAxios.get(`/getUser/${userId}`);
      setData(response.data);
      // console.log('usercontext data get by api ',response?.data);
      // if(response?.data?.message === "The user belonging to this token does no longer exist."){
      //   clearLocalStorage();
      // }
    } catch (error) {
      console.error('error',error);
      // The user belonging to this token does no longer exist.
      // if(response?.data?.message === "The user belonging to this token does no longer exist."){
        // clearLocalStorage();
        // window.location.reload()
      // }
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        isUserLoggedIn,
        userType,
        userCountry, setUserCountry 
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
